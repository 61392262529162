import {empty, isset, sortByType, isNumber} from '../../helpers/helper'
import Helpers from './Helpers'
import store from "../../redux/store";
import Construction from '../Construction'

//this.contour - контур детали
//this.shapes - обработки

export default class Contour {
	_baseContour;
	contour;
	corners = [];
	shapes;
	edges = {left: null, top: null, right: null, bottom: null}
	_h;
	_l;
	_detail;

	constructor({h, l, contour = [], corners = [], shapes = [], cutouts = [], edges = [], updateContour, detail}) {
		this._h = h;
		this._l = l;
		if (!empty(edges)) this.edges = edges;
		this.contour = Helpers.updateContour(this.l, this.h, contour, {...this.edges});
		this._baseContour = [...this.contour];
		this.corners = corners;
		this.shapes = shapes;
		this._detail = detail;
		this.addMills()
		this.addCorners();
		if (typeof updateContour === 'function') {
			updateContour(this.contour);
		}

	}

	addCorners() {
		if (empty(this.corners)) return;
		this.corners.forEach(corner => {
			this.addCorner(corner);
		})
		this.addCornerEdgesToSides();
	}

	addMills() {
		const sortingArr = ['closed', 'partial', 'smile', 'tableTop', 'tableCorner', "radiusEdge", "gEdge", "uShape"]
		let array = this.shapes.sort((a, b) => sortingArr.indexOf(a.type) - sortingArr.indexOf(b.type))
		array = array.sort((a, b) => {
			if (a.type === 'partial' || b.type === 'partial' || a.type === 'closed' || b.type === 'closed') {
				if (!empty(a.mill?.overallElements) && !empty(b.mill?.overallElements)) {
					if (a.mill.overallElements[0].y1 === 0 && a.mill.overallElements[a.mill.overallElements.length - 1].x2 === 0) return 1
					if (b.mill.overallElements[0].y1 === 0 && b.mill.overallElements[b.mill.overallElements.length - 1].x2 === 0) return -1
				}
			}
			return 0
		})
		array.forEach(shape => {
			const cutout = shape.cutOut;
			if (cutout?.subType ==='mill') {
				switch (cutout.type) {
					case 'closed':
						this.addMillClosed(cutout)
						return
					default:
						this.addMill(cutout)
						this.deleteLinesThatArePoint()
            return
          // case'smile':
					// 	this.addSmile(cutout)
					// 	break;
					// case 'gEdge':
					// 	if (cutout.height >= this.l || cutout.width >= this.h) {
					// 		return
					// 	}
					// 	this.addGEdge(cutout)
					// 	break
					// case 'radiusEdge':
					// 	this.addRadiusEdge(cutout)
          //   break
          // case 'uShape':
					// 	this.addUShapes(cutout)
					// 	break;

				}
      } else if (cutout?.subType === 'tableProc') {
				switch (cutout.type) {
					case 'tableTop':
						this.addTableTop(cutout)
						break;
					case 'tableCorner':
						this.addTableCorner(cutout)
						break;
				}
			}
		})
	}

	// oldContourMethod() {
  //
	// }
  //
	// addMillPartial(cutout) {
	// 	const contour = [...this.contour];
	// 	const fixedCutIutElements = cutout.elements
	// 	console.log(cutout.place)
	// 	this.contour.forEach((cont, i) => {
	// 		let line, line2, startIndex, endIndex;
	// 		this.getUShapePoints(fixedCutIutElements).forEach((point, j) => {
	// 			if(!empty(cont)) {
	// 				if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
	// 					if (j === 0) {
	// 						line = {...cont};
	// 						startIndex = i;
	// 						line.x2 = point.x;
	// 						line.y2 = point.y;
	// 					} else {
	// 						endIndex = i;
	// 						line2 = {...cont};
	// 						line2.x1 = point.x;
	// 						line2.y1 = point.y;
	// 					}
	// 				}
	// 			}
	// 		})
  //
	// 		switch (cutout.sidesCount) {
	// 			case 1:
	// 				if (!empty(startIndex) && !empty(endIndex) && startIndex === endIndex) {
	// 					contour.splice(i, 1, line, ...fixedCutIutElements, line2)
	// 				}
	// 				break;
	// 			case 2:
	// 				if(!empty(startIndex)){
	// 					contour.splice(startIndex, 2, line, ...fixedCutIutElements, line2)
	// 				}
	// 				break;
	// 			case 3:
	// 				if(!empty(line) && !empty(line2)) {
	// 					const start = startIndex === contour.length - 1 ? 0 : startIndex
	// 					contour.splice(start, 4, ...fixedCutIutElements, {...line, x1: line2.x1, y1: line2.y1} )
	// 				}
  //
	// 				break;
	// 		}
  //
	// 	})
	// 	this.contour = [...contour]
	// }

	addMillClosed(cutout) {
		if (!empty(cutout.elements)) {
			this.contour = cutout.elements
		}
	}

	addMill(cutout) {
		if (empty(cutout.elements)) return false

		function roundPoint(num1, num2) {
			let distance = Math.abs(num1 - num2);
			if (distance <= 0.050) {
				return parseFloat(num2.toFixed(3));
			} else {
				return parseFloat(num1.toFixed(3));
			}
		}

		let contour = [...this.contour];
		const fixedCutIutElements = cutout.elements

		let startMainIndex, endMainIndex, line, line2
		this.contour.some((cont, i) => {
			this.getUShapePoints(fixedCutIutElements).forEach((point, j) => {
				const pointX = point.x > this._l ? Number(this._l) : Number(Number(point.x).toFixed(1))
				const pointY = point.y > this._h ? Number(this._h) : Number(Number(point.y).toFixed(1))
				if(cont.type === 'line') {
					if (Helpers.isPointOnSegment(Number(pointX), Number(pointY), Number(cont.x1), Number(cont.y1), Number(cont.x2), Number(cont.y2))) {
							if (j === 0) {
								line = {...cont};
								startMainIndex = i;
								if(i === 0) {
									line.x2 = point.x;
									line.y2 = point.y;
								} else {
									line.x2 = point.x;
									line.y2 = point.y;
								}
							} else {
								endMainIndex = i
								line2 = {...cont};
								if(i === 0 && empty(startMainIndex)) {
									line2.x2 = point.x;
									line2.y2 = point.y;
								} else {
									line2.x1 = point.x;
									line2.y1 = point.y;
								}
							}
						}
					// }
				} else {
					if(Helpers.isPointOnArc(pointX, pointY, cont )) {
						let angle1, angle2;
						if (j === 0) {
							line = {...cont};
							startMainIndex = i;
							if(i === 0) {
								line.x2 = point.x;
								line.y2 = point.y;
							} else {
								line.x2 = point.x;
								line.y2 = point.y;
							}
							angle1 = Helpers.calculateAngle(
								{x: line.xc, y: line.yc},
								{x: line.x1, y: line.y1},
								{x: line.x2, y: line.y2},
								line.dir
							)
							line = {...line,
								"angle": angle1.angle,
								"startAngle": angle1.angleStart * (180 / Math.PI),
								"endAngle": angle1.angleEnd * (180 / Math.PI),
								"startAngleRad": angle1.angleStart,
								"endAngleRad": angle1.angleEnd
							}
						} else {
							endMainIndex = i
							line2 = {...cont};
							if(i === 0 && empty(startMainIndex)) {
								line2.x2 = point.x;
								line2.y2 = point.y;
							} else {
								line2.x1 = point.x;
								line2.y1 = point.y;
							}

							angle2 = Helpers.calculateAngle(
								{x: line2.xc, y: line2.yc},
								{x: line2.x1, y: line2.y1},
								{x: line2.x2, y: line2.y2},
								line2.dir
							)
							line2 = {...line2,
								"angle": angle2.angle,
								"startAngle": angle2.angleStart * (180 / Math.PI),
								"endAngle": angle2.angleEnd * (180 / Math.PI),
								"startAngleRad": angle2.angleStart,
								"endAngleRad": angle2.angleEnd
							}

						}
					}
				}
			})
			if(!empty(startMainIndex) && !empty(endMainIndex)) {
				return true
			}
		})
		if(empty(startMainIndex) || empty(endMainIndex)) {
			return
		}
		let  countToDelete;
		if(endMainIndex >= startMainIndex) {
			countToDelete = endMainIndex - startMainIndex;
		} else if(endMainIndex === 0){
			countToDelete = contour.length - startMainIndex + 1
		} else if(startMainIndex + 1 === contour.length){
			countToDelete = endMainIndex;
		} else {
			countToDelete = (contour.length - endMainIndex) - startMainIndex + 1;
		}
		if(countToDelete === 0) {
			let element;
			if(cutout.sidesCount !== 1) {
				if(contour[startMainIndex].type === 'arc') {
					element = {
						...contour[startMainIndex],
						x1: line.x1,
						y1: line.y1,
						x2: line.x2,
						y2: line.y2
					}
					const angle = Helpers.calculateAngle(
						{x: element.xc, y: element.yc},
						{x: element.x1, y: element.y1},
						{x: element.x2, y: element.y2},
						element.dir
					)

					element = {...element,
						"angle": angle.angle,
						"startAngle": angle.angleStart * (180 / Math.PI),
						"endAngle": angle.angleEnd * (180 / Math.PI),
						"startAngleRad": angle.angleStart,
						"endAngleRad": angle.angleEnd
					}
				} else {
					element = {
						...contour[startMainIndex],
						x1: line2.x1,
						y1: line2.y1,
						x2: line.x2,
						y2: line.y2
					}
				}
				contour = [
					...fixedCutIutElements,
					{
						...element
					}
				]
			} else {
				element = []
				if(!this.checkElementIfDot(line)) {
					element.push(line)
				}
				element = [...element, ...fixedCutIutElements];
				if(!this.checkElementIfDot(line2)) {
					element.push(line2)
				}
 				contour.splice(startMainIndex, 1, ...element)
			}
		} else {
			let element = []
			if(!this.checkElementIfDot(line) && startMainIndex + 1 !== contour.length) {
				element.push(line)
			}
			element = [...element, ...fixedCutIutElements];
			if(!this.checkElementIfDot(line2) && endMainIndex !== 0) {
				element.push(line2)
			}
			if(startMainIndex + 1 === contour.length) {
				contour[contour.length - 1] = line;
				const start = {...contour[0], x1: line2.x2, y1: line2.y2}
				if(!this.checkElementIfDot(start)) {
					contour[0] = start
				}
				contour.splice(0, endMainIndex === 0 ? 0 : countToDelete + 1, ...element)

			} else {
				contour.splice(startMainIndex, countToDelete + 1, ...element)
				if(endMainIndex === 0 || endMainIndex < startMainIndex) {
					contour.splice(0, endMainIndex)
					contour[0] = {
						...contour[0],
						x1: line2.x2,
						y1: line2.y2
					}
				}
			}

		}

		this.contour = [...contour].map((el) => ({...el, x1: Number(el.x1), y1: Number(el.y1), x2: Number(el.x2), y2: Number(el.y2)}))
	}

	deleteLinesThatArePoint() {
    this.contour.forEach((cont, i) => {
			if (cont.type === 'line') {
				if (cont.x1 === cont.x2 && cont.y1 === cont.y2) {
					this.contour.splice(i, 1)
				}
			}
    })
	}

	checkElementIfDot(el) {
		return el.x1 === el.x2 && el.y1 === el.y2
	}

	addTableCorner(cutout) {
		const contour = [...this.contour];
		const fixedCutIutElements = this.getPoints(cutout.elements)
		let line, line2, startIndex, endIndex;
		switch (cutout.angle) {
			case 'left_bottom':
				this.contour.forEach((cont, i) => {
					fixedCutIutElements.forEach((point, j) => {
						if (Helpers.isPointOnSegment(Number(point.x), Number(point.y), Number(cont.x1), Number(cont.y1), Number(cont.x2), Number(cont.y2))) {
							if (j === 0) {
								line = {...cont};
								startIndex = i;
								line.x2 = point.x;
								line.y2 = point.y;
							} else {
								endIndex = i;
								line2 = {...cont};
								line2.x1 = point.x;
								line2.y1 = point.y;
							}

						}
					})
				})
				contour.splice(startIndex, 2, ...cutout.elements, line2)
				break
			case 'left_top':
				this.contour.forEach((cont, i) => {
					this.getPoints(cutout.elements).forEach((point, j) => {
						if (Helpers.isPointOnSegment(Number(point.x), Number(point.y), Number(cont.x1), Number(cont.y1), Number(cont.x2), Number(cont.y2))) {
							if (j === 0) {
								line = {...cont};
								startIndex = i;
								line.x2 = point.x;
								line.y2 = point.y;
							} else {
								endIndex = i;
								line2 = {...cont};
								line2.x1 = point.x;
								line2.y1 = point.y;
							}
						}
					})
				})
				contour.splice(startIndex, 2, ...cutout.elements, line2)
				break
			case "right_top":
				this.contour.forEach((cont, i) => {
					this.getUShapePoints(cutout.elements).forEach((point, j) => {
						if (Helpers.isPointOnSegment(Number(point.x), Number(point.y), Number(cont.x1), Number(cont.y1), Number(cont.x2), Number(cont.y2))) {
							if (j === 0) {
								line = {...cont};
								startIndex = i;
								line.x2 = point.x;
								line.y2 = point.y;
							} else {
								endIndex = i;
								line2 = {...cont};
								line2.x1 = point.x;
								line2.y1 = point.y;
							}
						}
					})
				})
				contour.splice(startIndex, 2, line, ...cutout.elements)
				break
			case 'right_bottom':
				this.contour.forEach((cont, i) => {
					this.getUShapePoints(cutout.elements).forEach((point, j) => {
						if (Helpers.isPointOnSegment(Number(point.x), Number(point.y), Number(cont.x1), Number(cont.y1), Number(cont.x2), Number(cont.y2))) {
							if (j === 0) {
								line = {...cont};
								startIndex = i;
								line.x2 = point.x;
								line.y2 = point.y;
							} else {
								endIndex = i;
								line2 = {...cont};
								line2.x1 = point.x;
								line2.y1 = point.y;
							}
						}
					})
				})
                  contour.splice(startIndex, 2, ...cutout.elements, line2)
				}
		this.contour = [...contour]
	}

	addTableTop(cutout) {
		const contour = [...this.contour];
		const fixedCutIutElements = this.getPoints(cutout.elements)
		let line, line2, startIndex, endIndex;
		switch (cutout.place) {
			case 'left':
				this.contour.forEach((cont, i) => {
					fixedCutIutElements.forEach((point, j) => {
						if (Helpers.isPointOnSegment(Number(point?.x), Number(point?.y), Number(cont?.x1), Number(cont?.y1), Number(cont?.x2), Number(cont?.y2))) {
							if (j === 0) {
								line = {...cont};
								startIndex = i;
								line.x2 = point.x;
								line.y2 = point.y;
							} else {
								endIndex = i;
								line2 = {...cont};
								line2.x1 = point.x;
								line2.y1 = point.y;
							}

						}
					})
				})
				contour.splice(startIndex, 2, ...cutout.elements, line2)
		break
			case 'right':
				this.contour.forEach((cont, i) => {
					this.getUShapePoints(cutout.elements).forEach((point, j) => {
						if (Helpers.isPointOnSegment(Number(point?.x), Number(point?.y), Number(cont?.x1), Number(cont?.y1), Number(cont?.x2), Number(cont?.y2))) {
							if (j === 0) {
								line = {...cont};
								startIndex = i;
								line.x2 = point.x;
								line.y2 = point.y;
							} else {
								endIndex = i;
								line2 = {...cont};
								line2.x1 = point.x;
								line2.y1 = point.y;
							}
						}
					})
				})
				contour.splice(startIndex, 2, line, ...cutout.elements,)
				break
			case "top":
				this.contour.forEach((cont, i) => {
					this.getUShapePoints(cutout.elements).forEach((point, j) => {
						if (Helpers.isPointOnSegment(Number(point.x), Number(point.y), Number(cont.x1), Number(cont.y1), Number(cont.x2), Number(cont.y2))) {
								if (j === 0) {
									line = {...cont};
									startIndex = i;
									line.x2 = point.x;
									line.y2 = point.y;
								} else {
									endIndex = i;
									line2 = {...cont};
									line2.x1 = point.x;
									line2.y1 = point.y;
								}
						}
					})
				})
					contour.splice(startIndex, 2, line, ...cutout.elements, line2)
				break
			case 'bottom':
				this.contour.forEach((cont, i) => {
					this.getUShapePoints(cutout.elements).forEach((point, j) => {
						if (Helpers.isPointOnSegment(Number(point.x), Number(point.y), Number(cont.x1), Number(cont.y1), Number(cont.x2), Number(cont.y2))) {
							if (cutout.x_axis === 'left') {
								if (j === 0) {
									line2 = {...cont};
									startIndex = i;
									line2.x2 = point.x;
									line2.y2 = point.y;
								} else {
									endIndex = i;
									line = {...cont};
									line.x1 = point.x;
									line.y1 = point.y;
								}
							} else {
								if (j === 0) {
									line = {...cont};
									startIndex = i;
									line.x2 = point.x;
									line.y2 = point.y;
								} else {
									endIndex = i;
									line2 = {...cont};
									line2.x1 = point.x;
									line2.y1 = point.y;
								}
							}
						}
					})
				})
				if (cutout.x_axis === 'left') {
					contour.splice(0, 1, line)
					contour.splice(contour.length - 1, 1, line2, ...cutout.elements)
				} else {
					contour.splice(startIndex, 2, line, ...cutout.elements, line2)
				}
		}

		this.contour = [...contour]
	}

	addSmile(cutout) {
		const contour = [...this.contour];
		const fixedCutIutElements = cutout.elements
		this.contour.forEach((cont, i) => {
			let line, line2, startIndex, endIndex;
					this.getUShapePoints(fixedCutIutElements).forEach((point, j) => {
						if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
							if (j === 0) {
								line = {...cont};
								startIndex = i;
								line.x2 = point.x;
								line.y2 = point.y;
							} else {
								endIndex = i;
								line2 = {...cont};
								line2.x1 = point.x;
								line2.y1 = point.y;
							}

						}
					})
					if (!empty(startIndex) && !empty(endIndex) && startIndex === endIndex) {
						contour.splice(i, 1, line, ...fixedCutIutElements, line2)
					}
			this.contour = [...contour]
		})
	}

	getPoints(elements) {
		const lastIndex = elements.length - 1;
		return [
			{
				x: (elements[0].x2),
				y: (elements[0].y2)

			},
			{
				x: (elements[lastIndex].x2),
				y: (elements[lastIndex].y2)
			}
		]
	}

	getUShapePoints (elements) {
		const lastIndex = elements.length - 1;
		return [
			{
				x: (elements[0].x1),
				y: (elements[0].y1)

			},
			{
				x: (elements[lastIndex].x2),
				y: (elements[lastIndex].y2)
			}
		]
	}


	addGEdge(cutout) {
		const { y,x, height,width,  angle } = cutout

		switch (cutout.angle){
			case "left_bottom":
				this.updateBevelsHoles(0, y , angle.split("_")[0])
				this.updateBevelsHoles(0, x , angle.split("_")[1])
                break;
			case "right_bottom":
				this.updateBevelsHoles(x, x + width , angle.split("_")[1])
				this.updateBevelsHoles(height, y, angle.split("_")[0])
				break;
			case "left_top":
				this.updateBevelsHoles(x, x + width, angle.split("_")[1])
				this.updateBevelsHoles(y, y + height, angle.split("_")[0])
				break;
			case "right_top":
				this.updateBevelsHoles(x, x + width, angle.split("_")[1]);
				this.updateBevelsHoles(y + height, y, angle.split("_")[0])
				break;
		}

		const contour = [...this.contour];
		const newElements = [...cutout.elements]
		let line, line2, startIndex, endIndex;
		this.contour.forEach((cont, i) => {
			this.getUShapePoints(newElements).forEach((point, j) => {
				if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
					if (cutout.angle === 'left_bottom') {
						if (j === 0) {
							line2 = {...cont};
							startIndex = i;
							line2.x2 = point.x;
							line2.y2 = point.y;
						} else {
							endIndex = i;
							line = {...cont};
							line.x1 = point.x;
							line.y1 = point.y;
						}
					} else {
						if (j === 0) {
							line = {...cont};
							startIndex = i;
							line.x2 = point.x;
							line.y2 = point.y;
						} else {
							endIndex = i;
							line2 = {...cont};
							line2.x1 = point.x;
							line2.y1 = point.y;
						}
					}
				}
			})
		})
		if (cutout.angle === 'left_bottom') {
			contour.splice(0, 1, line)
			contour.splice(contour.length - 1, 1, line2, ...newElements)
		} else {
			if(!empty(startIndex)){
				contour.splice(startIndex, 2, line, ...newElements, line2)
			}
		}
		this.contour = [...contour]
	}

	addRadiusEdge(cutout) {
		const contour = [...this.contour];
		const newElements = [...cutout.elements]
		let line, line2, startIndex, endIndex;
		this.contour.forEach((cont, i) => {
			this.getUShapePoints(newElements).forEach((point, j) => {
				if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
					if (cutout.angle === 'left_bottom') {
						if (j === 0) {
							line2 = {...cont};
							startIndex = i;
							line2.x2 = point.x;
							line2.y2 = point.y;
						} else {
							endIndex = i;
							line = {...cont};
							line.x1 = point.x;
							line.y1 = point.y;
						}
					} else {
						if (j === 0) {
							line = {...cont};
							startIndex = i;
							line.x2 = point.x;
							line.y2 = point.y;
						} else {
							endIndex = i;
							line2 = {...cont};
							line2.x1 = point.x;
							line2.y1 = point.y;
						}
					}
				}
			})
		})
		if (cutout.angle === 'left_bottom') {
			contour.splice(0, 1, line)
			contour.splice(contour.length - 1, 1, line2, ...newElements)
		} else {
			contour.splice(startIndex, 2, line, ...newElements, line2)
		}
		this.contour = [...contour]
	}

	chooseSideForUpdate = (y,x, height, place, width) => {
		switch (place) {
			case "left":
			case "right":

				this.updateBevelsHoles(y, y + height, place);
				break;

			case "top":
			case "bottom":

				this.updateBevelsHoles(x, x + width, place);
				break;

		}
   }
	addUShapes(cutout) {
			//TODO убрать условия потом
                const { y,x, height, place, width } = cutout
		  this.chooseSideForUpdate( y,x, height, place, width)

				const fixedCutIutElements = cutout.elements


				const contour = [...this.contour];
				this.contour.forEach((cont, i) => {
					let line, line2, arc1, arc2, startIndex, endIndex;
					switch (cont.type) {
						case 'line':
							this.getUShapePoints(fixedCutIutElements).forEach((point, j) => {
								if (Helpers.isPointOnSegment(point.x, point.y, cont.x1, cont.y1, cont.x2, cont.y2)) {
									if (j === 0) {
										line = {...cont};
										startIndex = i;
										line.x2 = point.x;
										line.y2 = point.y;
									} else {
										endIndex = i;
										line2 = {...cont};
										line2.x1 = point.x;
										line2.y1 = point.y;
									}

								}
							})
							if (!empty(startIndex) && !empty(endIndex) && startIndex === endIndex) {
								contour.splice(i, 1, line, ...fixedCutIutElements, line2)
							}
							break
						case 'arc':
							fixedCutIutElements.forEach((cut, j) => {
								switch (cut.type) {
									case 'line':
										const intersection = Helpers.findIntersectionOfSegmentAndArc(
											{x: cut.x1, y: cut.y1},
											{x: cut.x2, y: cut.y2},
											{x: cont.xc, y: cont.yc},
											cont.r,
											cont.startAngle,
											cont.endAngle
										)
										if (!empty(intersection)) {
											if (!empty(line) && empty(line2) || empty(line) && !empty(line2) || !empty(arc1)) {
												const angle = Helpers.calculateAngle(
													{x: cont.xc, y: cont.yc},
													{x: intersection.x, y: intersection.y},
													{x: cont.x2, y: cont.y2}
												)
												arc2 = {
													...cont,
													x2: intersection.x,
													y2: intersection.y,
													startAngle: angle.angleStart * (180 / Math.PI),
													endAngle: angle.angleEnd * (180 / Math.PI),
													startAngleRad: angle.angleStart,
													endAngleRad: angle.angleEnd
												};
												endIndex = i;
											} else if (empty(line) && empty(line2)) {
												const angle = Helpers.calculateAngle(
													{x: cont.xc, y: cont.yc},
													{x: cont.x1, y: cont.y1},
													{x: intersection.x, y: intersection.y}
												)
												arc1 = {
													...cont,
													x2: intersection.x,
													y2: intersection.y,
													startAngle: angle.angleStart * (180 / Math.PI),
													endAngle: angle.angleEnd * (180 / Math.PI),
													startAngleRad: angle.angleStart,
													endAngleRad: angle.angleEnd
												};
												startIndex = i;
											}
										}
								}
							})

							if (startIndex && endIndex) {
								if (startIndex === endIndex) { // both sides of uShape are intersect one arc

								} else {

								}
							}
							break
					}
				})
				this.contour = [...contour];
	}

	addCorner(corner) {
		switch (corner.angle){
			case "left_bottom":
				this.updateBevelsHoles(0, corner.y , corner.angle.split("_")[0])
				this.updateBevelsHoles(0, corner.x , corner.angle.split("_")[1])
				break;
			case "left_top":
				this.updateBevelsHoles(this.h, this.h - corner.y , corner.angle.split("_")[0])
				this.updateBevelsHoles(this.l, this.l - corner.x , corner.angle.split("_")[1])
				break;
		}
		const h = this.h;
		const l = this.l;
		let intersections = [];
		let p3, p4, xc, yc, startAngle, endAngle;
		const inverce = corner.angle === 'left_bottom';
		function getCornerAngle(corner) {
			switch (corner) {
				case 'left_bottom':
					return [-90, 180]
				case 'left_top':
					return [180, 90]
				case 'right_top':
					return [0, -90]
				case 'right_bottom':
					return [-90, 0]
			}
		}

		const angle = getCornerAngle(corner.angle);

		function addCorner(startPoints, endPoints) {
			switch (corner.type) {
				case "radius":
					const x1 = inverce ? endPoints.x : startPoints.x;
					const y1 = inverce ? endPoints.y : startPoints.y;
					const x2 = inverce ? startPoints.x : endPoints.x;
					const y2 = inverce ? startPoints.y : endPoints.y;
					const cont = Helpers.createArc(x1, y1, x2, y2, corner.r, xc, yc, true)
					cont.isCorner = corner.angle;
					cont.edge = corner.edge ? corner.edge.index : null;
					cont.color = corner.isActive ? Helpers.colorForActive : null
					return cont
				case "line":
					const contLine = Helpers.createLine(startPoints.x, startPoints.y, endPoints.x, endPoints.y);
					contLine.isCorner = corner.angle;
					contLine.color = corner.isActive ? Helpers.colorForActive : null;
					contLine.edge = corner.edge ? corner.edge.index : null
					return contLine;
			}
		}

		switch (corner.angle) {
			case 'left_bottom':
				if (corner.type === "radius") {
					xc = corner.r;
					yc = corner.r;
					startAngle = angle[0];
					endAngle = angle[1];
				} else {
					p3 = {x: corner.x, y: 0};
					p4 = {x: 0, y: corner.y};
				}
				break
			case 'left_top':
				if (corner.type === "radius") {
					xc = corner.r;
					yc = h - corner.r;
					startAngle = angle[0];
					endAngle = angle[1];
				} else {
					p3 = {x: 0, y: h - corner.y}
					p4 = {x: corner.x, y: h}
				}
				break
			case 'right_top':
				if (corner.type === "radius") {
					xc = l - corner.r;
					yc = h - corner.r;
					startAngle = angle[0];
					endAngle = angle[1];
				} else {
					p3 = {x: l - corner.x, y: h}
					p4 = {x: l, y: h - corner.y}
				}
				break
			case 'right_bottom':
				if (corner.type === "radius") {
					xc = l - corner.r;
					yc = corner.r;
					startAngle = angle[0];
					endAngle = angle[1];
				} else {
					p3 = {x: l, y: corner.y}
					p4 = {x: l - corner.x, y: 0}
				}
				break
		}

		switch (corner.type) {
			case "line":
				this.contour.forEach((el, i) => {
					let isIntersections = [];
					switch (el.type) {
						case 'line':
							isIntersections = Helpers.findIntersection(
								{x: el.x1, y: el.y1},
								{x: el.x2, y: el.y2},
								p3,
								p4
							)
							if (!empty(isIntersections)) {
								intersections.push({[i]: isIntersections})
							}
							break
						case 'arc':
							isIntersections = Helpers.findIntersectionOfSegmentAndArc(
								p3,
								p4,
								{x: el.xc, y: el.yc},
								el.r,
								el.startAngle,
								el.endAngle
							)
							if(!empty(isIntersections)) {
								intersections.push({[i]: isIntersections})
							}
							break
					}
				})
				break
			default:
				this.contour.forEach((el, i) => {
					let isIntersections = [];
					switch (el.type) {
						case 'line':
							isIntersections = Helpers.findIntersectionOfSegmentAndArc(
								{x: el.x1, y: el.y1},
								{x: el.x2, y: el.y2},
								{x: xc, y: yc},
								corner.r,
								startAngle,
								endAngle
							)
							if (!empty(isIntersections)) {
								intersections.push({[i]: isIntersections})
							}
							break
						case 'arc':

							isIntersections = Helpers.findIntersectionOfTwoArcs(
								{x: el.xc, y: el.yc},
								el.r,
								el.startAngle,
								el.endAngle,
								{x: xc, y: yc},
								corner.r,
								startAngle,
								endAngle
							)
							if (!empty(isIntersections)) {
								isIntersections.map(coordinate => intersections.push({[i]: coordinate}))
							}
							break
					}
				})
				break
		}
		if (!empty(intersections)) {
			let startIndex, endIndex, startPoints, endPoints, contour1, contour2, countsToDelete = 0;
			if(intersections.length > 2 &&  corner.type === "radius" && corner.angle === 'left_top'){

					intersections.splice( 2)
			}
			if (intersections.length > 2 && corner.type === "radius" && corner.angle === 'right_top') {
				if(corner.r === l && corner.r === h ){
					intersections.pop()
					intersections.shift()
				}else if( corner.r === h){
					//needs fix in the future
					if(l < h *2){
						intersections.shift()
						intersections.pop()

					}else if(l ===  h * 2){
						const left_top = this.corners.find(({ angle }) => angle === 'left_top')
                        if(left_top?.r < h){
							intersections.splice(3)

						}else{
							intersections.splice(2)

						}
					}
					else {

						intersections.splice(3)

					}

				}
				else if( corner.r === l){

					intersections.shift()
				}
				else if(corner.r === l/2){

					intersections.splice(0,1)
				}

				else{
					//needs fix in the future
					// intersections.splice(2)
					intersections.shift()
				}
			}
			if (intersections.length > 2 && corner.type === "line" && (corner.angle === 'left_top')) {
				//needs fix in the future
				intersections.splice(2)
                // 	intersections.pop();

			}

			if (intersections.length > 2 && corner.type === "line" &&  corner.angle === 'right_bottom') {
				intersections.shift();
			}
			if (intersections.length > 2 && corner.type === "radius" &&  corner.angle === 'right_bottom') {
				intersections.splice(0,1)

				// intersections.splice(2)
			}
			if (intersections.length < 2) {
				return false;
			}
			intersections.forEach((el, i) => {
				if (i === 0) {
					startIndex = Number(Object.keys(el)[0]);
					contour1 = {...this.contour[startIndex]};
					startPoints = el[startIndex];
					if (corner.angle === 'left_bottom') {
						contour1.x1 = el[startIndex].x
						contour1.y1 = el[startIndex].y;
					} else {
						contour1.x2 = el[startIndex].x;
						contour1.y2 = el[startIndex].y;
					}
				} else if (i === intersections.length - 1) {
					endIndex = Number(Object.keys(el)[0]);
					contour2 = {...this.contour[endIndex]};
					endPoints = el[endIndex];
					if (corner.angle === 'left_bottom') {
						contour2.x2 = el[endIndex].x;
						contour2.y2 = el[endIndex].y;
					} else {
						contour2.x1 = el[endIndex].x;
						contour2.y1 = el[endIndex].y;
					}
				}
			})

			const _corner = addCorner(startPoints, endPoints)
			if (corner.angle === 'left_bottom') {
				this.contour.splice(0, 1, contour1)
				this.contour.splice(endIndex, 1, contour2, _corner)
			} else {
				if (this.contour.length > 3) {
					this.contour.splice(startIndex, endIndex - startIndex + 1, contour1, _corner, contour2)
				} else {
					switch (corner.angle) {
						case 'right_bottom':
							this.contour.splice(endIndex + 1, 1, _corner);
							break;
						case 'right_top':
							this.contour.splice(startIndex + 1, 1, _corner);
							break;
						case 'left_top':
							this.contour.splice(startIndex, 1, _corner);
							break;
					}
				}

			}

		}
	}

	addCornerEdgesToSides() {
		const {corners} = this._detail;
		const detail = this._detail;
		const edges = this.edges;
		const construction = this._detail.parent;
		const len = this.contour.length
		const nextIndex = i => {
			if (!empty(this.contour[i + 1])) {
				return i + 1
			} else {
				return 0
			}
		}
		const prewIndex = i => {
			if (i === 0) {
				return len - 1
			}
			return i - 1
		}
		const removeEdgeSideForWidthOrHeightCorner = () => {
			if(!empty(edges)){

			corners.map(corner => {
				if(corner.angle === "left_top"){
					if(corner.y === detail.h){
						edges.left = null
					}else if(corner.x === detail.l){
						edges.top = null
					}
				}else if(corner.angle === "right_top"){
					if(corner.y === detail.h){
						edges.right = null
					}else if(corner.x === detail.l){
						edges.top = null
					}
				}else if(corner.angle === "left_bottom"){
					if(corner.y === detail.h){
						edges.left = null
					}else if(corner.x === detail.l){
						edges.bottom = null
					}
				}else if(corner.angle === "right_bottom"){
					if(corner.y === detail.h){
						edges.right = null
					}else if(corner.x === detail.l){
						edges.bottom = null
					}
				}

			})
			}
		}

		const _corners = [];
		const usedSides = [];
		// removeEdgeSideForWidthOrHeightCorner()

		corners.map(item => item).reverse().forEach(el => {
			const sides = el.angle.split('_')
			if (el.type === "radius" && el.type !== "line") {
				const pres = _corners.find(_el => {
					return usedSides.includes(sides[0]) || usedSides.includes(sides[1])
				})
				if (!pres) {
					_corners.push({
						edge: el.edge?.index ?? null,
						corner: el.angle
					})
				}
				usedSides.push(...el.angle.split('_'));
			}
		})

		const addCornersToDb = (sides, edge) => {
			return new Promise((resolve, reject) => {
				function addToDB (side){
					if(['left', 'right', 'top', 'bottom'].includes(side) && !empty(side)){
						construction.updateDetailSideEdge(detail, side, edge)
							.then(() => {
								addToDB(sides.shift())
								// if(corners.length === 1){
								// 	let newSide = ''
								// 	if(side === 'left' && !edges[side]){ newSide = 'right' }
								// 	if(side === 'right' && !edges[side]){ newSide = 'left' }
								// 	if(side === 'top' && !edges[side]){ newSide = 'bottom' }
								// 	if(side === 'bottom' && !edges[side]){ newSide = 'top' }
								// 	if(['left', 'right', 'top', 'bottom'].includes(newSide)){
								// 		construction.updateDetailSideEdge(detail, newSide, null)
								// 			.then(() => addToDB(sides.shift()))
								// 			.catch(error => reject(error))
								// 	}else{
								// 		addToDB(sides.shift())
								// 	}
								// }else{
								// 	addToDB(sides.shift())
								// }
							})
							.catch(error => reject(error))
					} else {
						return resolve()
					}
				}
				addToDB(sides.shift())
			})
		}

		const addCornerEdges = (index, edge, currentContour) => {
			if (!empty(this.contour[index])) {
				this.contour[index].edge = edge
				// if (this.contour[index].isCorner) {
				// 	addCornersToDb(index, edge)
				// }
				if (!empty(nextIndex(index)) && nextIndex(index) !== currentContour) {
					if (!(this.contour[index].type === 'line' && this.contour[nextIndex(index)].type === 'line')) {
						addCornerEdges(nextIndex(index), edge, currentContour)
					}
				}
			}
		}

		const addCornerEdges2 = (index, edge, currentContour) => {
			if (!empty(this.contour[index])) {
				this.contour[index].edge = edge
				if (!empty(prewIndex(index)) && prewIndex(index) !== currentContour) {
					if (!(this.contour[index].type === 'line' && this.contour[prewIndex(index)].type === 'line')) {
						addCornerEdges2(prewIndex(index), edge, currentContour)
					}
				}
			}
		}

		_corners.reverse().forEach(corner => {
			const currentContour = this.contour.findIndex(el => el.isCorner === corner.corner);
			addCornerEdges(currentContour, corner.edge, currentContour);
			addCornerEdges2(currentContour, corner.edge, currentContour);
		})
		const sides = [];
		let edge = null;
		corners.forEach(el => {
			if(el.type === 'radius'){
				const tempAngle = el.angle.split('_');
				if(!empty(el?.edge?.index)){
					edge = el.edge.index;
				} else if(!empty(el?.edge) && isNumber(el.edge)) {
					edge = el.edge
				}
				if(!sides.includes(tempAngle[0])){ sides.push(tempAngle[0]) }
				if(!sides.includes(tempAngle[1])){ sides.push(tempAngle[1]) }
			}
		})

		addCornersToDb(sides, edge)
			.then(() => {})
			.catch(error => console.log(error));
	}
	updateBevelsHoles(start, end, side) {
		if(empty(this._detail.bevels)) return;
		const bevel = this._detail.bevels.find(el => el.edgeSide === side);
		if(!empty(bevel)) {
			bevel.ownHoles.push({start, end})
		}
	}
	get h() {
		return this._h
	}

	get l() {
		return this._l
	}
}