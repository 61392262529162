import { useState, useEffect, useRef } from "react";
import CloseBtn from "../../Buttons/CloseBtn";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import { empty } from "../../../../helpers/helper";
import { useSelector } from "react-redux";
import { projectSelectors } from "redux/project";
import Requests from "api/API";
import Errors from "models/Errors";
import './style.scss';
import txtIcon from "../../../../media/txt-icon.svg";
import Loader from "../../Loader";
import Languages from "../../../../translation/Languages";
import {Modal, ModalFooter, ModalHeader} from "../index";

const MAX_TOTAL_SIZE = 20 * 1024 * 1024; // 20MB in bytes
const _errors = new Errors([]);
const setDefaultFormData = (projectId) => ({
	subjectLetter: "",
	textLetter: "",
	files: [],
	projectId,
});

const QARequestModal = ({isOpen, closeModal, openSuccessModal }) => {
  const construction = useSelector(projectSelectors.getConstruction);
  const projectId = construction?.projectData?.id === 0 ? '' : construction?._projectData?.id;
  const [formData, setFormData] = useState(setDefaultFormData(projectId));
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    const totalSize = formData.files.reduce((acc, file) => acc + file.size, 0);
    setIsBtnDisabled(empty(formData.subjectLetter) || empty(formData.textLetter) || totalSize > MAX_TOTAL_SIZE)
  }, [formData]);
	
	const close = () => {
		closeModal();
		setFormData(setDefaultFormData(projectId));
	}

  const submit = async (event) => {
    event.preventDefault();
    setIsLoaded(true)
    setIsBtnDisabled(true)
    const { subjectLetter, textLetter, files, projectId } = formData;
    const dataToSend = {
      title: subjectLetter,
      message: textLetter,
      project_file_id: projectId,
      filename: files,
    };

    Requests.Projects.sendSupportMessage(dataToSend).then((res) => {
      if (res && res.data) {
        setIsLoaded(false)
				close()
        openSuccessModal()
      }
    }).catch(error => {
      _errors.setError(error?.response?.data?.message, 'error', true)
    })
  };

  const handleTextChange = (e) => {
    setFormData({
      ...formData,
      textLetter: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    setFormData({
      ...formData,
      files: [...formData.files, ...selectedFiles].slice(0, 5),

    });
  };

  const handleSubjectChange = (e) => {
    setFormData({
      ...formData,
      subjectLetter: e.target.value,
    });
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  }

  const handleRemoveFile = (index) => {
    const filteredFiles = formData.files.filter((_, i) => i !== index)

    setFormData({
      ...formData,
      files: filteredFiles
    });
    fileInputRef.current.value = null;
  }
  const handleClipboardPaste = async (e) => {
    try {
      const clipboardData = e.clipboardData || window.clipboardData;
      if (clipboardData && clipboardData.items) {
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i];
          if (item.kind === "file" && item.type.startsWith("image/")) {
            const blob = item.getAsFile();
            if (blob) {
              const file = new File([blob], "pasted-image.png", { type: "image/png" });
              setFormData({
                ...formData,
                files: [...formData.files, file].slice(0, 5),
              });
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <form className="request-modal__content modal__wrapper" onSubmit={submit}>
				<ModalHeader className="request-modal__header">
					<h3
						className="request-modal__header-title">{Languages.getTranslation('Kronas-Master-3D-support-service', true)}</h3>
					<span className="request-modal__close-btn"><CloseBtn handler={close}/></span>
				</ModalHeader>
				<div className="request-modal__subject">
					<div className="request-modal__subject-title">{Languages.getTranslation('subject-email', true)}</div>
          <textarea
            className="request-modal__subject-area request-modal__subject-area-title"
            name="subjectLetter"
            placeholder={Languages.getTranslation("text-email", true)}
            value={formData.subjectLetter}
            onChange={handleSubjectChange}
          />
        </div>
        <div className="request-modal__subject">
          <div className="request-modal__subject-title">{Languages.getTranslation('subject-email', true)}</div>
          <textarea
            className="request-modal__subject-area request-modal__subject-area-text"
            name="textLetter"
            cols="100"
            placeholder={Languages.getTranslation("subject-email", true)}
            value={formData.textLetter}
            onChange={handleTextChange}
            onPaste={handleClipboardPaste}
          />
        </div>
          {!empty(formData.files) && (
						<div className="request-modal__additions">
							<span className="request-modal__header-file">{Languages.getTranslation('added-files', true)}</span>
							<div className="request-modal__list">
								{formData.files.map((file, index) => (
									<div key={index} className="request-modal__list-item">
                  <span className="request-modal__list-item-block">
                    <img
											src={file.type.includes('image') ? URL.createObjectURL(file) : txtIcon}
											className="request-modal__list-item_img"
										/>
                    <span className="request-modal__list-item_name">{file.name}</span>
                  </span>
										<span onClick={() => handleRemoveFile(index)} className="request-modal__list-item_trash"></span>
									</div>
								))}
							</div>
						</div>
					
					)}
				<ModalFooter className="request-modal__footer">
					<div className="request-modal__files" onClick={handleFileButtonClick}>
						<input
							className="request-modal__files-input"
							type="file"
							name="files"
							ref={fileInputRef}
							accept=".jpg, .jpeg, .png, .bmp, .ppt, .pptx, .doc, .docx, .pdf, .xls, .xlsx, .json, .xml, .project, .kronas, .b3d, .mpr, .lc4, .gibcut, .ptx, .dwg, .dxf, .wes"
							onChange={handleFileChange}
							multiple
						/>
						<span className="request-modal__files-clip"/>
						<span className="request-modal__files-text">{Languages.getTranslation('add-new-file', true)}</span>
					</div>
					<DefaultBtn title={Languages.getTranslation("send-message", true)} disabled={isBtnDisabled}/>
				</ModalFooter>
			</form>
			{isLoaded && <Loader/>}
		</Modal>
	)
}

export default QARequestModal
