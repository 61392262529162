import React, {useState, useEffect} from "react";
import PrivateTemplate from "views/PrivateTemplate";
import {useNavigate} from "react-router-dom";
import Languages from "translation/Languages";
import Slider from "../../components/UI/Slider/Slider"
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";

import service1 from "media/services/3D_text1.png";
import service2 from "media/services/service2.png";
import service3 from "media/services/service3.png";
import service4 from "media/services/service4.png";
import service5 from "media/services/service5.png";
import service6 from "media/services/service6.png";

import sale from "media/info/sale.png";
import firstOrder from "media/info/first-order.png";
import gift from "media/info/gift.png";
import vector from "media/info/vector.png";
import telegram from "media/info/telegram.png";
import scroll from "media/info/scroll.png";
import constructor from "media/constructor/constructor.svg";
import selectMaterial from "media/constructor/selectMaterial.svg";
import introducedDetailing from "media/constructor/introducedDetailing.svg";
import handleMaterial from "media/constructor/handleMaterial.svg";
import importMaterial from "media/constructor/importMaterial.svg";
import anotherOperations from "media/constructor/anotherOperations.svg";
import pdfGrey from "media/constructor/pdf-grey.svg";
import one from "media/constructor/01.svg";
import two from "media/constructor/02.svg";
import three from "media/constructor/03.svg";
import four from "media/constructor/04.svg";
import five from "media/constructor/05.svg";
import six from "media/constructor/06.svg";
import seven from "media/constructor/07.svg";
import eight from "media/constructor/08.svg";
import location from "media/location.svg";
import kmLogoInfo from "media/kmLogoInfo.svg";

import DefaultBtn from "../../components/UI/Buttons/DefaultBtn";
import {userSelectors} from "redux/user";
import {empty} from "../../helpers/helper";
import Requests from "../../api/API";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";
import SaleInfo from "../../components/SaleInfo";
import CONSTANTS from "../../config/constants";

const services = [
    {
        id: "1",
        name: {
            "uk": "Зручна 3D-візуалізація для перегляду деталей в різних площинах.",
            "ru": "Удобная 3D-визуализация для просмотра деталей в разных плоскостях.",
            "en": "Convenient 3D visualization for viewing details in different planes.",
            "pl": "Wygodna wizualizacja 3D do przeglądania szczegółów w różnych płaszczyznach.",
            "sk": "Pohodlná 3D vizualizácia na prezeranie detailov v rôznych rovinách."
        },
        image: service1
    },
    {
        id: "2",
        name: {
            "uk": "Створення та використання в одному проєкті декількох різних декорів, як з матеріалів KRONAS, так і з матеріалу клієнта.",
            "ru": "Создание и использование в одном проекте нескольких различных декоров как из материалов KRONAS, так и из материала клиента.",
            "en": "Creation and use of multiple different decors in one project, both from KRONAS materials and client materials.",
            "pl": "Tworzenie i używanie różnych dekorów w jednym projekcie, zarówno z materiałów KRONAS, jak i z materiałów klienta.",
            "sk": "Vytváranie a používanie rôznych dekorov v jednom projekte z materiálov KRONAS aj z materiálu klienta."
        },
        image: service2
    },
    {
        id: "3",
        name: {
            "uk": "Об'єднання збережених проєктів в один, імпорт окремих деталей зі збережених проєктів до новостворених.",
            "ru": "Объединение сохраненных проектов в один, импорт отдельных деталей из сохраненных проектов к вновь созданным.",
            "en": "Merging saved projects into one, importing individual parts from saved projects to newly created ones.",
            "pl": "Łączenie zapisanych projektów w jeden, importowanie poszczególnych elementów z zapisanych projektów do nowo utworzonych.",
            "sk": "Zlúčenie uložených projektov do jedného, import jednotlivých častí z uložených projektov do novo vytvorených."
        },
        image: service3
    },
    {
        id: "4",
        name: {
            "uk": "Створення та збереження в особистому кабінеті серійних замовлень і шаблонів виробів.",
            "ru": "Создание и хранение в личном кабинете серийных заказов и шаблонов изделий.",
            "en": "Creating and saving serial orders and product templates in the personal cabinet.",
            "pl": "Tworzenie i zapisywanie seryjnych zamówień i szablonów produktów w osobistym panelu.",
            "sk": "Vytváranie a ukladanie sériových objednávok a šablón výrobkov v osobnej kancelárii."
        },
        image: service4
    },
    {
        id: "5",
        name: {
            "uk": "Оформлення криволінійної обробки та \"зшивання\" деталей одним замовленням.",
            "ru": "Оформление криволинейной обработки и \"сшивки\" деталей одним заказом.",
            "en": "Processing curved cuts and 'stitching' parts in one order.",
            "pl": "Przetwarzanie cięć krzywoliniowych i „zszywanie” części w jednym zamówieniu.",
            "sk": "Spracovanie krivkových úprav a 'zošívanie' dielov v jednej objednávke."
        },
        image: service5
    },
    {
        id: "6",
        name: {
            "uk": "Самостійне створення, онлайн-оплата та керування замовленнями через KRONAS-Особистий кабінет.",
            "ru": "Самостоятельное создание, онлайн-оплата и управление заказами через KRONAS-Личный кабинет.",
            "en": "Self-creation, online payment, and order management through the KRONAS Personal Cabinet.",
            "pl": "Samodzielne tworzenie, płatność online i zarządzanie zamówieniami przez panel osobisty KRONAS.",
            "sk": "Vlastné vytváranie, online platba a správa objednávok prostredníctvom osobného kabinetu KRONAS."
        },
        image: service6
    }
];


const pdfConsructors = [
    {
        id: "1",
        name: {
            "uk": "робота з конструктором",
            "ru": "работа с конструктором",
            "en": "Working with the constructor",
            "pl": "Praca z konstruktorem",
            "sk": "Práca s konštruktorom"
        },
        image: constructor,
        numImg: one,
        url: 'https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=Robota_z_konstruktorom.pdf&download=1'
    },
    {
        id: "2",
        name: {
            "uk": "Особистий кабінет",
            "ru": "Личный кабинет",
            "en": "Personal cabinet",
            "pl": "Osobisty panel",
            "sk": "Osobný kabinet"
        },
        image: constructor,
        numImg: two,
        url: 'https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=OsobKabinet.pdf&download=1'
    },
    {
        id: "3",
        name: {
            "uk": "Підбір матеріалу",
            "ru": "Подбор материала",
            "en": "Material selection",
            "pl": "Dobór materiału",
            "sk": "Výber materiálu"
        },
        image: selectMaterial,
        numImg: three,
        url: 'https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=Pidbir_materialu.pdf&download=1'
    },
    {
        id: "4",
        name: {
            "uk": "Введення деталювання",
            "ru": "Ввод деталей",
            "en": "Detailing input",
            "pl": "Wprowadzanie detali",
            "sk": "Vkladanie detailov"
        },
        image: introducedDetailing,
        numImg: four,
        url: 'https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=Vvedennya_danykh.pdf&download=1'
    },
    {
        id: "5",
        name: {
            "uk": "обробка матеріалу",
            "ru": "обработка материала",
            "en": "Material processing",
            "pl": "Przetwarzanie materiału",
            "sk": "Spracovanie materiálu"
        },
        image: handleMaterial,
        numImg: five,
        url: 'https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=Obrobka_materialu.pdf&download=1'
    },
    {
        id: "6",
        name: {
            "uk": "імпорт проєкту",
            "ru": "импорт проекта",
            "en": "Project import",
            "pl": "Import projektu",
            "sk": "Import projektu"
        },
        image: importMaterial,
        numImg: six,
        url: "https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=Import.pdf&download=1"
    },
    {
        id: "7",
        name: {
            "uk": "додаткові операції",
            "ru": "дополнительные операции",
            "en": "Additional operations",
            "pl": "Dodatkowe operacje",
            "sk": "Ďalšie operácie"
        },
        image: anotherOperations,
        numImg: seven,
        url: 'https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=Dodatkovi_operatsiyi.pdf&download=1'
    },
    {
        id: "8",
        name: {
            "uk": "Виробничі обмеження",
            "ru": "Производственные ограничения",
            "en": "Production limitations",
            "pl": "Ograniczenia produkcyjne",
            "sk": "Výrobné obmedzenia"
        },
        image: anotherOperations,
        numImg: eight,
        url: 'https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=Production_limitations.pdf&download=1'
    }
];

const InfoPage = (load) => {
  const navigate = useNavigate();
  const user = useSelector(userSelectors.User);
  const [isScrolled, setIsScrolled] = useState(false);
  const [videos, setVideos] = useState([]);
  const date = new Date();
  useUpdateTranslations();
  const checkScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    const fetch = () => {
      Requests.YoutubeV3API.fetchVideoFromPlaylist().then((response) => {
          const convertedVideos = response.items.map((item) => {
            const {publishedAt, title, thumbnails, resourceId} = item.snippet;
            const [date, time] = new Date(publishedAt).toLocaleString().split(",");
            const formattedDate = [date.replaceAll("/", "."), time].join(",");
            return {
              id: item.id,
              title,
              date: formattedDate,
              videoId: resourceId.videoId,
              thumbnails,
            }
          })
          setVideos(convertedVideos);
          return Promise.resolve()
        })
        .catch(error => {
          console.log(error);
          return Promise.reject({Error: error})
        })
    }

    fetch();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  const goToLearning = () => {
    window.open("https://kronas.com.ua/ua/kronas-master-training", "_blank");
  }

  const goToTelegram = () => {
    window.open("https://t.me/kronasmaster3d", "_blank");
  }
  const titleForBtn = () => {
    return (
      <div className="infoPage__main-block-btnTitle">
        <img src={kmLogoInfo} alt='km' width="52px" height="52px"/>
        <span className="infoPage__main-block-btnTitle-text">{Languages.getTranslation('create-new-project')}</span>
      </div>
    )
  }
  const createNewProject = () => {
    if (!empty(user)) {
      navigate("/")
    } else document.location = `${process.env.PERSONAL_ACCOUNT_URL}?previousUrl=${window.location.href}`

  }
  const goToRegister = () => {
    document.location = `${process.env.PERSONAL_ACCOUNT_URL}/#/auth/registration?previousUrl=${window.location.href}`
  }
  return (
    <PrivateTemplate>
      <div className="infoPage">
        <div className="infoPage__main container">
          <div className="infoPage__main-block">
            <div className="infoPage__main-block-titleFirst">
              {Languages.getTranslation('you-are-welcomed')}
            </div>
            <div className="infoPage__main-block-second">
                          <span className="infoPage__main-block-titleSecond">
                            КRONAS MASTER
                          </span>
              <img src={kmLogoInfo} alt="KM" className="infoPage__main-block-img"/>
            </div>

            <div className="infoPage__main-block-desc infoPage__main-block-desc-hint">
              {Languages.getTranslation('info-page-desc')}
            </div>
						<div className="infoPage__main-block-desc-extra infoPage__main-block-desc-hint">
							<p>{Languages.getTranslation('info-page-desc-p-1')}</p>
							<p>{Languages.getTranslation('info-page-desc-p-2')}</p>
						</div>

            {/*<div className="infoPage__main-block-main">*/}
            {/*  <div className="infoPage__main-block-sale">*/}
            {/*    <div className="infoPage__main-block-sale-main">*/}
            {/*      <img src={sale} alt="KM" className="infoPage__main-block-imgIcon"/>*/}
            {/*      <span*/}
            {/*          className="infoPage__main-block-sale-main-text">{Languages.getTranslation('sale-15')}</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
						
              {/*<div className="infoPage__main-block-sale">*/}
              {/*  <div className="infoPage__main-block-sale-main">*/}
              {/*    <img src={firstOrder} alt="KM" className="infoPage__main-block-imgIcon"/>*/}
              {/*    <div*/}
              {/*        className="infoPage__main-block-sale-main-text2">*/}
              {/*      {Languages.getTranslation('first-order-100')}*/}
              {/*      <div>*/}
              {/*        {Languages.getTranslation('first-order-100-date06')}*/}
              {/*      </div>*/}
						
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
						
              {/*<div className="infoPage__main-block-sale">*/}
              {/*  <div className="infoPage__main-block-sale-main">*/}
              {/*    <img src={gift} alt="KM" className="infoPage__main-block-imgIcon"/>*/}
              {/*    <span*/}
              {/*        className="infoPage__main-block-sale-main-text">{Languages.getTranslation('laptop-lenovo')}</span>*/}
              {/*  </div>*/}
              {/*</div>*/}
            {/*</div>*/}
						
						<SaleInfo title={Languages.getTranslation("sale-november-2024-title")} conditions={CONSTANTS.saleNovember2024Conditions}/>
            <div className="infoPage__main-block-btn">
              <DefaultBtn
                  className="defaultBtn3"
                  btnStyle={{height: '75px', borderRadius: "62px"}}
                  handler={createNewProject}
                  title={titleForBtn()}
              />
            </div>
            <div className="infoPage__main-block-scroll">
              <img
                  style={{
                    opacity: isScrolled ? '0' : '1',
                    transition: 'opacity var(--transition) ease-in-out'  // изменяем прозрачность в течение 0.5 секунды
                  }}
                  src={scroll}
                  alt="KM"
                  className="infoPage__main-block-imgScroll"
              />
            </div>
          </div>
        </div>

        <div className="infoPage__service">
          <div
              className="infoPage__service-titleMain">
            {Languages.getTranslation('why-needs-work').toUpperCase()}
            <span className="infoPage__service-KM">{Languages.getTranslation('Kronas-Master-3D')}</span>
          </div>
          <div className="infoPage__service-cards">
            {services.map((item) => (

              <div key={item.id} className="infoPage__service-cards-card">
                <div className="infoPage__service-cards-card-imgBlock">
                  <img src={item.image} alt={item.name[Languages.lang]}
                       className="infoPage__service-cards-card-img"/>
                </div>
                <div className="infoPage__service-cards-card-text">{item.name[Languages.lang]}</div>
              </div>

            ))}
          </div>
          {empty(user) && (
            <div className="infoPage__service-registration">
              <div
                className="infoPage__service-registration-text">{Languages.getTranslation('registration-text')}</div>
              <DefaultBtn
                className="defaultBtn3"
                btnStyle={{marginRight: "15px"}}
                title={Languages.getTranslation("to_register", true)} handler={goToRegister}/>
            </div>
          )}

        </div>


        <div className="infoPage__lessons">
          <div className="infoPage__lessons-main">
            <p className="infoPage__lessons-main-titleSecond">
              {Languages.getTranslation('video-lessons').toUpperCase()}
            </p>

          </div>
        </div>
        <div className="infoPage__slider">
          <Slider videos={videos}/>
        </div>


        <div className="infoPage__learning container">

          <div className="infoPage__learning-KM">
                        <span
                          className="infoPage__learning-info-title">{Languages.getTranslation("learning-title", true)}
                        </span>
            <span
              className="infoPage__learning-info-title infoPage__learning-info-titleRed">{Languages.getTranslation("learning-title2", true)}
                        </span>
            <span
              className="infoPage__learning-info-title">{Languages.getTranslation("learning-title3", true)}
                        </span>
            <div
              className="infoPage__learning-info-titleSecond">{Languages.getTranslation("free-registration", true)}</div>
            <DefaultBtn
              className="defaultBtn3"
              btnStyle={{height: '45px', fontSize: '16px'}}

              title={Languages.getTranslation("enroll-in-studies", true)}
              handler={goToLearning}
            />
            <img className="infoPage__learning-imgVector" src={vector} alt='vector'/>

          </div>
          <div className="infoPage__learning-telegram">
            <div className="infoPage__learning-telegram-block">
              <img className="infoPage__learning-imgTelegram" src={telegram} alt='telegram'/>
              <div className="infoPage__learning-telegram-block-text">
                {Languages.getTranslation("go-to-telegram", true)}
              </div>
            </div>
            <div className="infoPage__learning-telegram-line"/>
            <div className="infoPage__learning-telegram-btn">
              <DefaultBtn
                className="defaultBtn4"
                btnStyle={{height: '59px', fontSize: '16px'}}
                title={Languages.getTranslation("subscribe", true)}
                handler={goToTelegram}
              />
            </div>

          </div>
        </div>


        <div className="infoPage__constructor">
          <div className="infoPage__constructor-main">
            <div
              className="infoPage__constructor-main-title">{Languages.getTranslation('work-with-constructor').toUpperCase()}</div>
            <div className="infoPage__constructor-main-cards">
              {pdfConsructors.map((item) => (
                <div key={item.id} className="infoPage__constructor-main-cards-card">

                  <div className="infoPage__constructor-main-cards-card-main">
                    <div
                      className="infoPage__constructor-main-cards-card-text">{item.name[Languages.lang].toUpperCase()}</div>
                    <a href={item.url} target="_blank" title={Languages.getTranslation("download", true)}>
                      <img src={pdfGrey} alt="pfd" className="pointer"/>
                    </a>
                  </div>
                  <div className="infoPage__constructor-main-cards-card-blockImg">
                    <img src={item.image} alt={item.name[Languages.lang]}/>
                  </div>
                  <img src={item.numImg} alt={item.numImg}
                       className="infoPage__constructor-main-cards-card-img"/>
                </div>

              ))}
            </div>
          </div>
        </div>
        <div className="infoPage__footer">
          <div className="infoPage__footer-main">
            <img src={location} alt="локація"/>
            <span className="infoPage__footer-location infoPage__footer-text">{Languages.getTranslation("company-address", true)}</span>
          </div>
          <span className="infoPage__footer-text">{`© 1996-${date.getFullYear()} ${Languages.getTranslation("copyright", true)}`}</span>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default InfoPage;
