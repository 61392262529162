import Helpers from "../3D/Helpers";
import Languages from "../../translation/Languages";
import {empty} from "../../helpers/helper";
import SizeArrow from "../3D/components/SizeArrow";

export default class Sampling {
  cutout
  _x
  _y
  _depth
  _width
  _height
  _elements
  _side
  _x_axis
  _y_axis
  _fullDepth
  _r1
  _r2
  _r3
  _r4
  constructor(cutout) {
    this.cutout = cutout;
    this._x = cutout._x
    this._y = cutout._y
    this._width = cutout.width
    this._height = cutout.height
    this._depth = cutout.depth
    this._elements = cutout.elements
    this._side = cutout._side
    this._fullDepth = cutout.fullDepth
    this._x_axis = cutout._dataForConstructor.x_axis
    this._y_axis = cutout._dataForConstructor.y_axis
    this._dataForConstructor = cutout._dataForConstructor
    this._r1 = cutout._dataForConstructor.r1
    this._r2 = cutout._dataForConstructor.r2
    this._r3 = cutout._dataForConstructor.r3
    this._r4 = cutout._dataForConstructor.r4

    if(empty(cutout.height)) {
      this._height = 30
    }

    if(empty(cutout.width)) {
      this._width = 30
    }

    if(empty(cutout.depth)) {
      this._depth = 6
    }

    if(empty(cutout._x) || this._x === 0) {
      this._x = 6
    }

    if(empty(cutout._y) || this._y === 0) {
      this._y = 6
    }

    if(empty(cutout._dataForConstructor.x_axis)) {
      this._x_axis = "left"
    }

    if(empty(cutout._dataForConstructor.y_axis)) {
      this._y_axis = "bottom"
    }

    if(empty(cutout._side)) {
      this._side = "back"
    }

    if (!empty(cutout._dataForConstructor.x)) {
      this._x = cutout._dataForConstructor.x
    }

    if (!empty(cutout._dataForConstructor.y)) {
      this._y = cutout._dataForConstructor.y
    }

    if (empty(cutout._dataForConstructor.r1)) {
      this._r1 = 10
    }

    if (empty(cutout._dataForConstructor.r2)) {
      this._r2 = 10
    }

    if (empty(cutout._dataForConstructor.r3)) {
      this._r3 = 10
    }

    if (empty(cutout._dataForConstructor.r4)) {
      this._r4= 10
    }

  }

  initRequiredFields() {
    this.cutout.requiredFields = {
      x: {
        max: this.cutout.detail.l - this.width,
        min: 0
      },
      y: {
        max: this.cutout.detail.h - this.height,
        min: 6
      },
      width: {
        min: 20,
        max: this.cutout.detail.l - this.x,
      },
      height: {
        min: 20,
        max: this.cutout.detail.h - this.y
      },
      r1: {
        min: 3,
        max: Math.max(this.width, this.height) - 3
      },
      r2: {
        min: 3,
        max: Math.max(this.width, this.height) - 3
      },
      r3: {
        min: 3,
        max: Math.max(this.width, this.height) - 3
      },
      r4: {
        min: 3,
        max: Math.max(this.width, this.height) - 3
      },
      depth: {
        min: 1,
        max: this.cutout.validateDepthCutoutWithMultiplicity(18)
      },
    }
  }

  addParams() {
    // if(this.isInit) return;
    this.cutout.setFormField({name:'side', label: Languages.getTranslation("side", true), type: 'select', value: this.side, variables: [
        { key: "front", value: "Лицьова" },
        { key: "back", value: "Тильна" }
      ], visible: true})

    this.cutout.setFormField({name: 'y', value: this.y, type: 'number', label: 'y (py)', additionalParam : [
        {name: 'center_y', label: `${Languages.getTranslation("center-on-axis", true)} Y`, callback: () => {
            this.setCenterY();
          }}
      ]})

    this.cutout.setFormField({name: 'x', value: this.x, type: 'number', label: 'x (px)', additionalParam : [
        {name: 'center_x', label: `${Languages.getTranslation("center-on-axis", true)} X`, callback: () => {
            this.setCenterX();
          }}
      ]})

    this.cutout.setFormField({name: 'width', value: this.width, type: 'number', label:  `${Languages.getTranslation("height", true)} (pw)`, visible: true, })

    this.cutout.setFormField({name: 'height', value: this.height, type: 'number', label: `${Languages.getTranslation("width", true)} (ph)`, visible: true, })

    this.cutout.setFormField({name:'x_axis', value: this.x_axis, type: 'select', label: `${Languages.getTranslation("side-to-exist", true)} X`, variables: [
        { key: "left", value: "Ліво" },
        { key: "right", value: "Право" },
      ], visible: true})
    this.cutout.setFormField({name:'y_axis', value: this.y_axis, type: 'select', label: `${Languages.getTranslation("side-to-exist", true)} Y`, variables: [
        { key: "top", value: "Верх" },
        { key: "bottom", value: "Низ" },
      ], visible: true})
    this.cutout.setFormField({name: 'depth', visible: true, label: Languages.getTranslation("depth", true), type: 'number', value: this.depth});
    this.cutout.setFormField({name: 'r2', visible: true, label: "R2", type: 'number', value: this.r2});
    this.cutout.setFormField({name: 'r3', visible: true, label: "R3", type: 'number', value: this.r3});
    this.cutout.setFormField({name: 'r1', visible: true, label: "R1", type: 'number', value: this.r1});
    this.cutout.setFormField({name: 'r4', visible: true, label: "R4", type: 'number', value: this.r4});
  }

  get paramsSorting() {
    const sortingArr = ['side', 'depth', 'x_axis', 'y_axis', 'x', 'y',  'width', 'height', 'r2', 'r3', 'r1', 'r4', 'comment']

    return this.cutout.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  get realData() {
    // this.cutout.updateDataForConstructor({name: 'x_axis', value: this.x_axis})
    // this.cutout.updateDataForConstructor({name: 'y_axis', value: this.y_axis})
    this.cutout.updateDataForConstructor({name: 'x', value: this.x})
    this.cutout.updateDataForConstructor({name: 'y', value: this.y})
    this.cutout.updateDataForConstructor({name: 'r1', value: this.r1})
    this.cutout.updateDataForConstructor({name: 'r2', value: this.r2})
    this.cutout.updateDataForConstructor({name: 'r3', value: this.r3})
    this.cutout.updateDataForConstructor({name: 'r4', value: this.r4})
    // this.cutout.calcSize()
    return {
      height: this.height,
      width: this.width,
      elements: this.generateElements(),
      type: 'Sampling',
      fullDepth: this.cutout.fullDepth,
      x: this.xCalc,
      y: this.yCalc,
      edge: this.cutout.edge,
      x_axis: this.x_axis,
      y_axis: this.y_axis,
      depth: this.depth,
      comment: this.cutout.comment,
      id: this.cutout.id,
      side: this.side,
      dataForConstructor: this.cutout._dataForConstructor
    }
  }
//
  get cutOut() {
    const width = this.width
    const height = this.height
    // this.cutout.fixMultiplicity();
    const cutout = {
      x: this.xCalc,
      y:  this.yCalc,
      side: this.side,
      type: 'cutout',
      subType: this.cutout.subType,
      depth: this.depth,
      multiplicity: this.cutout.multiplicity,
      fullDepth: this.cutout.fullDepth,
      edge: null,
      proc: this.cutout,
      id: this.cutout.id,
      width,
      height,
      color: this.cutout.getMeshColor(),
      elements: this.generateElements()
    }
    return cutout;
  }

  generateElements() {
    const r1 = this.r2
    const r2 = this.r3
    const r3 = this.r4
    const r4 = this.r1
    const elements = [];
    elements.push(Helpers.createLine(0, r4, 0, this.height - r1));
    elements.push(Helpers.createArc(0, this.height - r1, r1, this.height, r1, r1, this.height - r1));
    elements.push(Helpers.createLine(r1, this.height, this.width - r2, this.height));
    elements.push(Helpers.createArc(this.width - r2, this.height, this.width, this.height - r2, r2, this.width - r2, this.height - r2));
    elements.push(Helpers.createLine(this.width, this.height - r2, this.width, r3));
    elements.push(Helpers.createArc(this.width, r3, this.width - r3, 0, r3, this.width - r3, r3));
    elements.push(Helpers.createLine(this.width - r3, 0, r4, 0));
    elements.push(Helpers.createArc(r4, 0, 0, r4, r4, r4, r4));
    return elements;
  }

  addSizesArrows() {
    if(!this.cutout.isActive) return false;
    const pos = this.x_axis + '_' + this.y_axis;
    return SizeArrow.getElementSizeArrow(
      this.x,
      this.y,
      null,
      pos,
      this.cutout.detail
    )
  }

  /*Getters and Setters*/
  get x(){
    return this._x;
  }

  set x (x){
    if(typeof x === 'number'){
      if(!this.cutout.checkRequiredField('x', x)) {
        this._x = Math.abs(x);
        this.cutout.updateDataForConstructor({name: 'x', value: this.x})
        this.cutout.setFormField({name: 'x', value: this.x})
      } else {
        this.cutout.showErrors();
      }
    }
    this.initRequiredFields()
    this.cutout.renderDetail();
  }

  get y(){
    return this._y;
  }

  set y(y){
    if(typeof y === 'number'){
      if(!this.cutout.checkRequiredField('y', y)) {
        this._y = Math.abs(y);
        this.cutout.updateDataForConstructor({name: 'y', value: this.y})
        this.cutout.setFormField({name: 'y', value: this.y})
      } else {
        this.cutout.showErrors();
      }
    }
    this.cutout.renderDetail()
    this.initRequiredFields()
  }

  get height() {
    return this._height;
  }

  set height(height) {
    if(typeof height === 'number'){
      if(!this.cutout.checkRequiredField('height', height)) {
        this._height = Number(height);
        this.cutout.setFormField({name: 'height', value: this.height})
      } else {
        this.cutout.showErrors();
      }
    }
    // this.buildDetail()
    //   .then(() => this.renderDetail());
    this.cutout.renderDetail()
    this.initRequiredFields()
  }

  get width() {
    return this._width;
  }

  set width(width) {
    if(typeof width === 'number'){
      if(!this.cutout.checkRequiredField('width', width)) {
        this._width = Number(width);
        this.cutout.setFormField({name: 'width', value: this.width})
      } else {
        this.cutout.showErrors();
      }
    }
    // this.buildDetail()
    //   .then(() => this.renderDetail());
    this.cutout.renderDetail()
    this.initRequiredFields()
  }

  get depth() {
    return this._depth;
  }

  set depth(depth) {
    this._depth = depth
    this.cutout.setFormField({name: 'depth', value: this.depth})
    this.cutout.fullDepth = depth === this.cutout.detail.w
    this.cutout.renderDetail()
    this.cutout.initRequiredFields()
  }

  setCenterY() {
    this.y = this.cutout.detail.h / 2 - this.height / 2;
    // this.setFormField({name: 'y', value: this.y});
    // this.buildDetail()
    //   .then(() => this.renderDetail());
    // this.renderDetail()
  }

  setCenterX() {
    this.x = this.cutout.detail.l / 2 - this.width / 2
    // this.setFormField({name: 'x', value: this.x});
    // this.buildDetail()
    //   .then(() => this.renderDetail());
    // this.renderDetail()
  }

  get xCalc() {
    if (this.x_axis === "right") {
      return this.cutout.detail.l - this.x - this.width
    } else {
      return this.x
    }
  }

  get yCalc() {
    if (this.y_axis === "top") {
      return this.cutout.detail.h - this.y - this.height;
    } else {
      return this.y;
    }
  }

  get elements() {
    return this._elements;
  }

  set elements(elements) {
    this._elements = elements;
  }

  get side() {
    return this._side;
  }

  set side(side) {
    this._side = side;
    this.cutout.setFormField({name: 'side', value: this.side})
    this.cutout.renderDetail()
  }

  get x_axis() {
    return this._x_axis;
  }

  set x_axis(x_axis) {
    this._x_axis = x_axis;
    this.cutout.setFormField({name: 'x_axis',value: x_axis, label: `${Languages.getTranslation("side-to-exist", true)} х`, type: 'select'})
    this.cutout.updateDataForConstructor({name: 'x_axis', value: x_axis})
    this.cutout.renderDetail()
  }

  get y_axis() {
    return this._y_axis;
  }

  set y_axis(y_axis) {
    this._y_axis = y_axis;
    this.cutout.setFormField({name: 'y_axis',value: y_axis, label: `${Languages.getTranslation("side-to-exist", true)} y`, type: 'select'})
    this.cutout.updateDataForConstructor({name: 'y_axis', value: y_axis})
    this.cutout.renderDetail()
  }

  get fullDepth() {
    return this._fullDepth;
  }

  set fullDepth(fullDepth) {
    this._fullDepth = fullDepth;
  }

  get r1() {
    return this._r1;
  }

  set r1(r1) {
    this._r1 = r1;
    this.cutout.setFormField({name: 'r1', value: this.r1})
    this.cutout.renderDetail()
    this.cutout.initRequiredFields()

  }

  get r2() {
    return this._r2;
  }

  set r2(r2) {
    this._r2 = r2;
    this.cutout.setFormField({name: 'r2', value: this.r2})
    this.cutout.renderDetail()
    this.cutout.initRequiredFields()

  }

  get r3() {
    return this._r3;
  }

  set r3(r3) {
    this._r3 = r3;
    this.cutout.setFormField({name: 'r3', value: this.r3})
    this.cutout.renderDetail()
    this.cutout.initRequiredFields()

  }

  get r4() {
    return this._r4;
  }

  set r4(r4) {
    this._r4 = r4;
    this.cutout.setFormField({name: 'r4', value: this.r4})
    this.cutout.renderDetail()
    this.cutout.initRequiredFields()

  }

  /*Features*/
  rotateDetail(direction, l, h) {
    let x, y, x_axis, y_axis, height, width,  r1, r2, r3, r4;
    [x , y] = [this.y, this.x];
    [height, width] = [this.width, this.height]

    if(direction) {
      y_axis = (this.x_axis === 'right') ? 'bottom' : 'top';
      x_axis = (this.y_axis === 'bottom') ? 'left' : 'right';
      [r1, r2, r3, r4] = [this.r4, this.r1, this.r2, this.r3];
    } else {
      y_axis = (this.x_axis === 'right') ? 'top' : 'bottom';
      x_axis = (this.y_axis === 'bottom') ? 'right' : 'left';
      [r1, r2, r3, r4] = [this.r2, this.r3, this.r4, this.r1];
    }
    [this.r1, this.r2, this.r3, this.r4] = [r1, r2, r3, r4];
    [this.cutout.r1, this.cutout.r2, this.cutout.r3, this.cutout.r4] = [r1, r2, r3, r4];
    this.initRequiredFields();
    this.x_axis = x_axis;
    this.y_axis = y_axis;
    this._x = x;
    this._y = y;

    this.cutout.x_axis = x_axis;
    this.cutout.y_axis = y_axis;
    this.cutout._x = x;
    this.cutout._y = y;

    this._height = height;
    this._width = width;
    this.cutout._height = height;
    this.cutout._width = width;
    return this.cutout.updateDb();

  }

}