import keyMirror from "keymirror";

const actionType = {
  Construction: null,
  Detail: null,
  IsArrowMaterial: null,
  IsArrowEdge: null,
  sceneModel: null,
  Treatment: null,
  ErrorProject: null,
  UpdateDetailsTableMode: null,
  UpdateDetailsTableLimits: null,
  UpdateDetailsTableFocusedRow: null,
  SetDefaultDetailsTableFocusedRow: null,
	SetDefaultDetailsTableLimits: null,
  UpdateProductsInConstruction: null,
  UpdateMaterialsInConstruction: null,
  UpdateDetailInConstruction: null,
  UpdateProductInConstruction: null,
  SetIdDetailFromScene: null,
  ClearConstructor: null,
};

export default keyMirror(actionType);
