import './style.scss'
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import Languages from "../../../../../translation/Languages";
import {Modal} from "../../index";

const WarningModalReplace = ({isOpen, text, save, cancel }) => {
	
  return (
    <Modal isOpen={isOpen}>
        <div className="warning-modal__content modal__wrapper">
            <span className="warning-modal__content-img"/>
            <b className="ahtungwarning-modal__body-decription-title">
                {Languages.getTranslation('warning', true)}
            </b>
            <span className="warning-modal__content-text">{text}</span>
            <div className="warning-modal__content-btns">
             <DefaultBtn title={Languages.getTranslation("cancel", true)} handler={cancel} minWidth="100px"/>
             <DefaultBtn title={Languages.getTranslation("submit", true)} handler={save} minWidth="100px"/>
        </div>
        </div>
    </Modal>
  )
}

export default WarningModalReplace;
