import React from "react";
import File from 'components/UI/Inputs/File'
import {useDispatch} from "react-redux";
import {modalsOperations} from "redux/modals";
import Languages from "../../../../translation/Languages";

const importProject = ({file}) => {
  const dispatch = useDispatch()
  
  const close = () => {
    dispatch(modalsOperations.switchStateForModal({
      show: false,
      type: null,
    }))
  }

  const submit = () => {
    dispatch(modalsOperations.switchStateForModal({
      show: true,
      type: 'DETAILS',
    }))
  }

  return (
    <>
      <div className="modal-type-create modal__wrapper">
        <h2>{Languages.getTranslation('program-to-import', true)}</h2>
        <div className="files-options">
          <File type={''} name={'Импорт PRO 100'} />
          <File type={'.fxm'} name={'Imos (fxm)'} />
          <File type={'.xml,.obl'} name={'Импорт БМ (xml, obl)'} />
          <File type={'.xml'} name={'Импорт BCAD (xml)'} />
          <File type={'.xml,.wes'} name={'Woody2 (xml, wes)'} />
          <File type={'.mpr'} name={'WoodWood (mpr)'} />
          <File type={'.pd4'} name={'iLENIA (pd4)'} />
        </div>
        <div className="actions">
          <button onClick={submit} className={'submit ' + (file && file.payload && Object.keys(file.payload.file).length === 0 || Object.keys(file).length === 0 ? "not-active" : "")}>Импортировать</button>
          <button onClick={close} className="cancel">{Languages.getTranslation('cancel', true)}</button>
        </div>
      </div>
    </>
  )
}

export default importProject