import {useEffect, useState} from "react";
import "./style.scss";
import Languages from "../../../translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import Header from "./components/Header";
import Accordion from "./components/Accordion";
import Item from "./components/Item";
import Actions from "./components/Actions";
import {Divider} from "../Divider";

const INITIAL_ACCORDION_STATE = {
	materials: false,
	multiplicity: false,
	texture: false,
	thickness: false,
	edges: false,
	products: false,
}

const INITIAL_FLAGS_STATE = {
	haveTexture: false,
	isNotTexture: false,
	haveMultiplicity: false,
	isNotMultiplicity: false,
}

const generateFilterItems = (item) => ({...item, isChecked: false});
const checkIsItemChecked = ({isChecked}) => isChecked;
const resetArrFilters = (items) => items.map(generateFilterItems);
const syncFilterItems = (prevItems, newItems, uniqueProp) => {
    return newItems.map(newItem => {
        const existingItem = prevItems.find(item => item[uniqueProp] === newItem[uniqueProp]);
        return existingItem ? { ...newItem, isChecked: !!existingItem.isChecked } : { ...newItem, isChecked: false };
    });
};
const filterUniqueItems = (items, key) => {
	return items.filter((obj, index, arr) => {
		return arr.findIndex(item => item[key] === obj[key]) === index;
	});
};
const selectItem = (material, dispatcher, identityProp) => {
	dispatcher((prev) => prev.map((m) => m[identityProp] === material[identityProp] ? {
		...m,
		isChecked: !m.isChecked
	} : m));
};

const FilterSidebar = (
	{
		isOpen,
		setDetails,
		initialDetails,
		setFilteredActive,
		close
	}) => {
	const dispatch = useDispatch();
	const [openedAccordions, setOpenedAccordions] = useState(INITIAL_ACCORDION_STATE);
	const construction = useSelector(projectSelectors.getConstruction);
	const [materials, setMaterials] = useState(() => construction.materials.map(generateFilterItems));
	const [edges, setEdges] = useState(() => construction.edges.map(generateFilterItems));
	const [thickness, setThickness] = useState(() => {
		return initialDetails.map(generateFilterItems).filter((obj, index, arr) => {
			return arr.findIndex((item) => item._w === obj._w) === index;
		});
	});
	const [products, setProducts] = useState(() => construction._products.map(generateFilterItems));
	const [filterFlags, setFilterFlags] = useState(INITIAL_FLAGS_STATE);
	const updateFlag = (name) => {
		setFilterFlags((prev) => ({...prev, [name]: !prev[name]}))
	}
	const filterDetailsByEdge = (currentDetails) => {
		const filteredIndexEdges = edges.filter(checkIsItemChecked).map(({index}) => index);

		return currentDetails.filter(({edges}) => {
			const edgeValues = Object.values(edges).filter((v) => typeof v === "number");
			return edgeValues.some(edge => filteredIndexEdges.includes(edge));
		});
	}
	const filterDetailsByThickness = (currentDetails) => {
		const filteredIndexThickness = thickness.filter(checkIsItemChecked).map(({_w}) => _w);
		return currentDetails.filter((det) => filteredIndexThickness.includes(det.w));
	}
	const filterDetailsByTexture = (currentDetails) => {
		if (filterFlags.haveTexture === filterFlags.isNotTexture) {
			return currentDetails; // This covers both cases where both are true or both are false.
		}

		return currentDetails.filter((det) => filterFlags.haveTexture ? det.isRotateTexture : !det.isRotateTexture);
	}
	const filterDetailsByMyltiplicity = (currentDetails) => {
		if (filterFlags.haveMultiplicity === filterFlags.isNotMultiplicity) {
			return currentDetails; // Return all details if both are true or both are false.
		}

		return currentDetails.filter((det) => filterFlags.haveMultiplicity ? !!det.multiplicity : !det.multiplicity);
	}
	const filterDetailsByMaterials = (currentDetails) => {
		const filteredIndexMaterials = materials.filter(checkIsItemChecked).map(({index}) => index);
		return currentDetails.filter((det) => filteredIndexMaterials.includes(det.material) || filteredIndexMaterials.includes(det.multiplicity?.material));
	}
	const filterDetailsByProducts = (currentDetails) => {
		const filteredProductsIds = products.filter(checkIsItemChecked).map(({id}) => id);

		return currentDetails.filter((det) => filteredProductsIds.includes(det.productId));
	}
	const checkSelectedFilters = () => {
		const isMaterialChecked = materials.some(checkIsItemChecked);
		const isEdgesChecked = edges.some(checkIsItemChecked);
		const isThicknessChecked = thickness.some(checkIsItemChecked);
		const isProductsChecked = products.some(checkIsItemChecked);
		const isMultiplicityChecked = filterFlags.haveMultiplicity || filterFlags.isNotMultiplicity;
		const isTextureChecked = filterFlags.haveTexture || filterFlags.isNotTexture;

		return {
			isMaterialChecked,
			isEdgesChecked,
			isThicknessChecked,
			isProductsChecked,
			isMultiplicityChecked,
			isTextureChecked,
		};
	}
	const checkFilteredProps = () => {
		setFilteredActive(checkSelectedFilters());
	}
	
	const filterDetails = () => {
		const {
			isThicknessChecked,
			isProductsChecked,
			isMultiplicityChecked,
			isTextureChecked,
			isEdgesChecked,
			isMaterialChecked
		} = checkSelectedFilters();

		let updatedDetails = initialDetails;

		const filters = [
				{condition: isMaterialChecked, filterFn: filterDetailsByMaterials},
				{condition: isEdgesChecked, filterFn: filterDetailsByEdge},
				{condition: isThicknessChecked, filterFn: filterDetailsByThickness},
				{condition: isProductsChecked, filterFn: filterDetailsByProducts},
				{condition: isTextureChecked, filterFn: filterDetailsByTexture},
				{condition: isMultiplicityChecked, filterFn: filterDetailsByMyltiplicity},
			];

		filters.forEach(({condition, filterFn}) => {
				if (condition) {
					updatedDetails = filterFn(updatedDetails);
				}
			});

		setDetails(updatedDetails);
	}
	const acceptSelectedFilterParams = () => {
		filterDetails();
		checkFilteredProps();
		dispatch(projectOperations.setDefaultDetailsTableFocusedRow());
	}
	const toggleAccordion = (name) => {
		setOpenedAccordions((prev) => ({...prev, [name]: !prev[name]}));
	}
	const resetFilters = () => {
		[setMaterials, setEdges, setThickness, setProducts].forEach(setter => setter(resetArrFilters));
		setFilterFlags(INITIAL_FLAGS_STATE);
		setOpenedAccordions(INITIAL_ACCORDION_STATE);
	};

	useEffect(() => {
		setMaterials(prevMaterials => syncFilterItems(prevMaterials, construction.materials, "guid"));
	}, [construction._materials]);

	useEffect(() => {
		setEdges(prevEdges => syncFilterItems(prevEdges, construction.edges, "guid"));
	}, [construction._edges]);

	useEffect(() => {
		setThickness(prevThickness => {
			const syncedItems = syncFilterItems(prevThickness, initialDetails, "_id");
			return filterUniqueItems(syncedItems, '_w');
		});
		
		// Filter details if some property in the detail was changed
		filterDetails();
	}, [initialDetails]);

	useEffect(() => {
		setProducts(prevProducts => syncFilterItems(prevProducts, construction._products, "id"));
	}, [construction._products]);

	return (
		<div className={`filter-sidebar ${isOpen ? "filter-sidebar--opened" : ""}`}>
			<Header close={close}/>
			<Divider/>
			<div className="filter-sidebar_content">
				<Accordion
					title={Languages.getTranslation('materials', true)}
					isOpen={openedAccordions["materials"]}
					onToggle={() => toggleAccordion("materials")}
				>
					{materials.map((material, index) => (
						<Item
							key={material.article}
							item={material}
							index={index}
							onSelect={() => selectItem(material, setMaterials, "article")}
						/>
					))}
				</Accordion>

				{edges.length !== 0 && (
					<Accordion
						title={Languages.getTranslation('edges', true)}
						isOpen={openedAccordions["edges"]}
						onToggle={() => toggleAccordion("edges")}
					>
						{edges.map((edge, index) => (
							<Item
								key={edge.article}
								index={index}
								item={edge}
								onSelect={() => selectItem(edge, setEdges, "article")}
							/>
						))}
					</Accordion>
				)}

				{thickness.length !== 0 && (
					<Accordion
						title={Languages.getTranslation('thickness', true)}
						isOpen={openedAccordions["thickness"]}
						onToggle={() => toggleAccordion("thickness")}
					>
						{thickness.map((item) => (
							<Item
								key={item._w}
								item={item}
								nameKey="_w"
								onSelect={() => selectItem(item, setThickness, "_w")}
							/>
						))}
					</Accordion>
				)}

				<Accordion
					title={Languages.getTranslation('texture', true)}
					isOpen={openedAccordions["texture"]}
					onToggle={() => toggleAccordion("texture")}
				>
					<Item
						item={{isChecked: filterFlags.haveTexture}}
						onSelect={() => updateFlag("haveTexture")}
						nameKey="with-texture"
					/>
					<Item
						item={{isChecked: filterFlags.isNotTexture}}
						onSelect={() => updateFlag("isNotTexture")}
						nameKey="without-texture"
					/>
				</Accordion>

				<Accordion
					title={Languages.getTranslation('multiplicity', true)}
					isOpen={openedAccordions["multiplicity"]}
					onToggle={() => toggleAccordion("multiplicity")}
				>
					<Item
						item={{isChecked: filterFlags.haveMultiplicity}}
						onSelect={() => updateFlag("haveMultiplicity")}
						nameKey="with-multiplicity"
					/>
					<Item
						item={{isChecked: filterFlags.isNotMultiplicity}}
						onSelect={() => updateFlag("isNotMultiplicity")}
						nameKey="without-multiplicity"
					/>
				</Accordion>
				{products.length !== 0 && (
					<Accordion
						title={Languages.getTranslation('products', true)}
						isOpen={openedAccordions["products"]}
						onToggle={() => toggleAccordion("products")}
					>
						{products.map((product, index) => (
							<Item
								key={product.id}
								index={index}
								item={product}
								nameKey="name"
								onSelect={() => selectItem(product, setProducts, "id")}
							/>
						))}
					</Accordion>
				)}
			</div>
			<Divider/>
			<Actions filterDetails={acceptSelectedFilterParams} resetFilters={resetFilters}/>
		</div>
	);
};

export default FilterSidebar;
