import {useMemo} from "react";
import Languages from "../translation/Languages";
import multiplicity from "../media/multiplicity.png";
import multiplicity2 from "../media/multiplicity2.png";
import multiplicity3 from "../media/multiplicity5.png";
import multiplicity4 from "../media/multiplicity4.png";
import multiplicity5 from "../media/multiplicity6.png";
import multiplicity6 from "../media/multiplicity3.png";
import multiplicity7 from "../media/multiplicity7.png";
import multiplicity8 from "../media/multiplicity8.png";
import {useSelector} from "react-redux";
import {langSelectors} from "redux/lang";

export const useMultiplicities = () => {
	const curLang = useSelector(langSelectors.getLangState);
	
	return useMemo(() => ([
			{
				name: Languages.getTranslation("none", true),
				nameMin: Languages.getTranslation("none", true),
				number: 0,
				description: Languages.getTranslation("none", true),
				material: false,
				edges: false,
				"isRotateTexture-text": Languages.getTranslation('multiplicity_type1-text_isRotateTexture', true)
			},
			{
				name: Languages.getTranslation('equally', true),
				nameMin: Languages.getTranslation('equally', true),
				number: 1,
				description: Languages.getTranslation('equally-description', true),
				isRotateTexture: false,
				material: true,
				edges: false,
				img: multiplicity
			},
			{
				name: Languages.getTranslation('other-material', true),
				nameMin: Languages.getTranslation('other-material', true),
				number: 2,
				description: Languages.getTranslation('other-material-description', true),
				isRotateTexture: true,
				material: true,
				edges: false,
				img: multiplicity2
			},
			{
				name: Languages.getTranslation('contours', true),
				nameMin: Languages.getTranslation('contours', true),
				number: 3,
				description: Languages.getTranslation('contours-description', true),
				isRotateTexture: false,
				material: true,
				edges: true,
				img: multiplicity3
			},
			{
				name: Languages.getTranslation('strips-with-vertical-jumper', true),
				nameMin: Languages.getTranslation('strips-with-vertical-jumper_min', true),
				number: 4,
				description: Languages.getTranslation('strips-with-vertical-jumper-description', true),
				isRotateTexture: false,
				material: true,
				edges: true,
				img: multiplicity4
			},
			{
				name: Languages.getTranslation('strips-with-horizontal-jumper', true),
				nameMin: Languages.getTranslation('strips-with-horizontal-jumper_min', true),
				number: 5,
				description: Languages.getTranslation('strips-with-horizontal-jumper-description', true),
				isRotateTexture: false,
				material: true,
				edges: true,
				img: multiplicity5
			},
			{
				name: Languages.getTranslation('top-stitching', true),
				nameMin: Languages.getTranslation('top-stitching_min', true),
				number: 6,
				description: Languages.getTranslation('top-stitching-description', true),
				isRotateTexture: false,
				material: true,
				edges: true,
				img: multiplicity6
			},
			{
				name: Languages.getTranslation('on-3-sides', true),
				nameMin: Languages.getTranslation('on-3-sides_min', true),
				number: 7,
				description: Languages.getTranslation('top-stitching-description', true),
				isRotateTexture: false,
				material: true,
				edges: true,
				img: multiplicity7
			},
			{
				name: Languages.getTranslation('bottom-stitching', true),
				nameMin: Languages.getTranslation('bottom-stitching_min', true),
				number: 8,
				description: Languages.getTranslation('bottom-stitching-description', true),
				isRotateTexture: false,
				material: true,
				edges: true,
				img: multiplicity8
			},
		]
	), [curLang]);
}