import {useState} from 'react'
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {modalsOperations} from "redux/modals";
import {useDispatch} from "react-redux";
import {SearchClient} from "../../../ClientSearch";
import {ModalFooter, ModalHeader} from "../index";

const ChooseOwner = () => {
	const [user, setUser] = useState(null);
	const dispatch = useDispatch()
	
	const submit = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "CUSTOMER_WAREHOUSE",
				isEdit: user.phone,
			})
		);
	}
	
	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};
	
	const selectClient = (client) => {
		setUser(client);
	}
	
	return (
		<>
			{<div className="saveToOwnerProfile modal__wrapper">
				<ModalHeader>
					<h3 className="modal-save-project__title">{Languages.getTranslation('connect-to-owner', true)}</h3>
				</ModalHeader>
				<div className="modal-save-project__body">
					<div className="modal-save-project__form-control">
						<label
							htmlFor="search-client-in-choose-owner"
							className="modal-save-project__form-control-label"
						>
							{Languages.getTranslation('enter-name-or-phone', true)}
						</label>
						<SearchClient id="search-client-in-choose-owner" onSelect={selectClient}/>
					</div>
				</div>
				<ModalFooter className="modal-save-project__btns">
					<DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
					<DefaultBtn title={Languages.getTranslation('join', true)} handler={submit} disabled={!user}/>
				</ModalFooter>
			</div>}
		</>
	)
}
export default ChooseOwner
