import store from "redux/store";
import { errorMessageOperations } from "../redux/errors";
import {v4 as uuidv4} from "uuid";
import {projectOperations} from "redux/project";
import {toast} from "react-toastify";

/**
 * This is a class for handle all errors.
 * @class
 * @classdesc This is a class for handle all errors.
 */

export default class Errors{
    _errorsMessage = [];
    _detailId;
    constructor(errorsMessage, detailId = null) {
        // this._errorsMessage = errorsMessage;
        this._detailId = detailId;
    }

    addError(message, type, index, uidOperation, processId){
        const { isShow } = store.getState().project.project.errors
        let isNotExist = true
        this.allErrorsMessage.forEach(i => {
            if(i.message === message) {
                isNotExist = false
            }
        })

        isNotExist && this._errorsMessage.push({message, type, index});

        if(window.location.pathname.includes('/details')) {
            store.dispatch(projectOperations.errorProject({message, isShow: isShow, type}))
        }else{
            if(window.location.pathname === '/processes' && (uidOperation === processId ||
                ['top', 'bottom', 'left', 'right'].includes(index))){
                toast[type](message, {
                    onClose: () => {},
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    toastId: `${message}`,
                })
            }
        }
    }

    set detailId(id){
        this._detailId = id;
    }

    get detailId(){
        return this._detailId;
    }

    set typeModel(type){
        this._typeModel = type;
    }

    get typeModel(){
        return this._typeModel;
    }

    get allErrorsMessage(){
        return this._errorsMessage;
    }

    allErrorsById(id){
        return this._errorsMessage.map(item => item.id === id)
    }

    deleteErrorProcessing(message){
        this._errorsMessage = this._errorsMessage.filter(item => item !== message)
    }

    dropErrors(){
        // store.dispatch(errorMessageOperations.removeAllErrors())
        this._errorsMessage = []
    }

    setError(message, type) {
        this.addError(message, type)
        // if(window.location.pathname !== '/processes') {
        //     store.dispatch(errorMessageOperations.switchStateError({message, type}))
        // }

        // const { isShow } = store.getState().project.project.errors
        //
        // if(!isShow) {
        //     store.dispatch(projectOperations.errorProject(
        //         {message: error, isShow: isShow}))
        // }else{
        //     // store.dispatch(errorMessageOperations.switchStateError(
        //     //     { message: error, type, errorId: uuidv4() }))
        // }
    }
}