import './style.scss';
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import ahtung from '../../../../media/ahtuung.png'

const AhtungModalVersion = ({close, version}) => {


    return <div className='modal'>
        <div className='AhtungModalVersion modal__wrapper'>
            <img src={ahtung} alt="ahtung"/>
            <div className="AhtungModalVersion__body">
                <div className="AhtungModalVersion__body-decription">
                    <b className="AhtungModalVersion__body-decription-title">
                        {Languages.getTranslation('warning', true)}</b>
                    <div className="AhtungModalVersion__body-decription-body">
                        <span>
                            {`${Languages.getTranslation('old-version', true)}: "${version.last}"
                                ${Languages.getTranslation('old-current' )}`}</span>

                    </div>
                    <div className="AhtungModalVersion__body-decription-body mt-20">
                        <a onClick={() => {
                            setTimeout(() => {
                                close()
                            }, 200)
                        }}
                            className='AhtungModalVersion__body-decription-body_link'
                            href='https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=bazis_converter.zip&download=1' target='_blank'>
                            {`${Languages.getTranslation('link-version', true)}: "${version.last}".`}</a>
                    </div>
                </div>
            </div>
            <div className="AhtungModalVersion__actions">
                <DefaultBtn
                    title={Languages.getTranslation("close", true)}
                    handler={close}
                />
            </div>
        </div>
    </div>

}

export default AhtungModalVersion