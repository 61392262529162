import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import CloseBtn from "../../Buttons/CloseBtn";
import DefaultBtn from "../../Buttons/DefaultBtn";
import MaterialResiduesItem from "./MaterialResiduesItem/MaterialResiduesItem";
import {modalsOperations} from "redux/modals";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import Languages from "../../../../translation/Languages";
import {empty, validationInput} from "../../../../helpers/helper";
import Requests from "../../../../api/API";
import Loader from "../../Loader";
import {errorMessageOperations} from "redux/errors";
import {projectSelectors} from "redux/project";
import {useNavigate} from "react-router-dom";
import {toastSuccess, toastWarning} from "../../../../helpers/toasts";

const selectOptions = [
  {
    label: 'Київ - Куренівський',
    value: "72"
  },
  {
    label: 'Київ - Корольова',
    value: "54096"
  },
]

const MaterialsResidues = () => {
  const [materials, setMaterials] = useState([])
  const [selectedMaterials, setSelectedMaterials] = useState([])
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({
    filialCode: '',
    vendorCode: '',
    l: '',
    w: '',
    range: '',
    textureDirection: "false",
    // damage: "false",
  })
  const [errorFilia, setErrorFilia] = useState(false)
  const [damage, setDamage] = useState('false')
  const [errorVendorCode, setErrorVendorCode] = useState(false)
  const [errorOnSearch, setErrorOnSearch] = useState(false)
  const [loader, setLoader] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [btnIsDisabled, setBtnIsDisabled] = useState(false)
  const construction = useSelector(projectSelectors.getConstruction);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);

  const activeCheckBox = (type, expression) => {
    if (type === "checkbox") {
      return expression
        ? "detailsTab__checkbox detailsTab__checkbox_active"
        : "detailsTab__checkbox";
    } else {
      return expression
        ? "checkbox__icon checkbox__icon_active"
        : "checkbox__icon";
    }
  };

  const selectMaterialHandler = (material) => {
    if (selectedMaterials.includes(material)) {
      const filteredDetails = selectedMaterials.filter(
        (el) => el !== material
      );
      setSelectedMaterials([...filteredDetails]);
    } else {
      setSelectedMaterials([...selectedMaterials, material]);
    }
  };

  const close = () => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  const handlerChange = (e) => {
    const {value, name} = e.target
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handlerChange2 = (type) => {
   if (type === 'textureDirection') {
     setFormData({
    ...formData,
       textureDirection: formData.textureDirection === 'true' ? 'false' : 'true'
     });
   } else {
     setDamage(damage === 'true' ? 'false' : 'true');
   }
  }


  const handleKeyDown = (event, input) => {
    if (event.key === 'Enter') {
      input.current.focus();
    }
  }

  const searchValidation = () => {
    if (!formData.filialCode && !formData.vendorCode) {
      setErrorFilia(true)
      setErrorVendorCode(true)
      return false
    }
    if (!formData.vendorCode ) {
      setErrorVendorCode(true)
      return false
    }
    if (!formData.filialCode) {
      setErrorFilia(true)
      return false
    }
    return true
  }

  const cancelSearchFilters = () => {
    setFormData({
      filialCode: '',
      vendorCode: '',
      l: '',
      w: '',
      range: '',
      textureDirection: 'false',
    })
    setDamage('false')
    setErrorFilia(false)
    setErrorVendorCode(false)
  }

  const fetchDataMaterials = async (needLoader = true) => {
    if (needLoader) {
      setLoader(true)
      setMaterials([])
    }
    const bodyRequest = {
      residueType: 'kronasElement',
      page,
      ...formData,
      ...(damage === "true" ? {damage: "true"} : {})
    }
    const request = await Requests.Materials.getMaterialsKronas(bodyRequest)
    try {
      if (!request) {
        toastWarning(Languages.getTranslation('defaultError', true))
      }
      if (!empty(request?.data)) {
        const editedMaterials = request.data.map(material => {
          material.height = material.length
          material.name = material.decor
          material.count = 1
          material.typeParts = 'kronasElement'
          material.mc_id = material.id
          delete material.length
          delete material.decor
          return material
        })
        setMaterials((prevState) => [...prevState, ...editedMaterials])
      } else {
        setErrorOnSearch(true)
        setMaterials([])
      }
      if (request?.meta.current_page === request?.meta.last_page) {
        setHasMore(false)
        setPage(1);
      } else {
        setPage(prevState => prevState + 1);
        setHasMore(true)
      }
      if (needLoader) {
        setLoader(false)
      }
    } catch (err) {
      toastWarning(Languages.getTranslation('defaultError', true))
    }
  }

  const setAllMaterialsToSelected = () => {
    if (selectedMaterials.length === materials.length) {
      return setSelectedMaterials([])
    }
    return setSelectedMaterials(materials)
  }

  const searchHandler = () => {
    setMaterials([])
    setSelectedMaterials([])
    if (searchValidation()) {
      fetchDataMaterials()
    }
  }

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      if (hasMore) {
        fetchDataMaterials(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    let timer;
    if (errorFilia) {
      timer = setTimeout(() => {
        setErrorFilia(false);
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [errorFilia]);

  useEffect(() => {
    let timer;
    if (errorVendorCode) {
      timer = setTimeout(() => {
        setErrorVendorCode(false);
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [errorVendorCode]);

  useEffect(() => {
    let timer;
    if (errorOnSearch) {
      timer = setTimeout(() => {
        setErrorOnSearch(false);
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [errorOnSearch]);

  const addMaterialsToProject = (arr) => {
    if (empty(selectedMaterials)) {
      return
    }

    let materialsNeeded = [...arr]
    const mainMaterial = construction.materials.find((el) => el.article === formData.vendorCode)
    if (!empty(mainMaterial) && !empty(mainMaterial.parts)) {
      materialsNeeded = arr.filter(el => !mainMaterial.parts.some(el1 => el1.mc_id === el.mc_id))
    }
    materialsNeeded.forEach(el => {
      el.article = `${el.article}_${el.mc_id}`
    })

    if (!empty(materialsNeeded)) {
      setBtnIsDisabled(true)
      construction.setProjectMaterials([...materialsNeeded.map(el => el)]).then(() => {
        dispatch(errorMessageOperations.switchStateError(
          {message: Languages.getTranslation("material-success", true), type: 'success', show: true}))
        close();
        toastSuccess(Languages.getTranslation('save-project-for-residues', true))
        navigate("/details");
      })
    } else {
      close()
    }
  }

  return (
    <div className="materialResidues modal__wrapper">
      <div className="materialResidues__header">
        <span>{Languages.getTranslation('choose-MaterialsResidues', true)}</span>
        <CloseBtn handler={close}/>
      </div>
      <div className="materialResidues__materialOption">
        <div className="materialResidues__materialOption-titleContainer">
          <h6 className="materialResidues__materialOption-title">{Languages.getTranslation('filter', true)}</h6>
        </div>
        <div className="materialResidues__materialOption-mainContent">
          <div className="materialResidues__materialOption-tableHeader">
            <div
              className={`materialResidues__tableHeader-item materialResidues__materialOption-select ${errorFilia && "errorValidationOnLabel"}`}>{Languages.getTranslation('filia', true)}*
            </div>
            <div
              className={`materialResidues__tableHeader-item materialResidues__materialOption-item ${errorVendorCode && "errorValidationOnLabel"}`}>{Languages.getTranslation('article', true)}*
            </div>
            <div
              className="materialResidues__tableHeader-item materialResidues__materialOption-item">{Languages.getTranslation('height', true)}</div>
            <div
              className="materialResidues__tableHeader-item materialResidues__materialOption-item">{Languages.getTranslation('width', true)}</div>
            <div
              className="materialResidues__tableHeader-item materialResidues__materialOption-item ">{Languages.getTranslation('range', true)}</div>

            <div
              style={{visibility: 'hidden', height: 0}}
              className="materialResidues__materialOption-checkBox-container materialResidues__materialOption-texture">
              <span
                className="materialResidues__materialOption-checkBox-text ">{Languages.getTranslation('texture', true)}</span>
              <div className={activeCheckBox("checkbox", formData.textureDirection === 'true')}
                   onClick={() => handlerChange2('textureDirection')}>
                <i className={activeCheckBox("icon", formData.textureDirection === 'true')}></i>
              </div>
            </div>
            <div
              style={{visibility: 'hidden', height: 0}}
              className="materialResidues__materialOption-checkBox-container">
              <span
                className="materialResidues__materialOption-checkBox-text">{Languages.getTranslation('damage-to-the-part', true)}</span>
              <div className={activeCheckBox("checkbox", formData.damage === 'true')}
                   onClick={() => handlerChange2('damage')}>
                <i className={activeCheckBox("icon", formData.damage === 'true')}></i>
              </div>
            </div>
          </div>
          <div className="materialResidues__materialOption-tableBody">
            <div className="materialResidues__materialOption-select ">
              <select
                className={`materialResidues__select materialResidues__materialOption-select ${errorFilia && "errorValidationOnInput"}`}
                value={formData.filialCode} name='filialCode' onChange={(e) => handlerChange(e)}>
                <option value="">-</option>
                {selectOptions.map(el => <option key={el.value} value={el.value}>
                  {el.label}
                </option>)}
              </select>
            </div>
            <input type="number"
                   className={`materialResidues__materialOption-input materialResidues__materialOption-item ${errorVendorCode && "errorValidationOnInput"}`}
                   onInput={(e) => validationInput(e)}
                   onKeyDown={(e) => handleKeyDown(e, input1)}
                   onChange={(e) => handlerChange(e)}
                   value={formData.vendorCode}
                   name='vendorCode'
            />
            <input type="number" className={`materialResidues__materialOption-input materialResidues__materialOption-item`}
                   onInput={(e) => validationInput(e)}
                   onKeyDown={(e) => handleKeyDown(e, input2)}
                   onChange={(e) => handlerChange(e)}
                   ref={input1}
                   value={formData.l}
                   name='l'
            />
            <input type="number" className="materialResidues__materialOption-input materialResidues__materialOption-item"
                   onInput={(e) => validationInput(e)}
                   onKeyDown={(e) => handleKeyDown(e, input3)}
                   onChange={(e) => handlerChange(e)}
                   ref={input2}
                   value={formData.w}
                   name='w'
            />
            <input type="number" className="materialResidues__materialOption-input materialResidues__materialOption-item"
                   onInput={(e) => validationInput(e)}
                   onChange={(e) => handlerChange(e)}
                   value={formData.range}
                   ref={input3}
                   name='range'
            />
            <div className="materialResidues__materialOption-checkBox-container materialResidues__materialOption-texture" >
              <span className="materialResidues__materialOption-checkBox-text ">{Languages.getTranslation('texture', true)}</span>
              <div className={activeCheckBox("checkbox", formData.textureDirection === 'true')} onClick={() => handlerChange2('textureDirection')}>
                <i className={activeCheckBox("icon", formData.textureDirection === 'true')}></i>
              </div>
            </div>
            <div className="materialResidues__materialOption-checkBox-container">
              <span className="materialResidues__materialOption-checkBox-text">{Languages.getTranslation('damage-to-the-part', true)}</span>
              <div className={activeCheckBox("checkbox", damage === 'true')} onClick={() => handlerChange2('damage')}>
                <i className={activeCheckBox("icon", damage === 'true')}></i>
              </div>
            </div>
          </div>

        </div>
        <div className="materialResidues__materialOption-footer">
          <DefaultBtn className={"defaultBtn defaultBtn2"} title={Languages.getTranslation('discount', true)} handler={cancelSearchFilters}/>
          <DefaultBtn className={"defaultBtn defaultBtn2"} title={Languages.getTranslation('search', true)} handler={searchHandler}/>
        </div>
      </div>


      {loader ? <div className='materialResidues__loader'><Loader /></div> :  (empty(materials) ? <div className={`materialResidues__dontHaveMaterial ${errorOnSearch && 'errorOnSearch'}`}>{Languages.getTranslation('dont-have-material', true)}</div> :<div className="materialResidues__table" onScroll={handleScroll}>
        <div className="materialResidues__tableHeader">
          <div className="materialResidues__checkBoxContainer">
            <div className={activeCheckBox("checkbox", selectedMaterials.length === materials.length)} onClick={setAllMaterialsToSelected}>
              <i className={activeCheckBox("icon", true)}></i>
            </div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
            <div className="materialResidues__tableHeader-item">{Languages.getTranslation('article')}</div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
            <div className="materialResidues__tableHeader-item">ID</div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
            <div className="materialResidues__tableHeader-item">{Languages.getTranslation('height', true)}</div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
            <div className="materialResidues__tableHeader-item">{Languages.getTranslation('width', true)}</div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
            <div
              className="materialResidues__tableHeader-item">{Languages.getTranslation('sheet-thickness', true)}</div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
            <div className="materialResidues__tableHeader-item">{Languages.getTranslation('amount', true)}</div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__material">
            <div className="materialResidues__tableHeader-item">{Languages.getTranslation('name', true)}</div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
            <div className="materialResidues__tableHeader-item">{Languages.getTranslation('material-type', true)}</div>
          </div>
          <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
            <div
              className="materialResidues__tableHeader-item">{Languages.getTranslation('damage-to-the-part', true)}</div>
          </div>
        </div>
        <div className="materialResidues__tableBody">
          {materials.map(material => {
            const key = uuidv4()
            return (
              <MaterialResiduesItem key={key}
                                    material={material}
                                    selectedMaterials={selectedMaterials}
                                    selectMaterialHandler={selectMaterialHandler}
                                    activeCheckBox={activeCheckBox}/>
            )
          })}
        </div>
      </div>)}
            {/*<Loader2 />*/}
      {!empty(materials) && <div className="materialResidues__footer">
        <DefaultBtn className={"defaultBtn defaultBtn2"} handler={close}
                    title={Languages.getTranslation('cancel', true)}/>
        <DefaultBtn minWidth={'250px'} className={"defaultBtn defaultBtn2"} disabled={btnIsDisabled} title={Languages.getTranslation('add-to-project', true)} handler={() => addMaterialsToProject(selectedMaterials)}/>
      </div>}
    </div>
  );
};

export default MaterialsResidues;
