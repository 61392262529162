const getModalShowState = ({modals}) => {
  return modals.modals.show
}

const getModalTypeState = ({modals}) => {
  return modals.modals.type
}
const getIsReplacedMaterialForProject = ({modals}) => {
  return modals.modals.isReplacedMaterialForProject
}

const getModalIsEdit = ({modals}) => {
  return modals.modals.isEdit || false
}

export default {
  getModalIsEdit,
  getModalShowState,
  getModalTypeState,
  getIsReplacedMaterialForProject
};
