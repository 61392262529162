import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import img from "../../../../media/material-add-img-test.svg";
import detail_img from "../../../../media/detail_img.svg";
import '../../../../components/CreateProject/Materials/style.scss'
import "./style.scss";
import cloneDeep from "lodash.clonedeep";
import Loader from "../../Loader";
import {projectSelectors} from "redux/project";
import {empty, isFractional} from "../../../../helpers/helper";
import {dontHaveProc} from "../../ChosProcesses";
import DeleteProcessesContainer from "../../DeleteProcesses/DeleteProcessesContainer";
import {Modal, ModalFooter, ModalHeader} from "../index";

const getArr = (arrType, type, detail) => {
    if(!empty(detail)) {
        return detail[arrType]?.filter((item, index) => {
            if (item?.type?.toLowerCase() === type || item?.subType?.toLowerCase() === type) {
                return {...item, index}
            }
        })
    }
}
const getDeleteProcesses = (detail) => {
    return [
        {
            name: Languages.getTranslation('corner', true),
            type: "corner",
            items: detail?.corners.map((item, index) => { return { ...item, index } }),
        },
        {
            name: Languages.getTranslation('groove', true),
            type: "groove",
            items: getArr('rects', 'groove', detail)
        },
        {
            name: Languages.getTranslation('rabbet', true),
            type: "rabbet",
            items: getArr('rects', 'rabbet', detail)
        },
        {
            name: Languages.getTranslation('circle-proc', true),
            type: "circle",
            items: getArr('rects', 'circle', detail)
        },
        {
            name: Languages.getTranslation('rectangle-proc', true),
            type: "rectangle",
            items: getArr('rects', 'rectangle', detail)
        },
        {
            name: Languages.getTranslation('uShape-proc', true),
            type: "uShape",
            items: getArr('mills', 'uShape', detail)
        },
        {
            name: Languages.getTranslation('hole', true),
            type: "hole",
            items: detail?.holes?.map((item, index) => { return { ...item, index, type: 'hole' } }),
        },
        {
            name: Languages.getTranslation('add_mill_title', true),
            type: "mill",
            items: getArr('mills', 'mill', detail),
        },
        {
            name: Languages.getTranslation('add_bevel_title', true),
            type: "bevel",
            items:  detail?.bevels?.map((item, index) => { return { ...item, index, type: 'bevel' } }) ?? [],
        },
    ]
}

const CloneDetailsModal = ({isOpen, close}) => {
    const construction = useSelector(projectSelectors.getConstruction);
    const Details  = cloneDeep(useSelector(state => state.tempDetail.property.tempDetails))

    const inputRef = useRef(null)

    const [load, setLoad] = useState(false)
    const [oneDetail, setOneDetail] = useState(null)


    useEffect(() => {
        if(Details.length === 1){
            construction.getDetailFromDB(Details[0].id)
                .then(data => setOneDetail({...data, newName: data.name += Languages.getTranslation('copy', true)}))
                .catch(error => console.log(error))
        }
        inputRef.current && inputRef.current.focus()
    }, [isOpen]);

    const handlerClone = () => {
        let url = new URL(window.location.href);
        url.searchParams.delete('id');
        let tempDetail = {...oneDetail, corners: [], rects: [], mills: [], holes: [], bevels: []}
        if(Details.length === 1) {
            oneDetail.corners.forEach(item => {
                if (!item.hasOwnProperty('remove')) {
                    tempDetail.corners.push(item)
                }
            })
            oneDetail.rects.forEach(item => {
                if (!item.hasOwnProperty('remove')) {
                    tempDetail.rects.push(item)
                }
            })
            oneDetail.mills.forEach(item => {
                if (!item.hasOwnProperty('remove')) {
                    tempDetail.mills.push(item)
                }
            })
            oneDetail.holes.forEach(item => {
                if (!item.hasOwnProperty('remove')) {
                    tempDetail.holes.push(item)
                }
            })
            if(oneDetail.hasOwnProperty('bevels')){
                oneDetail.bevels.forEach(item => {
                    if (!item.hasOwnProperty('remove')) {
                        tempDetail.bevels.push(item)
                    }
                })
            }
        }


        setLoad(true)
        if(Details.length === 1){
            construction.cloneDetail(tempDetail)
                .then(() => {
                    window.location.href = url;
                    setLoad(false)
                })
                .catch(error => console.log(error))
        }else {
            construction.cloneDetails(Details)
                .then(() => {
                    window.location.href = url;
                    setLoad(false) })
                .catch(error => console.log(error))
        }
    }

    const handlerChange = (event, detail) => {
        if(Details.length === 1){
            oneDetail.newName = event.target.value
        }
        else {
            detail.newName = event.target.value
        }
    }

    const handlerRemove = (item, group = false, all = false) => {
        const addProp = (temp) => {
            if(all){
                temp.remove = true
            }else {
                if(temp.hasOwnProperty('remove') && temp.remove) {
                    delete temp.remove
                    if(temp.type !== "partial"){ delete temp.remove }
                }else {
                    temp.remove = true
                }
            }
        }

        const switchProcc = (pro) => {
            switch (pro.subType || pro.type){
                case 'corner':
                case 'line':
                case 'radius':
                    addProp(oneDetail.corners.find(f => f.id === pro.id))
                    break
                case 'Groove':
                case 'Rabbet':
                case 'Circle':
                case 'Rectangle':
                    addProp(oneDetail.rects.find(f => f.id === pro.id))
                    break
                case 'hole':
                    addProp(oneDetail.holes.find(f => f.id === pro.id))
                    break
                case 'mill':
                    addProp(oneDetail.mills.find(f => f.id === pro.id))
                    break
                case 'bevel':
                    addProp(oneDetail.bevels.find(f => f.id === pro.id))
                    break
            }
        }

        if(group){
            item.items.forEach(i => switchProcc(i))
        }else {
            switchProcc(item)
        }

    }

    return (
        <Modal isOpen={isOpen}>
            <div className="modal-clone-details modal__wrapper">
							<ModalHeader>
								<h3
									className="modal-clone-details__title">{Languages.getTranslation(`change-names-part${Details.length === 1 ? '' : 's'}`, true)}</h3>
							</ModalHeader>
							<div className="modal-clone-details__header">
								<div className="modal-clone-details__info-container" style={{paddingTop: 10, paddingBottom: 7}}>
                        <div className="modal-clone-details__header-item modal-clone-details__number">№</div>
                        <div className="modal-clone-details__header-item modal-clone-details__img">{Languages.getTranslation("Sketch", true)}</div>
                        <div className="modal-clone-details__header-item" style={{ marginLeft: '15px' }}>
                            {Languages.getTranslation("name", true)}
                        </div>
                    </div>
                    <div className="modal-clone-details__data-container">
                        <div style={{ marginLeft: '15px' }}
                             className='modal-clone-details__header-item modal-clone-details__data-item'>
                            {Languages.getTranslation("height", true)}
                        </div>
                        <div className='modal-clone-details__header-item modal-clone-details__data-item'>
                            {Languages.getTranslation("width", true)}
                        </div>
                        <div className='modal-clone-details__header-item modal-clone-details__data-item'>
                            {Languages.getTranslation("amount", true)}
                        </div>
                        <div className='modal-clone-details__header-item modal-clone-details__data-item'>
                            {Languages.getTranslation("thickness", true)}
                        </div>
                    </div>
                </div>
                {load ? <div className="modal-clone-details_loader"><Loader /></div>:
                <div className="modal-clone-details__body">
                    {Details.map((detail, key) => {
                        return (
                            <div key={key} id={key}
                                className={`modal-clone-details__body-item`}>
                                <div className="modal-clone-details__info-container">
                                    <span className=" modal-clone-details__number">{key + 1}</span>
                                    <div className=" modal-clone-details__img">
                                        <img src={detail_img} alt="detail img"/>
                                    </div>
                                    <input
                                        ref={key === 0 ? inputRef : null}
                                        placeholder={detail.name}
                                        type="text"
                                        onChange={event => handlerChange(event, detail)}
                                        className="modal-clone-details__name"
                                    />
                                </div>
                                <div className="modal-clone-details__data-container">
                                    <div className="modal-clone-details__data-item">{!isFractional(Number(detail.l)) ? Math.round(Number(detail.l)) : Number(detail.l).toFixed(1)}</div>
                                    <div className="modal-clone-details__data-item">{!isFractional(Number(detail.h)) ? Math.round(Number(detail.h)) : Number(detail.h).toFixed(1)}</div>
                                    <div className="modal-clone-details__data-item">{detail.count}</div>
                                    <div className="modal-clone-details__data-item">{detail.w}</div>
                                </div>
                            </div>
                        );
                    })}
                    {Details.length === 1 &&
                        <div className="chosProcesses__body">
                        {!empty(oneDetail)
                            && getDeleteProcesses(oneDetail).map((item, key) => {
                                return (
                                    <DeleteProcessesContainer
                                        idx={key}
                                        key={key}
                                        param={item}
                                        detail={oneDetail}
                                        handlerRemove={handlerRemove}
                                    />
                                );
                            })}
                    </div> }
                    {dontHaveProc(Details[0])}
                </div>}
							<ModalFooter className='modal-clone-details__wraperBtn'>
								<div className="modal-clone-details__btns">
									<DefaultBtn title={Languages.getTranslation('close-btn', true)} handler={close}/>
								</div>
								<div className="modal-clone-details__btns">
									<DefaultBtn title={Languages.getTranslation('clone', true)} handler={handlerClone}/>
								</div>
							</ModalFooter>
						</div>
				</Modal>
		);
};

export default CloneDetailsModal;

