import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import "./index.scss";
import {empty, sortDetailsByOrder} from "../../../../helpers/helper";
import {modalsSelectors} from "redux/modals";
import DefaultHeader from "./DefaultView/Header";
import EditHeader from "./EditView/Header";
import {AutoSizer} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {atLeastOneHasErrors, sortByPropertyDesc} from "./helper";
import DefaultTable from "./DefaultView/Table";
import EditTable from "./EditView/Table";
import {useProjectTableContext} from "../../../../hooks/useContexts";

const TableDetails = ({
                          isDetailsModeEqualsList,
                          selectedDetails,
                          setSelectedDetails,
                          setDetails,
                          details,
                          setDeleteDetail,
                          filteredActive
                      }) => {
    const modalState = useSelector(modalsSelectors.getModalShowState);
    const [isOrderConform, setIsOrderConform] = useState(false);
    const {rerenderTable} = useProjectTableContext();
		const hasErrors = atLeastOneHasErrors(details);
    const isAllDetailsSelected = useMemo(() => selectedDetails.length !== 0 && selectedDetails.length === details.length, [selectedDetails, details]);

    const selectDetail = useCallback((detail) => {
        setSelectedDetails((prevSelectedDetails) => {
            if (prevSelectedDetails.includes(detail)) {
                return prevSelectedDetails.filter((el) => el._id !== detail._id);
            } else {
                return [...prevSelectedDetails, detail];
            }
        })
    }, [setSelectedDetails]);

    const setAllDetails = useCallback((event) => {
        if (event.target.checked) {
            setSelectedDetails([...details]);
        } else {
            setSelectedDetails([]);
        }
    }, [setSelectedDetails, details]);

    const remove = useCallback((detail) => {
        setDeleteDetail(detail)
    }, [setDeleteDetail]);

    const sortDetails = (property) => {
        setDetails((prev) => property
                ? sortByPropertyDesc(prev, property)
                : prev.sort(sortDetailsByOrder)
        )
        rerenderTable();
    };

    useEffect(() => {
        if (modalState) {
            setSelectedDetails([]);
        }

        let orderSet = Array(details.length).fill(0);
        let maxOrder = 0;
        details.forEach((d, index) => {
            if (empty(d.order)) {
                d.order = index + 1;
            }
            if (orderSet[d.order - 1] !== 0) {
                d.order = orderSet.lastIndexOf(0) + 1;
            }
            orderSet[d.order - 1] = 1;
            maxOrder = Math.max(maxOrder, d.order);
        });
        setDetails(details.sort(sortDetailsByOrder));
        setIsOrderConform(true);
    }, [modalState]);

    return (
        <>
						{isDetailsModeEqualsList ?
								<DefaultHeader
										hasErrors={hasErrors}
										isAllDetailsSelected={isAllDetailsSelected}
										sortDetails={sortDetails}
										setAllDetails={setAllDetails}
										filteredActive={filteredActive}
								/> :
								<EditHeader
										isAllDetailsSelected={isAllDetailsSelected}
										setAllDetails={setAllDetails}
										filteredActive={filteredActive}
								/>
						}
						<div className="detailsTab__body" style={{height: "100%", minHeight: "400px"}}>
								{isOrderConform ? (
										<div style={{flex: "1 1 auto", height: "100%"}}>
												<AutoSizer>
														{({width, height, registerChild }) => (
																<>{
																		isDetailsModeEqualsList ?
																				<DefaultTable
																						hasErrors={hasErrors}
																						selectedDetails={selectedDetails}
																						remove={remove}
																						selectDetail={selectDetail}
																						width={width}
																						details={details}
																						setDetails={setDetails}
																						height={height}
																				/> :
																				<EditTable
																						ref={registerChild}
																						selectedDetails={selectedDetails}
																						remove={remove}
																						selectDetail={selectDetail}
																						width={width}
																						details={details}
																						setDetails={setDetails}
																						height={height}
																				/>
																}
																</>
														)}
												</AutoSizer>
										</div>
								) : (
										<div></div>
								)}
            </div>
        </>
    );
};

export default TableDetails;
