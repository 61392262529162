import "./style.scss";

const Loader = ({main, style}) => {
    return (
        <div className="loader" style={{height: main && "100vh"}}>
          <div className="spiner-wrapper-hidden">
            <div className="sk-chase sk-center spinner-item" style={style}>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
            </div>
          </div>
        </div>
    );
};

export default Loader;
