import {Tooltip as ReactTooltip} from "react-tooltip";

import {createPortal} from "react-dom";
import CONSTANTS from "../../../config/constants";

export const Tooltip = ({className = "", place = "top", children, ...rest}) => {
	return createPortal(
		<ReactTooltip
			className={`tooltip ${className}`.trim()}
			disableStyleInjection={true}
			variant='light'
			opacity={1}
			place={place}
			globalCloseEvents={{resize: true, escape: true, scroll: true, clickOutsideAnchor: true}}
			{...rest}
		>
			{children}
		</ReactTooltip>, document.querySelector(CONSTANTS.nodeQuerySelector.tooltipContainer))
}