import {useState} from 'react'
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import './style.scss';
import {useDispatch, useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {modalsOperations} from "redux/modals";
import Project from "../../../../models/Project";
import {SearchClient} from "../../../ClientSearch";
import {ModalFooter, ModalHeader} from "../index";

const SaveToOwnerProfile = () => {
	const construction = useSelector(projectSelectors.getConstruction);
	
	const [ownerId, setOwnerId] = useState(null);
	const [nameProj, setNameProj] = useState(construction.projectData.name ?? Languages.getTranslation('new-project', true));
	const dispatch = useDispatch();
	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};
	
	const handleChangeProject = (e) => {
		setNameProj(e.target.value)
	}
	
	const submit = () => {
		Project.editClientProject(nameProj, ownerId, dispatch)
			.then(() => close())
			.catch(e => console.log(e))
	}
	
	const selectClient = (client) => {
		setOwnerId(client.id)
	}
	
	return (
		<>
			{<div className="saveToOwnerProfile modal__wrapper">
				<ModalHeader>
					<h3 className="modal-save-project__title">{Languages.getTranslation('save-project-in-userProfile', true)}</h3>
				</ModalHeader>
				<div className="modal-save-project__body">
					<div className="modal-save-project__form-control">
						<label
							htmlFor="name"
							className="modal-save-project__form-control-label"
						>
							{Languages.getTranslation('enter-project-name', true)}
						</label>
						<input
							id="name"
							onChange={handleChangeProject}
							name="name"
							value={nameProj}
							type="text"
							maxLength="21"
							className="modal-save-project__form-control-input"
						/>
					</div>
					<div className="modal-save-project__form-control">
						<label
							htmlFor="search-client-in-choose-owner"
							className="modal-save-project__form-control-label"
						>
							{Languages.getTranslation('enter-name-or-phone', true)}
						</label>
						<SearchClient id="search-client-in-choose-owner" onSelect={selectClient}/>
					</div>
				</div>
				<ModalFooter className="modal-save-project__btns">
					<DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
					<DefaultBtn title={Languages.getTranslation('save', true)} handler={submit} disabled={!ownerId}/>
				</ModalFooter>
			</div>}
		</>
	)
}
export default SaveToOwnerProfile
