import "./style.scss";

import Select from "react-select";

const defaultStyles = {
	option: (provided, state) => ({
		...provided,
		color: state.isSelected ? '#414141' : 'black',
		backgroundColor: state.isFocused ? '#EFE3EC' : null,
		padding: 10,
		fontSize: '16px',
		cursor: "pointer"
	}),
	control: (provided) => ({
		...provided,
		width: '100%',
		display: 'flex',
		background: "#F0F0F0",
		cursor: "pointer"
	}),
	singleValue: (provided) => ({
		...provided,
		fontSize: '16px',
		cursor: 'pointer'
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 15,
	}),
	placeholder: (provided) => ({
		...provided,
		fontSize: '16px',
		cursor: "pointer"
		
	})
}

const CustomSelect = ({id, handleChange, isSearchable= false, components, customStyles = {}, ...rest }) => {
    const combinedStyles = { ...defaultStyles, ...customStyles }

    return (
        <Select
						inputId={id}
            styles={combinedStyles}
            onChange={handleChange}
            isSearchable={isSearchable}
            components={{
							IndicatorSeparator: () => null,
							...components
						}}
						theme={(theme) => ({
							...theme,
							borderRadius: "4px",
							colors: {
								...theme.colors,
								primary25: '#ffedf9',
								primary: '#a04382',
							},
						})}
						{...rest}
        />
    );
}

export default CustomSelect;
