import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {memo} from "react";
import {Tooltip} from "../../../../Tooltip";
import img from "../../../../../../media/octahedron-cube-morph-PTDDH9qlHJ.png";
import Languages from "../../../../../../translation/Languages";
import "./style.scss";

const Product = ({detailId, productId}) => {
	const construction = useSelector(projectSelectors.getConstruction);
	const product = construction.products.find(p => p.id === productId)
	
	return (
		<>
			<div className="pointer" data-tooltip-id={`productHelper-tooltip-${detailId}`}>
				{construction.products.findIndex(el => el.id === productId) + 1}
			</div>
			<Tooltip id={`productHelper-tooltip-${detailId}`}>
				<div
					className="product-tooltip">
					<img src={img} alt="PRODUCT" className='product-tooltip__image'/>
					<div className="product-tooltip__container">
						<span className="product-tooltip__title">{Languages.getTranslation('product-name', true)}</span>
						<span className="product-tooltip__name">{product?.name}</span>
					</div>
				</div>
			</Tooltip>
		</>
	)
}

export default memo(Product);