import Languages from "../../../translation/Languages";
import {Tooltip} from "../Tooltip";
import "./style.scss";

const buttonsConfig = [
	{
		id: 'import_project_3dConstructor',
		translationKey: 'import3dConstructorDetail',
	},
	{
		id: 'import_project',
		translationKey: 'from-Basis-Meblyar',
	},
	{
		id: 'import_project_id',
		translationKey: 'import-saved-project',
	},
	{
		id: 'import_project',
		translationKey: 'from-GibLab',
	},
	{
		id: 'import_project_gitlab',
		translationKey: 'from-Xsl',
	},
	{
		id: 'import_project_pro100',
		translationKey: 'importPro100Detail',
	},
	{
		id: 'import_project_voody',
		translationKey: 'importWoody2Detail',
	},
];

export const ImportDetailsTooltip = ({isOpen, setIsOpen, importHandler}) => {
	return (<>
		<Tooltip
			id="imports-tooltip"
			place="bottom"
			className="imports-tooltip"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			clickable
			openOnClick
		>
			{buttonsConfig.map(({id, translationKey, isSpan}) => (
				<button
					key={id}
					type="button"
					onClick={() => importHandler(id)}
					className="imports-tooltip__button"
				>
					{Languages.getTranslation(translationKey, true)}
				</button>
			))}
		</Tooltip>
	</>)
}