import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {projectSelectors} from "./../../../../redux/project";
import {constructor3dSelectors} from "./../../../../redux/constructor";
import {userSelectors} from "../../../../redux/user";
import Panel from "../../layuot/panel";
import ProjectDetail from "../ProjectDetail";
import {empty, isset} from "../../../../helpers/helper";
import './styles.scss';
import index from "polygon-clipping";


const ProjectDetails = () => {
	const permissions = useSelector(userSelectors.permissions);
	const projectDetails = useSelector(projectSelectors.getDetails);
	const usedDetails = useSelector(constructor3dSelectors.getCDetails);
	const products = useSelector(constructor3dSelectors.getProducts)
	const productsObject = {};
	const [openedProducts, setOpenedProducts] = useState([0])

	projectDetails.forEach(detail => {
		if(empty(productsObject[detail.productId])) {
			productsObject[detail.productId] = {
				name: products.find(el => el.id === detail.productId).name,
				details: [detail]
			};
		} else {
			productsObject[detail.productId].details.push(detail)
		}

	})

	const toggleProduct = i => {
		const index = openedProducts.indexOf(i)
		if(index === -1) {
			setOpenedProducts([...openedProducts, i])
		} else {
			setOpenedProducts([...openedProducts.slice(0, index), ...openedProducts.slice(index + 1)])
		}
	}

	if(empty(productsObject)) return null;
	if(!permissions.includes('KK_showProjectDetails')) {
		return null;
	}
	return (
		<Panel className="c-details__project c-details__list p__content" icon={'cube'} title='Деталі проекту'>
			{ Object.keys(productsObject).map((product, i) => {
				const opened = openedProducts.includes(i);
				return (
          <div className={'block'} key={product}>
            <div className={'block__header'}>
	            <a className={`block__header-control icon-button-arrow ${opened ? 'hide' : ''}`} onClick={e => toggleProduct(i)}> </a>
	            <span className={'block__header-title'}>{productsObject[product].name}</span>
						</div>
	          {opened ? <div className={`block__body`}>
		          {productsObject[product].details.map(detail => {
			          const isUsed = usedDetails.filter(el => el.detailId === detail.detailId);
			          detail.disabled = !empty(isUsed) && isUsed.length >= detail.count;
			          return (<ProjectDetail
				          key={detail.detailId}
				          detail={detail}
				          used={isUsed.length}
			          />)
		          })}
	          </div>
						: null
						}

          </div>
				)
			})}

		</Panel>
	)
}

export default ProjectDetails;