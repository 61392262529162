import {empty} from "../../../../../../helpers/helper";
import {getArrErrors} from "../../helper";
import {memo} from "react";
import {Tooltip} from "../../../../Tooltip";
import "./styles.scss";

const Errors = ({errorMessages = [], detailId}) => {
    const {errorsByPriority, type} = getArrErrors(errorMessages);

    return (
        <>
            {!empty(errorsByPriority) &&
                <div className='detailsTab__icon-error pointer'
                     data-tooltip-id={errorMessages.length > 0 ? `my-tooltip-${detailId}` : ''}>
                    <svg viewBox="0 0 24 24" width="18px" height="18px"
                         fill={`var(--toastify-icon-color-${type})`}>
                        <path
                            d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>
                    </svg>
                </div>
            }
					<Tooltip id={`my-tooltip-${detailId}`} clickable place="left-end">
						<div className='tooltip-error__body'>
							{errorsByPriority.map((err, idx) => {
								return (
									<div className='tooltip-error__item' key={`error-${idx}`}>
										<div className='tooltip-error__item-img'>
											<svg viewBox="0 0 24 24" width="18px" height="18px"
													 fill={`var(--toastify-icon-color-${err.type})`}>
												<path
													d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>
											</svg>
										</div>
										<div className='tooltip-error__item-text'>
											{err.message}
										</div>
										<br/>
									</div>)
							})}
						</div>
					</Tooltip>
				</>
		)
};

export default memo(Errors);