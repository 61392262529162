import {useState} from "react";
import {useDispatch} from "react-redux";
import "../JoinToSlidingSystemOrder/style.scss";
import Requests from "api/API";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import {errorMessageOperations} from '../../../../redux/errors'
import CustomSelect from "../../Select";
import warningImg from "../../../../media/ahtuung.png";
import {SearchClient} from "../../../ClientSearch";
import {Modal} from "../index";

const JoinToSlidingSystemOrderManager = ({isOpen, close, onContinue}) => {
	const dispatch = useDispatch();
	const [orders, setOrders] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [isOrdersLoading, setIsOrdersLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [client, setClient] = useState(null);
	
	const chooseOrder = (option) => {
		setSelectedOrder(option);
	}
	
	const closeModal = () => {
		close();
		
		setClient(null);
		setIsError(false);
		setSelectedOrder(null);
		setOrders([]);
	}
	
	const submit = () => {
		onContinue(selectedOrder.value);
		closeModal();
	};
	
	const selectClient = (client) => {
		setClient(client);
		setSelectedOrder(null);
		setOrders([]);
		
		if (client) {
			setIsOrdersLoading(true);
			
			const promises = [Requests.Orders.getSSActualOrdersByPhone(client.phone), Requests.Orders.getSSFinalOrdersByPhone(client.phone)];
			
			Promise.all(promises).then((data) => {
				const joinedData = data.map((ssObj) => ssObj.result).flat();

				if (joinedData && joinedData.length) {
					setOrders(joinedData.filter((order) => order.type === "SLIDING_SYSTEMS"));
				}
			}).catch((error) => {
				dispatch(errorMessageOperations.switchStateError(
					{message: error?.message, type: 'warn', show: true}));
				setIsError(true);
			}).finally(() => {
				setIsOrdersLoading(false);
			});
		}
	};
	
	let content;
	
	if (isError) {
		content = <div className="join-to-ss-order-modal__error">
			<div className="join-to-ss-order-modal__error-text">{Languages.getTranslation("service-doesnt-work", true)}</div>
			<DefaultBtn title={Languages.getTranslation('go-back', true)} handler={closeModal}/>
		</div>
	} else {
		content = <>
			<div className="join-to-ss-order-modal__img">
				<img src={warningImg} width="88" height="70" alt="Warning image"/>
			</div>
			<h2
				className="join-to-ss-order-modal__title">{Languages.getTranslation('select-ss-order-title', true)}</h2>
			<p
				className="join-to-ss-order-modal__text">{Languages.getTranslation("join-ss-order-warning", true)}</p>
			<div className="join-to-ss-order-modal__body">
				<div className="join-to-ss-order-modal__form-control">
					<label
						htmlFor="search-client-in-ss"
						className="join-to-ss-order-modal__label"
					>
						{Languages.getTranslation("enter-name-or-phone", true)}
					</label>
					<SearchClient id="search-client-in-ss" onSelect={selectClient}/>
				</div>
				<div className="join-to-ss-order-modal__form-control">
					<label
						className="join-to-ss-order-modal__label"
					>
						{Languages.getTranslation('select-ss-order', true)}
					</label>
					<CustomSelect
						options={orders.map((order) => ({value: order.number, label: order.number}))}
						handleChange={chooseOrder}
						placeholder=""
						value={selectedOrder}
						isLoading={isOrdersLoading}
						isDisabled={!client}
						noOptionsMessage={() => Languages.getTranslation("join-ss-order-no-orders", true)}
					/>
				</div>
			</div>
			<div className="join-to-ss-order-modal__btns">
				<DefaultBtn title={Languages.getTranslation('cancel', true)} handler={closeModal}/>
				<DefaultBtn title={Languages.getTranslation('join-details-to-ss', true)} handler={submit}
										disabled={!selectedOrder}/>
			</div>
		</>
	}
	
	return (
		<Modal isOpen={isOpen}>
			<div className="join-to-ss-order-modal modal__wrapper">
				{content}
			</div>
		</Modal>
	);
};

export default JoinToSlidingSystemOrderManager;
