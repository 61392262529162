import './style.scss';
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {Modal, ModalFooter} from "../index";

const SubmitModal = ({isOpen, setShowSubmitModal, setSelectedValue, selectType, submitModalText, setDeleteDetail, submitAction, isRemoveDetails = false, isRemoveProject = false}) => {

	const submit = () => {
		if(!isRemoveDetails){
			if (!isRemoveProject) {
				setSelectedValue(selectType)
			}
		}
		if(isRemoveDetails){
			submitAction()
		}
		if(isRemoveProject){
			submitAction()
		}
		setShowSubmitModal(false)
	}

	const close = ()=>{

		if (setDeleteDetail){
		setDeleteDetail(null)
		}

		setShowSubmitModal(false)
	}

	return <Modal isOpen={isOpen}>
		<div className='submit-modal modal__wrapper'>
			<div className="submit-modal__body">
				{submitModalText}
			</div>
			<ModalFooter className="submit-modal__actions">
				<DefaultBtn
					title={Languages.getTranslation("cancel", true)}
					handler={close}
				/>
				<DefaultBtn
					title={Languages.getTranslation("submit", true)}
					handler={submit}
				/>
			</ModalFooter>
		</div>
	</Modal>
}

export default SubmitModal