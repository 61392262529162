import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsOperations, modalsSelectors } from "redux/modals";

import CloseBtn from "components/UI/Buttons/CloseBtn";
import Languages from "translation/Languages";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";

import "../AddMaterialAndEdges/style.scss";
import { checkDetailsWithEdges, empty} from "../../../../helpers/helper";
import { projectSelectors } from "redux/project";
import DetailsForDelete from "../../DetailsForDelete";
import SubmitModal from "../SubmitModal";
import Loader from "../../Loader";
import {ModalFooter, ModalHeader} from "../index";

const RemoveEdges = () => {
    const dispatch = useDispatch();
    const deletedEdge = useSelector(modalsSelectors.getModalIsEdit);
    const construction = useSelector(projectSelectors.getConstruction);
    const submitModalText = Languages.getTranslation('delete-chosen-details', true)
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [isLoaded, setIsLoaded]= useState(false)
    const detailsWithEdges = checkDetailsWithEdges(construction.details, deletedEdge.index)
    const close = () => {

        dispatch(modalsOperations.switchStateForModal({
            show: false, type: null,
        }));
    };
    const deleteEdgesAndDetails = async () => {
          setIsLoaded(true);
          construction.deleteEdge(deletedEdge.index);
          detailsWithEdges.map(det => construction.deleteDetail(det.id).then(() => {
              close();
              window.location.reload()
              setIsLoaded(false);
          }));
    };
    const openConfirmModal = () => {
        setShowConfirmDeleteModal(true)
    }
        return (
            <>
            {!empty(detailsWithEdges)  && (
                <div className="addMaterial modal__wrapper">
									<ModalHeader className="addMaterial__header">
										<h3 className="addMaterial__header-title">
											{Languages.getTranslation("edges", true)}
										</h3>
										
										{!isLoaded && <CloseBtn handler={close} />}
									</ModalHeader>
                    <div className="deleteMaterial__text">
                        {Languages.getTranslation("delete-edge-text")}
                    </div>
                    {isLoaded ?  <Loader /> :  <DetailsForDelete details={detailsWithEdges} />}
									<ModalFooter className="addMaterial__btns">
										<div className="addMaterial__actions">
											<DefaultBtn
												title={Languages.getTranslation("cancel", true)}
												handler={close}
											/>
										</div>
										<DefaultBtn
											title={Languages.getTranslation("delete", true)}
											handler={openConfirmModal}
										/>
									</ModalFooter>
									<SubmitModal
										isOpen={showConfirmDeleteModal}
										submitModalText={submitModalText}
										setShowSubmitModal={setShowConfirmDeleteModal}
										isRemoveDetails={true}
										submitAction={deleteEdgesAndDetails}
									/>
								
								</div>
						)}
						
						
						</>
				)
};

export default RemoveEdges;
