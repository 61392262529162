import { CylinderGeometry, Mesh } from "three";
import Helpers from "./3D/Helpers";
import SizeArrow from "./3D/components/SizeArrow";
import Processing from "./3D/Processing";
import {empty} from "../helpers/helper";
import Languages from "../translation/Languages";
import * as template from './template.json'
import store from "redux/store";

export default class Hole extends Processing {
  _diam;
  _isActive = false;
  _mesh;
  _subType = 'hole';
  _y_axis;
  _x_axis;



  constructor({
    detail,
    dataForConstructor = {},
    side = "back",
    diam = 35,
    x = 100,
    y = 100,
    z = detail.w / 2,
    depth = 4,
    x_axis = "left",
    y_axis = "bottom",
    comment = "",
    hole = 'select',
    id,
    isErrorText = "",
    additionalInfo = '',
    count = 1,
    slide = 10,
    direction = 'horizontal',
    directionSide = 'to-right',
    isInit = false,
                isTemplate = null
  }) {
    super({x, y, z, depth, detail, x_axis, y_axis, side, comment, id, isErrorText, additionalInfo, isInit, isTemplate})
    this._directionSide = directionSide;
    this._direction = direction;
    this._slide = slide;
    this._count = count;
    this._diam = diam;
    this._hole = hole;
    this._r = null;
    this._dataForConstructor = dataForConstructor;
    this._isErrorText = isErrorText;
    if(!empty(dataForConstructor.x)) {
      this._x = dataForConstructor.x;
    }
    if(!empty(dataForConstructor.y)) {
      this._y = dataForConstructor.y
    }
    if(!empty(dataForConstructor.x_axis)) {
      this._x_axis = dataForConstructor.x_axis;
    } else {
      if(['left', 'right'].includes(this.side)) {
        this._x_axis = this.side;
      }
    }

    if(!empty(dataForConstructor.y_axis)) {
      this._y_axis = dataForConstructor.y_axis;
    } else {
      if(['top', 'bottom'].includes(this.side)) {
        this._y_axis = this.side;
      }
    }

    switch (side) {
      case 'front':
        this._z = this.detail.w - depth;
        break;
      case 'back':
        this._z = 0;
        break;
    }
    this.fixHoles(side)
    this.addParams();
    this.initRequiredFields();

  }

  initRequiredFields () {
    this.requiredFields = {
      depth: {
        min: 2.5,
        max: ['front', 'back'].includes(this.side) ? this.detail.w : 200
      },
      x: {
        max: ['left', 'right'].includes(this.side) ? this.detail.l : (this.diam < 10) ? this.detail.l - this.diam / 2 : this.detail.l,
        min: 0
      },
      y: {
        max: ['top', 'bottom'].includes(this.side) ? this.detail.h : (this.diam < 10) ? this.detail.h - this.diam / 2 : this.detail.h,
        min: 0
      }
    }
  }

  fixHoles(side) {
    switch (side) {
      case 'top':
      case 'bottom':
        this.y = 0
        break
      case 'left':
      case 'right':
        this.x = 0
        break
    }
  }

  addParams() {
    let f_value = ''
    // if(this.isInit) return;
    this.setFormField({name:'side', value: this.side, label: Languages.getTranslation('side', true), type: 'select', variables: [
      { key: "front", value: Languages.getTranslation('face', true) },
        { key: "back", value: Languages.getTranslation('back', true) },
        ...this.getSidesValues()
    ], visible: true})
    let temp;
    if(this.side === "front" || this.side === 'back'){
      temp = template.default.hole.face.map(item => {
        return {key: item.name, value: Languages.getTranslation(item.name, true)}})
    }else {
      temp = template.default.hole.torec.map(item => {
        return {key: item.name, value: Languages.getTranslation(item.name, true)}})
    }

    this.setFormField({name:'hole', value: this.hole,
                       label: Languages.getTranslation('type-hole', true),
                       type: 'select', variables: [
        { key: "select", value: Languages.getTranslation('Select-hole', true) }, ...temp], visible: true})
    if(this.side === "front" || this.side === 'back'){
      this.setFormField({name:'diam', value: this.diam, label: Languages.getTranslation('diam', true), type: 'select',    variables: [
          { key: "2", value: "2" },
          { key: "5", value: "5" },
          { key: "7", value: "7" },
          { key: "8", value: "8" },
          { key: "10", value: "10" },
          { key: "15", value: "15" },
          { key: "20", value: "20" },
          { key: "26", value: "26" },
          { key: "35", value: "35" },
        ], visible: true})
    }else{
      this.setFormField({name:'diam', value: this.diam, label: Languages.getTranslation('diam', true), type: 'select',    variables: [
          { key: "4.5", value: "4.5" },
          { key: "8", value: "8" },
        ], visible: true})
    }
    this.setFormField({name:'x_axis',label: `${Languages.getTranslation('side-to-exist', true)} х`, type: 'select', value: this.x_axis, visible: !['left', 'right'].includes(this.side), variables: [
        { key: "left", value: Languages.getTranslation('left2', true) },
        { key: "right", value: Languages.getTranslation('right2', true) },
      ]})
    this.setFormField({name:'y_axis', label: `${Languages.getTranslation('side-to-exist', true)} у`, type: 'select', value: this.y_axis, variables: [
        { key: "top", value: Languages.getTranslation('top2', true) },
        { key: "bottom", value: Languages.getTranslation('bottom2', true) },
      ], visible: !['top', 'bottom'].includes(this.side)})
    this.setFormField({name: 'z', value: this.z, type: 'number', label: 'z', visible: !['front', 'back'].includes(this.side), additionalParam : [
        {name: 'center_z', label: `${Languages.getTranslation('center-on-axis', true)} Z`, callback: () => {
            this.setCenterZ();
          }}
      ]})
    this.setFormField({name: 'depth',value: this.depth, type: 'number', label: Languages.getTranslation('depth', true), additionalParam : [
        {name: 'center_z', label: Languages.getTranslation('fullest-extent', true), callback: () => {
            this.setFullDepth();
          }}
      ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_y') ? this._dataForConstructor['f_y'] : '';
    this.setFormField({name: 'y', value: this.y, type: 'number', label: 'y (py)', visible: !['top', 'bottom'].includes(this.side), additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center_y', label: `${Languages.getTranslation('center-on-axis', true)} Y`, callback: () => {
            this.setCenterY();
          }}
      ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_x') ? this._dataForConstructor['f_x'] : '';
    this.setFormField({name: 'x', value: this.x, type: 'number', label: 'x (px)', visible: !['left', 'right'].includes(this.side), additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center_x', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {
            this.setCenterX();
          }}
      ]})
    this.setFormField({name: 'count', value: this.count, type: 'number', label: Languages.getTranslation("amount", true)})
    this.setFormField({name: 'slide', value: this.slide, type: 'number', label: Languages.getTranslation("slide", true)})
    if(this.side === "front" || this.side === 'back') {
      this.setFormField({ name: 'direction', value: this.direction, label: Languages.getTranslation('shift-direction', true), type: 'select',
        variables: [
          {key: "horizontal", value: Languages.getTranslation('horizontal', true)},
          {key: "vertical", value: Languages.getTranslation('vertical', true)},
          {key: "diagonal", value: Languages.getTranslation('diagonal', true)},
        ], visible: true })
      if(this.direction === "horizontal"){
        this.setFormField({name:'directionSide', value: this.directionSide, /*label: Languages.getTranslation('shift-direction', true),*/ type: 'select', variables: [
            { key: "to-right", value: Languages.getTranslation('to-right', true) },
            { key: "to-left", value: Languages.getTranslation('to-left', true) },
          ], visible: true})
      }
      if(this.direction === "vertical"){
        this.setFormField({name:'directionSide', value: this.directionSide, type: 'select', variables: [
            { key: "to-top", value: Languages.getTranslation('to-top', true) },
            { key: "to-bottom", value: Languages.getTranslation('to-bottom', true) },
          ], visible: true})
      }
      if(this.direction === "diagonal"){
        this.setFormField({name:'directionSide', value: this.directionSide, type: 'select', variables: [
            { key: "left-up", value: Languages.getTranslation('left-up', true) },
            { key: "left-down", value: Languages.getTranslation('left-down', true) },
            { key: "right-up", value: Languages.getTranslation('right-up', true) },
            { key: "right-down", value: Languages.getTranslation('right-down', true) },
          ], visible: true})
      }
    }else {
      if(this.side === 'top' || this.side === 'bottom'){
        this.setFormField({ name: 'direction', value: this.direction, label: Languages.getTranslation('shift-direction', true), type: 'select',
          variables: [
            {key: "horizontal", value: Languages.getTranslation('horizontal', true)},
          ], visible: true })
        this.setFormField({name:'directionSide', type: 'select', variables: [
            { key: "to-left", value: Languages.getTranslation('to-left', true) },
            { key: "to-right", value: Languages.getTranslation('to-right', true) },
          ], visible: true})
      }else{
        this.setFormField({ name: 'direction', value: this.direction, label: Languages.getTranslation('shift-direction', true), type: 'select',
          variables: [
            {key: "vertical", value: Languages.getTranslation('vertical', true)},
          ], visible: true })
        this.setFormField({name:'directionSide', type: 'select', variables: [
            { key: "to-top", value: Languages.getTranslation('to-top', true) },
            { key: "to-bottom", value: Languages.getTranslation('to-bottom', true) },
          ], visible: true})
      }
    }
    this.getSidesForCompactPlite()

  }

  get x(){
    return this._x
  }

  set x(x){
    if(typeof x === 'number'){
      this._x = x;
      this.setFormField({name: 'x', value: this.x, type: 'number', label: 'x (px)', visible: !['left', 'right'].includes(this.side), additionalParam : [
          {name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
          {name: 'center_x', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {
              this.setCenterX();
            }}
        ]})
      this.updateDataForConstructor({name: 'f_x', value: ''})
      this.updateDataForConstructor({name: 'x', value: this.x})
    }else {
      if(!empty(x)){
        if (!empty(this._dataForConstructor['f_y'])
            && this._dataForConstructor['f_y'].includes('px')
            && x.includes('py')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(x))) {
          const calculation = Number(Number(this.formulaCalculation(x)).toFixed(1));
          if (calculation >= 0) {
            this._x = calculation;
            this.setFormField({
              name: 'x',
              value: this.x,
              type: 'number',
              label: 'x (px)',
              visible: !['left', 'right'].includes(this.side),
              additionalParam: [
                {name: 'formula', label: Languages.getTranslation('formula', true), value: x},
                {
                  name: 'center_x', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {
                    this.setCenterX();
                  }
                }
              ]
            })
            this.updateDataForConstructor({name: 'f_x', value: x})
          }else{
            this.showToastError('cannot-be-negative')
          }
        }
      }else{
        this.updateDataForConstructor({name: 'f_x', value: ''})
      }
    }
    if(!empty(this._dataForConstructor['f_y']) && this._dataForConstructor['f_y'].includes('px')){
      this.y = this._dataForConstructor['f_y']
    }
    this.initRequiredFields()
    this.renderDetail()
    // this.buildDetail(this)
    //     .then(() => this.renderDetail())
  }

  get y(){
    return this._y;
  }

  set y(y){
    if(typeof y === 'number'){
      this._y = y;
      this.setFormField({name: 'y', value: this.y, type: 'number', label: 'y (py)', visible: !['top', 'bottom'].includes(this.side), additionalParam : [
          {name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
          {name: 'center_y', label: `${Languages.getTranslation('center-on-axis', true)} Y`, callback: () => {
              this.setCenterY();
            }}
        ]})
      this.updateDataForConstructor({name: 'f_y', value: ''})
      this.updateDataForConstructor({name: 'y', value: this.y})
    }else{
      if(!empty(y)) {
        if (!empty(this._dataForConstructor['f_x'])
            && this._dataForConstructor['f_x'].includes('py')
            && y.includes('px')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(y))) {
          const calculation = Number(Number(this.formulaCalculation(y)).toFixed(1));
          if (calculation >= 0) {
            this._y = calculation;
            this.setFormField({
              name: 'y',
              value: this.y,
              type: 'number',
              label: 'y (py)',
              visible: !['top', 'bottom'].includes(this.side),
              additionalParam: [
                {name: 'formula', label: Languages.getTranslation('formula', true), value: y},
                {
                  name: 'center_y', label: `${Languages.getTranslation('center-on-axis', true)} Y`, callback: () => {
                    this.setCenterY();
                  }
                }
              ]
            })
            this.updateDataForConstructor({name: 'f_y', value: y})
          }else{
            this.showToastError('cannot-be-negative')
          }
        }
      }else{
        this.updateDataForConstructor({name: 'f_y', value: ''})
      }
    }
    if(!empty(this._dataForConstructor['f_x']) && this._dataForConstructor['f_x'].includes('py')){
      this.x = this._dataForConstructor['f_x']
    }
    this.initRequiredFields()
    this.renderDetail()
  }

  get paramsSorting() {
    const sortingArr = ['side', 'hole', 'x', 'y', 'diam', 'z', 'x_axis', 'depth', 'y_axis', 'comment', 'none', 'count', 'slide', 'direction', 'directionSide']

    return this.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  get paramsSortingForEdit() {
    const sortingArr = ['side', 'hole', 'x', 'y', 'diam', 'z', 'x_axis', 'depth', 'y_axis', 'comment']

    return this.formFields.filter(el => sortingArr.includes(el.name)).sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  setCenterY() {
    this.y = this.detail.h / 2
  }

  setCenterX() {
    this.x = this.detail.l / 2
  }

  setCenterZ() {
    this.z = this.detail.w / 2;
    this.setFormField({name: 'z', value: this.z});
    this.renderDetail()
    // this.buildDetail()
    //   .then(() => this.renderDetail());
  }

  directionSideCheak(dir){
    if(this.direction === "horizontal"){
      this.setFormField({name:'directionSide', value: dir, type: 'select', variables: [
          { key: "to-right", value: Languages.getTranslation('to-right', true) },
          { key: "to-left", value: Languages.getTranslation('to-left', true) },
        ], visible: true})
    }
    if(this.direction === "vertical"){
      this.setFormField({name:'directionSide', value: dir, type: 'select', variables: [
          { key: "to-top", value: Languages.getTranslation('to-top', true) },
          { key: "to-bottom", value: Languages.getTranslation('to-bottom', true) },
        ], visible: true})
    }
    if(this.direction === "diagonal"){
      this.setFormField({name:'directionSide', value: dir, /*label: Languages.getTranslation('shift-direction', true),*/ type: 'select', variables: [
          { key: "left-up", value: Languages.getTranslation('left-up', true) },
          { key: "left-down", value: Languages.getTranslation('left-down', true) },
          { key: "right-up", value: Languages.getTranslation('right-up', true) },
          { key: "right-down", value: Languages.getTranslation('right-down', true) },
        ], visible: true})
    }
  }

  set directionSide(directionSide){
    this._directionSide = directionSide
    this.directionSideCheak(directionSide)
  }

  get directionSide(){
    return this._directionSide
  }

  set direction(direction){
    this._direction = direction
    if(direction === "horizontal"){this._directionSide = "to-right"}
    if(direction === "vertical"){this._directionSide = "to-top"}
    if(direction === "diagonal"){this._directionSide = "left-up"}
    this.setFormField({name:'direction', value: direction, label: Languages.getTranslation('shift-direction', true), type: 'select', variables: [
        { key: "horizontal", value: Languages.getTranslation('horizontal', true) },
        { key: "vertical", value: Languages.getTranslation('vertical', true) },
        { key: "diagonal", value: Languages.getTranslation('diagonal', true) },
      ], visible: true})
    this.directionSideCheak(direction)
  }

  get direction(){
    return this._direction
  }

  set slide(slide){
    this._slide = Number(slide);
    this.setFormField({name: 'slide', value: slide, type: 'number', label: Languages.getTranslation("slide", true)})
  }

  get slide(){
    return this._slide
  }

  get count(){
    return this._count
  }

  set count(count){
    this._count = Number(count);
    this.setFormField({name: 'count', value: count, type: 'number', label: Languages.getTranslation("amount", true)})
  }

  setFullDepth() {
    this.initRequiredFields()
    this.depth = this.detail.w
  }

  addNCountHoles(){
    const { x, y, slide, diam, x_axis, y_axis } = this
    for(let i = 1; i <= this.count - 1; i++){
      switch(this.direction){
        case "horizontal":
          switch(this.directionSide){
            case "to-right":
              this.clone({
                x: x_axis === 'right' ? x - slide * i : x + slide * i,
                y: y,
                ver: false,
                hor: false,
              })
              break
            case "to-left":
              this.clone({
                x: x_axis === 'right' ? x + slide * i : x - slide * i,
                y: y,
                ver: false,
                hor: false,
              })
              break
          }
          break
        case "vertical":
          switch(this.directionSide){
            case 'to-top':
              this.clone({
                x: x,
                y: y_axis === 'top' ? y - slide * i : y + slide * i,
                ver: false,
                hor: false,
              })
              break
            case 'to-bottom':
              this.clone({
                x: x,
                y: y_axis === 'top' ? y + slide * i : y - slide * i,
                ver: false,
                hor: false,
              })
              break
          }
          break
        case "diagonal":
          let deg = 45
          switch(this.directionSide){
            case 'left-up':
              deg = -45
              break
            case 'left-down':
              deg = -135;
              break
            case 'right-down':
              deg = 135
              break
          }
          this.clone({
            x: x_axis === 'right' ? x - slide * Math.sin(Helpers.degToPi(deg)) * i : x + slide * Math.sin(Helpers.degToPi(deg)) * i,
            y: y_axis === 'top' ? y - slide * Math.cos(Helpers.degToPi(deg)) * i : y + slide * Math.cos(Helpers.degToPi(deg)) * i,
            ver: false,
            hor: false,
          })
          break
      }
    }
    this.setFormField({name: 'count', value: 1, type: 'number', label: Languages.getTranslation("amount", true)})
    this.setFormField({name: 'slide', value: 10, type: 'number', label: Languages.getTranslation("slide", true)})
    this.setFormField({ name: 'direction', value: 'horizontal', label: Languages.getTranslation('shift-direction', true), type: 'select',
      variables: [
        {key: "horizontal", value: Languages.getTranslation('horizontal', true)},
        {key: "vertical", value: Languages.getTranslation('vertical', true)},
        {key: "diagonal", value: Languages.getTranslation('diagonal', true)},
      ], visible: true })
    this.setFormField({name:'directionSide', value: 'to-right', type: 'select', variables: [
        { key: "to-right", value: Languages.getTranslation('to-right', true) },
        { key: "to-left", value: Languages.getTranslation('to-left', true) },
      ], visible: true})
    this.renderDetail()
  }

  get mesh() {
    this.createMesh();
    return this._mesh;
  }

  set mesh(mesh) {
    this._mesh = mesh;
  }

  get depth() {
    return this._depth;
  }

  set depth(depth) {
    this.initRequiredFields()
    this._depth = depth;
    this.setFormField({name: 'depth', value: depth, type: 'number', label: Languages.getTranslation("depth", true)})
  }

  createMesh() {
    const material = this.isActive || this.isSelected ? Helpers.getMaterial3D(this.getMeshColor()) : Helpers.getMaterial3DTransparent()
    const mesh = new Mesh (
      new CylinderGeometry(this.diam / 2, this.diam / 2, this.depth, 36),
      material
    );
    mesh.add(Helpers.addContourLines(mesh.geometry));

    this.calcMesh(mesh);
    mesh.userData = {
      pId: this.id,
      meshType: "hole",
      type: "hole",
      proc: this,
    }
    this.mesh = mesh;
  }

  calcMesh(mesh) {
    this.fixMultiplicity();
    const calcHorizontalPos = () => {
      switch (this.x_axis) {
        case "left": // left edge
          mesh.position.x = -this.detail.l / 2 + this.x;
          break;
        case "right": // right edge
          mesh.position.x = this.detail.l / 2 - this.x;
          break;
      }
    };
    const calcVerticalPos = () => {
      switch (this.y_axis) {
        case "bottom":
          mesh.position.y = -this.detail.h / 2 + this.y; // bottom edge
          break;
        case "top":
          mesh.position.y = this.detail.h / 2 - this.y; // top edge
          break;
      }
    };
    mesh.rotation.x = 0;
    mesh.rotation.z = 0;
    switch (this.side) {
      case "front":
        mesh.rotation.x = Math.PI / 2;
        mesh.position.z = this.detail.w / 2 - this.depth / 2;
        calcHorizontalPos();
        calcVerticalPos();
        break;
      case "left":
        mesh.rotation.z = Math.PI / 2;
        mesh.position.x = -this.detail.l / 2 + this.depth / 2;
        mesh.position.z = -this.detail.w / 2 + this.z;
        calcVerticalPos();
        break;
      case "top":
        mesh.position.y = this.detail.h / 2 - this.depth / 2;
        mesh.position.z = -this.detail.w / 2 + this.z;
        calcHorizontalPos();
        break;
      case "right":
        mesh.rotation.z = Math.PI / 2;
        mesh.position.x = this.detail.l / 2 - this.depth / 2;
        mesh.position.z = -this.detail.w / 2 + this.z;
        calcVerticalPos();
        break;
      case "bottom":
        mesh.position.y = -this.detail.h / 2 + this.depth / 2;
        mesh.position.z = -this.detail.w / 2 + this.z;
        calcHorizontalPos();
        break;
      case "back":
        mesh.rotation.x = Math.PI / 2;
        mesh.position.z = -this.detail.w / 2 + this.depth / 2 + this.z;
        calcHorizontalPos();
        calcVerticalPos();
        break;
    }
  }

  // async remove() {
  //   const index = this.detail.holes.findIndex(el => el.id === this.id);
  //   if(index !== -1) {
  //     this.detail.error.deleteErrorProcessing(this.detail.holes[index].isErrorText)
  //     this.detail.holes.splice(index, 1);
  //   }
  //
  //
  //   return this.updateDb(true)
  //     .then(() => this.buildDetail(this))
  //     .then(() => this.renderDetail())
  // }

  addSizesArrows() {
    if(!this.isActive) return false;

    let y = this.side === 'top' ? this.detail.h : this.side === 'bottom' ? 0 : this.y;
    let x = this.side === 'left' ? 0 : this.side === 'right' ? this.detail.l : this.x;
    const pos = this.x_axis + '_' + this.y_axis;
    switch (this.side) {
      case 'top':
        y = this.depth;
        break
      case 'bottom':
        y = this.depth;
        break
      case 'left':
        x = this.depth
        break
      case 'right':
        x = this.depth
        break
    }
    return SizeArrow.getElementSizeArrow(
      x,
      y,
      null,
      pos,
      this.detail,
      false,
      this.side === 'back'
    )
  }

  get diam() {
    return this._diam;
  }

  set diam(diam) {

    if(!this.checkRequiredField('diam', diam)) {
      this._diam = Number(diam);
      this.setFormField({name: 'diam', value: this.diam});
      this.initRequiredFields()
      this.renderDetail()
    } else {
      this.showErrors()
    }
    // this.fixMultiplicity();
    this.initRequiredFields()
    this.renderDetail()
    // this.buildDetail()
    //   .then(() => this.renderDetail())
  }

  get side() {
    return this._side;
  }
  set side(side) {
    this.defaultValue(this._side, side);
    this._side = side;
    this.setFormField({name: 'side', value: this.side, label: Languages.getTranslation('side', true)});
      switch (side) {
        case 'front':
        case 'back':
          // this.fixMultiplicity()
          this.setFormField({name: 'z', visible: false, value: this.z})
          this.setFormField({name: 'x', value: 100, visible: true})
          this.setFormField({name: 'y', visible: true})
          this.setFormField({name: 'x_axis', visible: true})
          this.setFormField({name: 'y_axis', visible: true})
          this.setFormField({name: 'hole', variables:
                    [{ key: "select", value: Languages.getTranslation('Select-hole', true) },
                      ...template.default.hole.face.map(item => {
                      return {key: item.name, value: Languages.getTranslation(item.name, true)}})]})
          this.z = 0
          this.setFormField({name:'diam', value: this.diam, label: Languages.getTranslation('diam', true), type: 'select',    variables: [
              { key: "2", value: "2" },
              { key: "5", value: "5" },
              { key: "7", value: "7" },
              { key: "8", value: "8" },
              { key: "10", value: "10" },
              { key: "15", value: "15" },
              { key: "20", value: "20" },
              { key: "26", value: "26" },
              { key: "35", value: "35" },
            ], visible: true})
          break;
        case 'left':
        case 'right':
          this.setFormField({name: 'x', visible: false, value: 0})
          this.setFormField({name: 'x_axis', visible: false})
          this.setFormField({name: 'y_axis', visible: true})
          this.setFormField({name: 'z', visible: true})
          this.setFormField({name: 'y', visible: true})
          this.setFormField({name: 'hole', variables:
                [{ key: "select", value: Languages.getTranslation('Select-hole', true) },
                  ...template.default.hole.torec.map(item => {
                  return {key: item.name, value: Languages.getTranslation(item.name, true)}})]})
          this.x_axis = this.side
          this.x = 0
          this.y = 100
          this.setFormField({name:'diam', value: this.diam, label: Languages.getTranslation('diam', true), type: 'select',    variables: [
              { key: "4.5", value: "4.5" },
              { key: "8", value: "8" },
            ], visible: true})
          this.direction = "vertical";
          this.setFormField({ name: 'direction', value: this.direction, label: Languages.getTranslation('shift-direction', true), type: 'select',
            variables: [
              {key: "vertical", value: Languages.getTranslation('vertical', true)},
            ], visible: true })
          this.directionSide = 'to-top';
          this.setFormField({name:'directionSide', type: 'select', variables: [
              { key: "to-top", value: Languages.getTranslation('to-top', true) },
              { key: "to-bottom", value: Languages.getTranslation('to-bottom', true) },
            ], visible: true})
          break
        case 'top':
        case 'bottom':
          this.setFormField({name: 'y_axis', visible: false})
          this.setFormField({name: 'x_axis', visible: true})
          this.setFormField({name: 'z', visible: true})
          this.setFormField({name: 'x', visible: true})
          this.setFormField({name: 'y', visible: false, value: 0});
          const holeValues = [
              { key: "select", value: Languages.getTranslation('Select-hole', true) },
              ...template.default.hole.torec.map(item => { return { key: item.name, value: Languages.getTranslation(item.name, true)}})
          ]
          this.setFormField({name: 'hole', value: "select", variables: holeValues
                })
          this.y_axis = this.side
          this.y = 0
          this.x = 100
          this.setFormField({name:'diam', value: this.diam, label: Languages.getTranslation('diam'), type: 'select',    variables: [
              { key: "4.5", value: "4.5" },
              { key: "8", value: "8" },
            ], visible: true})
          this.direction = "horizontal"
          this.setFormField({ name: 'direction', value: this.direction, label: Languages.getTranslation('shift-direction', true), type: 'select',
            variables: [
              {key: "horizontal", value: Languages.getTranslation('horizontal', true)},
            ], visible: true })
          this.directionSide = 'to-left'
          this.setFormField({name:'directionSide', value: this.directionSide, type: 'select', variables: [
              { key: "to-left", value: Languages.getTranslation('to-left', true) },
              { key: "to-right", value: Languages.getTranslation('to-right', true) },
            ], visible: true})
          break
      }
    this.initRequiredFields()
  }

  get hole() {
    return this._hole;
  }

  defaultValue(prevSide, side) {
    if ((["left", "top", "right", "bottom"].includes(prevSide) && ["front", "back"].includes(side))) {
      this.depth = 4
      this.setFormField({name: 'depth', value: 4, disabled: false})
      if (side === "front" || side === 'back') {
        this.x = 100
      } else {
        this.z = 9
        this.setFormField({name: 'z', value: 9, disabled: false})
      }
      this.y = 100
      this.x = 100
      this.diam = 35
      this.setFormField({name: 'diam', value: 2, disabled: false})
    }
    if (["left", "top", "right", "bottom"].includes(side) && ["front", "back"].includes(prevSide)) {
      this.depth = 4
      this.setFormField({name: 'depth', value: 4, disabled: false})
      if (side === "front" || side === 'back') {
        this.x = 100
      } else {
        this.z = 9
        this.setFormField({name: 'z', value: 9, disabled: false})
      }
      this.y = 100
      this.x = 100
      this.diam = 4.5
      this.setFormField({name: 'diam', value: 4.5, disabled: false})
    }
  }

  set hole(hole){
    this._hole = hole;
    this.setFormField({name: 'hole', value: this.hole, label: Languages.getTranslation('type-hole', true)});
    if(hole === 'select'){
      this.depth = 4
      this.setFormField({name: 'depth', value: 4, disabled: false})
      if(this.side === "front" || this.side === 'back') {
        this.x = 100
        // this.setFormField({name: 'x', value: 100, disabled: false})
      } else {
        this.z = 9
        this.setFormField({name: 'z', value: 9, disabled: false})
      }
      this.y = 100
      this.x = 100
      this.setFormField({name: 'hole', value: Languages.getTranslation('Select-hole', true), disabled: false})
      // this.setFormField({name: 'y', value: 100, disabled: false})
      this.diam = 2
      this.setFormField({name: 'diam', value: 2, disabled: false})
    }else{
      const currentHole = template.default.hole[
      this.side === "front" || this.side === 'back' ? 'face' : 'torec'].find(item => item.name === hole)
      this.depth = currentHole.depth
      this.setFormField({name: 'depth', value: currentHole.depth, disabled: true})
      this.diam = currentHole.diam
      this.setFormField({name: 'diam', value: currentHole.diam, disabled: true})
      if(this.side === "front" || this.side === 'back') {
        this.x = currentHole.x /*+ currentHole.diam / 2*/
        this.setFormField({name: 'x', value: currentHole.x})
        this.y = currentHole.y /*+ currentHole.diam / 2*/
        this.setFormField({name: 'y', value: currentHole.y})
      }else {
        this.setCenterZ();
        this.setFormField({name: 'z', disabled: true})
        if(this.side === 'top' || this.side === 'bottom'){
          this.x = 100
        }
      }
    }
  }

  get realData() {
    return this.data;
  }

  get data() {
    return {
      x: this.x,
      y: this.y,
      z: this.z,
      side: this.side,
      depth: this.depth,
      diam: this.diam,
      edge: null,
      x_axis: this.x_axis,
      y_axis: this.y_axis,
      comment: this.comment,
      id: this.id,
      isTemplate: this.isTemplate
    };
  }

  get xReal() {
    if (["front", "back"].includes(this.side)) {
      return this.x_axis === "left" ? this.x : this.detail.l - this.x;
    } else {
      const x = this.x_axis === "left" ? this.x : this.detail.l - this.x;
      const y = this.y_axis === "left" ? this.y : this.detail.h - this.y;
      return ["top", "bottom"].includes(this.side) ? x : y;
    }
  }

  get yReal() {
    if (["front", "back"].includes(this.side)) {
      return this.y_axis === "bottom" ? this.y : this.detail.h - this.y;
    } else {
      return 0;
    }
  }

  get dataForValidate() {
    return this.data;
  }

  validate() {
    const { l, h } = this.detail
    const { x, y, x_axis, y_axis, diam, slide } = this
    const newCount = this.count - 1, message = `${this.count} ${Languages.getTranslation('hole')} ${Languages.getTranslation('beyond-detail')}`
    this.checkRequiredFields();
    if(this.count > 1) {
      switch (this.direction) {
        case 'horizontal':
          switch (this.directionSide) {
            case 'to-right':
              if (x_axis === 'left') {
                if (x + (diam / 2 + (slide * newCount)) >= l) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              if (x_axis === 'right') {
                if (x - (diam / 2 + (slide * newCount)) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              break
            case 'to-left':
              if (x_axis === 'left') {
                if (x - (diam / 2 + (slide * newCount)) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              if (x_axis === 'right') {
                if (x + (diam / 2 + (slide * newCount)) >= l) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              break
          }
          break

        case 'vertical':
          switch (this.directionSide) {
            case 'to-top':
              if (y_axis === 'bottom') {
                if (y + (diam + (slide * newCount)) >= h) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              if (y_axis === 'top') {
                if (y - (diam + (slide * newCount)) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              break
            case 'to-bottom':
              if (y_axis === 'bottom') {
                if (y - (diam + (slide * newCount)) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              if (y_axis === 'top') {
                if (y + (diam + (slide * newCount)) >= h) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              break
          }
          break

        case "diagonal":
          switch (this.directionSide) {
            case 'left-up':
              if (y_axis === 'bottom') {
                if (x - slide * newCount <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
                if (y + slide * (newCount + 1) >= h) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              if (y_axis === 'top') {
                if (y - slide * (newCount + 1) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
                if (x - slide * (newCount + 1) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              break
            case 'left-down':
              if (y_axis === 'bottom') {
                if (y - slide * (newCount + 1) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
                if (x - slide * (newCount + 1) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              if (y_axis === 'top') {
                if (y + slide * (newCount + 1) >= h) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
                if (x + slide * (newCount + 1) >= l) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              break
            case 'right-up':
              if (y_axis === 'bottom') {
                if (y + slide * (newCount + 1) > h) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
                if (x + slide * (newCount + 1) > l) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              if (y_axis === 'top') {
                if (y - slide * (newCount + 1) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
                if (x + slide * (newCount + 1) >= l) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              break
            case 'right-down':
              if (y_axis === 'bottom') {
                if (y - slide * (newCount + 1) <= 0) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
                if (x + slide * (newCount + 1) >= l) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              if (y_axis === 'top') {
                if (y + slide * (newCount + 1) >= h) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
                if (x + slide * (newCount + 1) >= l) {
                  this.detail.error.setError(message, 'error', true);
                  return Promise.reject('beyond-detail')
                }
              }
              break
          }
          break
      }
    }
    // if(this.x === 0 && !["right", "left"].includes(this.side)) {
    //   this.detail.error.setError(`${Languages.getTranslation('change-the-X-value', true)}`, 'error', true);
    //   return Promise.reject('change-the-X-value')
    // }
    // if(this.y === 0 && !["top", "bottom"].includes(this.side)) {
    //   this.detail.error.setError(`${Languages.getTranslation('change-the-Y-value', true)}`, 'error', true);
    //   return Promise.reject('change-the-Y-value')
    // }
    // if(this.depth < 2.5) {
    //   this.detail.error.setError(`${Languages.getTranslation('change-depth', true)}`, 'error', true);
    //   return Promise.reject('change-depth')
    // }
    this.addNCountHoles()
    return Promise.resolve()
  }

  calcX() {
    if(this.x_axis === 'left') {
      return this.x;
    }
    return this.detail.l - this.x
  }

  calcY() {
    if(this.y_axis === 'top') {
      return this.detail.h - this.y;
    }
    return this.y;
  }

  fixMultiplicity() {
    if(empty(this.detail.multiplicity) || empty(this.detail.multiplicityClass)) return;
    // const data = {_z: this.z, _depth: this.depth, _multiplicity: 0};
    this.detail.multiplicityClass.parts.forEach(part => {
      if(Helpers.rectanglesAreNestedLB(
        Helpers.getHoleRectangle(this.x - this.detail.l / 2, this.y - this.detail.h / 2, this.diam / 2), part
      )) {
        // this.z = this.detail.multiplicityClass.w;
        // this.depth = this.depth > this.detail.multiplicityClass.w ? this.detail.multiplicityClass.w : this.depth;
        this._z = this.detail.multiplicityClass.w;
        this._depth = this.depth > this.detail.w - this.detail.multiplicityClass.w ? this.detail.w - this.detail.multiplicityClass.w :  this.depth;
        this.multiplicity = this.detail.multiplicityClass.w
      }
    })
    // return data
  }



  mirror(axis, data, detailH, detailL) {
    if(axis === 'ver') {
      if (data.side === 'left') {
        data.side = 'right'
        return
      }
      if (data.side === 'right') {
        data.side = 'left'
        return
      }
      data.x_axis = this.x_axis === 'left' ? 'right' : 'left'
    }

    if(axis === 'hor') {
      if (data.side === 'top') {
        data.side = 'bottom'
        return
      }
      if (data.side === 'bottom') {
        data.side = 'top'
        return
      }
      data.y_axis = this.y_axis === 'top' ? 'bottom' : 'top'
    }
  }

  mirrorWithReverse(axis) {
    const data = {
      x: this.x,
      y: this.y,
      x_axis: this.x_axis,
      y_axis: this.y_axis
    }
    if(axis.ver) {
      switch (this.side) {
        case 'left':
          this.side = 'right';
          this.y = data.y;
          this.y_axis = data.y_axis
          break;
        case 'right':
          this.side = 'left';
          this.y = data.y;
          this.y_axis = data.y_axis
          break;
        default:
          const _axis = this.x_axis === 'left' ? 'right' : 'left';
          this.x_axis = _axis;
          this.updateDataForConstructor({name: 'x_axis', value: _axis})
          break;
      }
    }
    if(axis.hor) {
      switch (this.side) {
        case 'top':
          this.side = 'bottom';
          this.x = data.x;
          this.x_axis = data.x_axis
          break;
        case 'bottom':
          this.side = 'top';
          this.x = data.x;
          this.x_axis = data.x_axis
          break;
        default:
          const _axis = this.y_axis === 'top' ? 'bottom' : 'top';
          this.y_axis = _axis;
          this.updateDataForConstructor({name: 'y_axis', value: _axis})
          break;
      }
    }
  }

  rotateDetail(direction) {
    let x, y, x_axis, y_axis, diam = this.diam;

    switch (this.side) {
      case 'front':
      case 'back':
        [x , y] = [this.y, this.x];
        break;
      case 'left':
      case 'right':
        x = this.y;
        y = this.detail.h;
        break;
      case 'top':
      case 'bottom':
        y = this.x
        x = this.detail.l;
    }

    if(direction) {
      y_axis = (this.x_axis === 'right') ? 'bottom' : 'top';
      x_axis = (this.y_axis === 'bottom') ? 'left' : 'right';
    } else {
      y_axis = (this.x_axis === 'right') ? 'top' : 'bottom';
      x_axis = (this.y_axis === 'bottom') ? 'right' : 'left';
    }


    this.side = this.getNextSideForRotate(direction);
    this.initRequiredFields()
    this.diam = diam;
    this.y = y;
    this.x = x
    this.x_axis = x_axis;
    this.y_axis = y_axis;
    this.updateDataForConstructor({name: 'x', value: this.x})
    this.updateDataForConstructor({name: 'y', value: this.y})
    this.updateDataForConstructor({name: 'x_axis', value: this.x_axis})
    this.updateDataForConstructor({name: 'y_axis', value: this.y_axis})
    return this.updateDb();
  }

  updatePosition(data) {
    for(const [key, value] of Object.entries(data)) {
      this['_' + key] = value;
      this.updateDataForConstructor({name: key, value})
    }
    return this.updateDb()
  }
}
