import {ExtrudeGeometry, AxesHelper, Group, Mesh, MeshBasicMaterial, Shape, Vector3, Euler} from "three";

import store from "redux/store";
import {empty} from "../../../helpers/helper";
import ConstructorSceneModel from './SceneModel'
import Helper from "./Helper";
import API from "../../../api/API";

export default class Scene {
	objects = [];
	_sceneModel;



	constructor(dom) {
		this.sceneModel = new ConstructorSceneModel(dom);
		dom.addEventListener('selectObject', e => {
			this.selectObjects(e.detail.selectedObject)
		})
		dom.addEventListener('editObject', e => {
			this.transformDetail(e.detail)
		})
		dom.addEventListener('transformObject', e => {
			this.storeTransform(e.detail)
		})
		dom.addEventListener('removeSelectedObjects', e => {
			this.unselectObjects();
			store.dispatch({
				type: 'DROP_CONNECT_VALUES',
				payload: null
			})
		})

		this.init()
		window.addEventListener('changView', e => {
			this.setCameraView(e.detail);
		})
	}

	createGLTF() {
		const project_id = store.getState().project?.project?.construction?._projectData?.id || null;
		if(project_id) {
			this.sceneModel.createGLTF()
				.then(data => API.Constructor.setPreview(project_id, data))
		}
		return Promise.reject('Project_id id empty')
	}

	unselectObjects() {
		const { selectedObjects } = store.getState().constructor3d;
		selectedObjects.forEach(obj => {
			store.dispatch({
				type: 'REMOVE_SELECTED_OBJECT',
				payload: obj.object.uuid
			})
			const detail = this.getDetailClass(obj)
			if(!empty(detail)) {
				detail.selectObject(obj.object.uuid);
			}
		})
		this.render()
	}

	transformDetail(detail) {
		if(!this.sceneModel.transformed) {
			const detailClass = this.getDetailClass(detail.object)
			if(detailClass?.locked) {
				detailClass.addError('item locked')
			} else {
				this.sceneModel.initTransform(detail.object)
			}
		} else {
			this.sceneModel.removeTransform()
		}
	}

	storeTransform(detailMesh) {
		const detail = this.getDetailClass(detailMesh);
		if(!empty(detail)) {
			detail.updatePosition();
		}
	}

	getDetails() {
		return store.getState().constructor3d.cDetails
	}

	getGroup(name) {
		return this.sceneModel.scene.getObjectByName(name);
	}

	addDetails() {
		this.getDetails().forEach(detail => {
			if(!empty(detail?.group)) {
				const group = this.getGroup(detail?.group)
				if(empty(group)) {
					const _group = new Group();
					_group.name = detail.group;
					_group.add(detail.create3DDetail())
				} else {
					group.add(detail.create3DDetail())
				}
			} else {
        this.sceneModel.scene.add(detail.create3DDetail());
			}
	 	})
	}

	addDetail(detailCId) {
		return new Promise(resolve => {
			const detail = this.getDetails().find(el => el.detailCId === detailCId);
			const detailMesh = detail.create3DDetail();
			this.sceneModel.scene.add(detailMesh);
			detail.addRotations(new Euler().copy(detailMesh.rotation))
			resolve()
		})
	}

	getDetailClass(object) {
		return this.getDetails().find(_detail => _detail.detailCId === Helper.getDetailMesh(object)?.userData.detailCId)
	}

	selectObjects(object) {
		const { selectedObjects } = store.getState().constructor3d;
		const objectIsSelected = !!selectedObjects.find(el => el.object.uuid === object.object.uuid);
		if(!objectIsSelected) {
			store.dispatch({
				type: 'ADD_SELECTED_OBJECT',
				payload: object
			})
		} else {
			store.dispatch({
				type: 'REMOVE_SELECTED_OBJECT',
				payload: object.object.uuid
			})
		}
		const detail = this.getDetailClass(object)
		if(!empty(detail)) {
			detail.selectObject(object.object.uuid);
		}
	}

	getDetailObject(object) {
		const _object = object?.object ?? object;
		if(_object.userData?.detailCId) {
			return _object
		} else if(_object.parent) {
			return this.getDetailObject(_object.parent)
		} else {
			return null;
		}
	}

	setCameraView(axis) {
		let angle = [];
		switch (axis) {
			case 'x':
				angle = [1500, 0, 0]
				break
			case 'y':
				angle = [0, 1500, 0]
				break
			case 'z':
				angle = [0, 0, 1500]
		}
		this.sceneModel.camera.position.set(...angle);
		this.sceneModel.camera.lookAt(this.sceneModel.scene.position)
		this.render()
	}

	selectDetails(objects) {
		const details = [];
		const ids = {};

		objects.forEach(el => {
			const detailMesh = this.getDetailObject(el);
			if(detailMesh) {
				if(!ids[detailMesh.userData.detailCId]) {
					ids[detailMesh.userData.detailCId] = [el.uuid];
					details.push(detailMesh)
				} else {
					ids[detailMesh.userData.detailCId].push(el.uuid);
				}
			}
		})

		this.getDetails().forEach(detail => {
			for (const [cId, obj] of Object.entries(ids)) {
				if(detail.detailCId === cId) {
					if(!detail.isSelected) {
						detail.toggleSelected(true)
					}
					obj.forEach(_obj => detail.selectObject(_obj))
				} else {
					if(detail.isSelected) {
						detail.toggleSelected(false)
					}
				}
			}
		})

		store.dispatch({
			type: 'SET_SELECTED_DETAILS',
			payload: details
		})
	}

	addObject(object) {
		this.sceneModel.scene.add(object)
		this.render();
	}

	removeObject(obj) {
		this.sceneModel.deleteElement(obj)
		this.render()
	}

	getSceneObject(uuid) {
		return this.sceneModel.getObjectByUuid(uuid)
	}

	changeDetail(object) {
		const index = this.objects.findIndex(obj => obj.uuid === object.uuid);
    if (index !== -1) {
      this.objects[index] = object;
    }
	}

	get sceneModel() {
		return this._sceneModel;
	}

	set sceneModel(sceneModel) {
		this._sceneModel = sceneModel;
	}

	init() {

		this.render()
	}


	updateDetails() {
		const details = this.getDetails();
		details.forEach(detail => {
			this.sceneModel.deleteElement(detail.mesh)
		})
		return Promise.all(details.map(detail => this.addDetail(detail.detailCId)))

		// const updateDetailMesh = (parent) => {
		// 	if(empty(parent?.children)) return false;
		// 	while ()
		// 	for(let i=0; i<= parent.children.length; i++) {
		// 		if(!empty(parent.children[i]?.userData?.detailCId)) {
		// 			this.sceneModel.deleteElement(parent.children[i]);
		//
		// 			// parent.add(details.find(el => el.detailCId === mesh.userData.detailCId).create3DDetail())
		// 		}
		// 	}
		// 	// parent.children.forEach(mesh => {
		// 	// 	if(!empty(mesh?.userData?.detailCId)) {
		// 	// 		this.sceneModel.deleteElement(mesh);
		// 	//
		// 	// 		// parent.add(details.find(el => el.detailCId === mesh.userData.detailCId).create3DDetail())
		// 	// 	}
		// 	// 	// else if(!empty(mesh.children)) {
		// 	// 	// 	updateDetailMesh(mesh)
		// 	// 	// } else {
		// 	// 	// 	return false
		// 	// 	// }
		// 	// })
		// }
		// updateDetailMesh(this.sceneModel.scene)
	}

	render(updateDetails = false) {
		if(updateDetails) {
			this.updateDetails()
				.then(() => this.sceneModel.renderScene())
		} else {
			this.sceneModel.renderScene();
		}
		// this.getDetails().forEach(detail => this.sceneModel.scene.add(detail.create3DDetail()))
		// this.objects.forEach(object => this.sceneModel.scene.add(object));

	}

}