import {checkForAnyHandles, checkForMilling, empty} from "../../../../../helpers/helper";
import img from "../../../../../media/material-test.png";
import drill from "../../../../../media/drill.svg";
import saw from "../../../../../media/saw.svg";
import cutter from "../../../../../media/cutter.svg";
import multiplicityImg from "../../../../../media/multiplicity.svg";
import addSlidingSystem from "../../../../../media/addSlidingSystem2.svg";
import Languages from "../../../../../translation/Languages";
import {memo} from "react";
import {Tooltip} from "../../../Tooltip";
import {useMultiplicities} from "../../../../../hooks/useMultiplicity";

const Handlers = ({detail, detailHoles, detailGrooves, detailRabbets, detailMultiplicity, detailId, ssSign}) => {
		const multiplicityArray = useMultiplicities();

    return (
        <div className="detailsTab__handlers">
            {!empty(detailHoles) &&
                <span title='Сверління' className="detailsTab__handler pointer">
                    <img src={drill} alt="Сверління"/>
                </span>
            }
            {(!empty(detailGrooves) || !empty(detailRabbets)) &&
                <span title='Пази, Чверті' className="detailsTab__handler pointer">
                    <img src={saw} alt="Пази, Чверті"/>
                </span>
            }
            {checkForMilling(detail) &&
                <span title='Фрезерування' className="detailsTab__handler pointer">
                    <img src={cutter} alt="Фрезерування"/>
                </span>
            }
            {!empty(detailMultiplicity) &&
                <span title='Сшивка' className="detailsTab__handler pointer"
                      data-tooltip-id={`${detailMultiplicity !== null && detailMultiplicity?.type > 0 ? `multiplicity-tooltip-${detailId}` : ''}`}>
                    <img src={multiplicityImg} alt="Сшивка"/>
                </span>
            }

            {ssSign &&
                <span title={`${Languages.getTranslation("ss-handler-title", true)}${ssSign}`} className="detailsTab__handler pointer">
                    <img src={addSlidingSystem} width="17" height="18" alt="Розсувна система"/>
                </span>
            }
            {!checkForAnyHandles(detail) &&
                <span title='Немає обробок' className="detailsTab__handler pointer">
                    —
                </span>
            }

						<Tooltip
								className='tooltip-multiplicity'
								id={`multiplicity-tooltip-${detailId}`}
								place="right-center"
						>
								<div className='tooltip-multiplicity_body'>
										<div className='tooltip-multiplicity_header'>
												{Languages.getTranslation("stitching-type", true)}
										</div>
										{multiplicityArray.sort((a, b) => a.number - b.number).map((item, index) =>
												!index ? null :
														<div key={index}
																 className={`tooltip-multiplicity_body_wrapper ${detailMultiplicity?.type === item.number ? 'selectedM' : ''}`}>
																<div className="tooltip-multiplicity_body_wrapper_sub">
																		<div
																				className="tooltip-multiplicity_body_wrapper_item">{`${item.number}. `}</div>
																		<div className="tooltip-multiplicity_body_wrapper_item">{item.nameMin}</div>
																</div>
																<img className="tooltip-multiplicity_body_wrapper_img" src={item.img}
																		 alt={item.name}/>
														</div>
										)}
								</div>
						</Tooltip>
        </div>
    )
};

export default memo(Handlers);