import {useState} from "react";
import "./style.scss";
import {projectSelectors} from "redux/project";
import {useSelector} from "react-redux";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import {validationInput} from "../../../../helpers/helper";
import {Modal, ModalFooter, ModalHeader} from "../index";

const EditCount = ({isOpen, close, selectedDetails}) => {
	const construction = useSelector(projectSelectors.getConstruction);
	
	const [value, setValue] = useState('');
	
	const handleChange = (event) => {
		setValue(event.target.value);
	};
	
	const closeModal = () => {
		setValue("");
		close();
	}
	
	const submit = () => {
		construction.updateDetailCount(selectedDetails, value).then((data) => closeModal())
	};
	
	return (
		<Modal isOpen={isOpen}>
			<div className="edit-count-modal modal__wrapper">
				<ModalHeader>
					<h3 className="edit-count-modal__title">{Languages.getTranslation('update-count-details', true)}</h3>
				</ModalHeader>
				<div className="edit-count-modal__body">
					<div className="modal-save-project__form-control">
						<label
							htmlFor="name"
							className="modal-save-project__form-control-label"
						>
							{Languages.getTranslation('update-count-which-value', true)}
						</label>
						<input
							id="name"
							onChange={handleChange}
							name="value"
							value={value}
							type="text"
							maxLength="21"
							className="modal-save-project__form-control-input"
							onInput={(e) => validationInput(e)}
						/>
					</div>
				</div>
				<ModalFooter className="edit-count-modal__btns">
					<DefaultBtn title={Languages.getTranslation('cancel', true)} handler={closeModal}/>
					<DefaultBtn title={Languages.getTranslation('save', true)} handler={submit}/>
				</ModalFooter>
			</div>
		</Modal>
	);
};

export default EditCount;
