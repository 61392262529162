import {createAction} from '@reduxjs/toolkit';
import actionType from './actionType';

const ModalStateSwitch = createAction(actionType.ModalState);
const ModalDetailSwitch = createAction(actionType.ModalDetail);

export default {
  ModalStateSwitch,
  ModalDetailSwitch
};
