import {Box3, Quaternion, Vector3, Matrix4, Group} from "three";
import { CSG } from 'three-csg-ts';
import store from "../../../redux/store";
import {empty} from "../../../helpers/helper";
import _Constructor from "../../../db/_Constructor";

export default class Base{
	mesh;
	db = new _Constructor();

	constructor() {
	}

	getState(type) {
		return store.getState()?.constructor3d?.[type] ?? null;
	}

	getProjectState(type = null) {
		if(!empty(type)) {
			return store?.getState().project?.project?.construction?.[type] ?? []
		} else {
			return store?.getState().project?.project?.construction
		}
	}

	getPermissions() {
		return store?.getState()?.user?.user?.user?.payload?.user?.permissions ?? []
	}

	updateProjectProduct(productId, data) {
		const construction = this.getProjectState();
		return  construction.updateProduct(productId, 'minMax', data)
	}

	updateState({type, payload}) {
		return store.dispatch({type, payload})
	}

	getMeshGlobalPosition(mesh) {
		mesh = mesh.isMesh ? mesh : mesh.object
		const globalPosition = new Vector3();
		mesh.getWorldPosition(globalPosition);
		return globalPosition;
	}

	getMeshSize(mesh) {
		mesh = mesh.isMesh ? mesh : mesh.object
		const box = new Box3().setFromObject(mesh);
    return box.getSize(new Vector3());
	}

	getMeshQuaternion(mesh) {
		mesh = mesh.isMesh ? mesh : mesh.object
		const q = new Quaternion();
		mesh.getWorldQuaternion(q);
		return q;
	}

	getDetailIdFromObject(object) {
		if(object?.object) {
			return object.object.userData.detailCId;
		}
		return object.userData.detailCId;

	}

	getDetailMeshFromChild(obj) {
		if(obj?.isGroup && obj.userData?.detailCId) {
			return obj;
		} else if(obj?.parent){
			return this.getDetailMeshFromChild(obj.parent)
		} else {
			return null;
		}
	}

	getDetailFromMesh(mesh) {
		const detailMesh = this.getDetailMeshFromChild(mesh);
		if(detailMesh) {
			const cDetails = this.getState('cDetails');
			return cDetails.find(el => el.detailCId === detailMesh.userData.detailCId);
		} else if(mesh?.userData?.detailCId) {
			return this.getState('cDetails').find(el => el.detailCId === mesh?.userData?.detailCId)
		}

	}

	getDetailFromState(detail) {
		return this.getState('cDetails').find(el => el.detailCId === detail.detailCId);
	}

	getProductDetails(product_id) {
		if(empty(product_id)) return null
		return this.getState('cDetails').filter(el => el.productId === product_id);
	}

	getProductMinMax(product_id) {

	}

	getDetailById(detailCId) {
		if(empty(detailCId)) return null
		return this.getState('cDetails').find(el => el.detailCId === detailCId);
	}

	getScene() {
		return this.getState('scene')?.sceneClass || null;
	}

	lockProduct(productId) {
		const details = this.getProductDetails(productId);
		const scene = this.getScene();
		if(!empty(details)) {
			const product = new Group();
			details.forEach(el => {
				product.add(el.mesh);
				scene.removeDetail(el.mesh);
			})
			product.userData = {
				productId
			}
			scene.addObject(product)
			product.updateMatrixWorld(true)
		}
	}

	getDetailMeshBySide(detail, side) {
		return detail.mesh.children.find(el => el.userData.meshSide === side)
	}

	getDetailProcessing(detail, processingId) {
		return detail.mesh.children.find(el => el.userData?.pId === processingId)
	}

	getAllDetails() {
		return this.getState('cDetails')
	}

	getConnections() {
		return this.getState('connections')
	}

	/**
	 * Retrieves the connected details for the given detail.
	 *
	 * @param {object} detail - The detail object for which to retrieve connected details.
	 * @returns {array} An array of connected detail objects.
	 */
	getConnectedDetails(detail) {
		const {detailCId, productId} = detail
		const connectedDetails = [];
		const allDetails = this.getProductDetails(productId);
		const connections = this.getConnections();
		connections.forEach(el => {
			if(el.mainDetail === detailCId) {
				if(!connectedDetails.includes(el.targetDetail)) {
					connectedDetails.push(el.targetDetail)
				}
			}
		})
		return allDetails.filter(el => connectedDetails.includes(el.detailCId) );
	}

	getAllConnectedDetails(detail) {
		const connectedDetails = [];
		const connections = this.getConnections();
		const allDetails = this.getProductDetails(detail.productId);

		function addDetailsIds(detailCId) {
			connections.forEach(el => {
				if(el.mainDetail === detailCId) {
					if(!connectedDetails.includes(el.targetDetail)) {
						connectedDetails.push(el.targetDetail)
					}
				}
			})
		}

		addDetailsIds(detail.detailCId)

		connectedDetails.forEach(el => {
			addDetailsIds(el)
		})

		return allDetails.filter(el => connectedDetails.includes(el.detailCId) );
	}

	getDetailsFromGroup(detail) {
		if(!detail?.group?.group_id) return [];
		const allDetails = this.getProductDetails(detail.productId);
		return allDetails.filter(d => d.group?.group_id === detail.group.group_id)
	}

	getProductSize(productId) {
		const details = this.getProductDetails(productId);
    const box = new Box3();
    details.forEach(el => {
      box.expandByObject(el.mesh);
    });
    return  {box, size: box.getSize(new Vector3())};
	}

	calcScaleFactor(newSize, productId) {
		const oldProductSize = this.getProductSize(productId);
		let scaleFactor = new Vector3();
		scaleFactor.z = newSize.z / oldProductSize.z;
		scaleFactor.x = newSize.x / oldProductSize.x;
		scaleFactor.y = newSize.y / oldProductSize.y;
		return scaleFactor;
	}


}