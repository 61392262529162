import {useState, useEffect, useRef, useCallback} from "react";
import TableDetails from "components/UI/Tables/Details";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import "./style.scss";
import {modalsOperations} from "redux/modals";
import Languages from "translation/Languages";
import _Details from "../../db/_Details";
import {empty, updateDetailOrderInDB, checkDetailsWithoutEdges} from "../../helpers/helper";
import {toast} from "react-toastify";
import SubmitModal from "../UI/Modal/SubmitModal";
import {errorMessageOperations} from '../../redux/errors'
import Errors from "../../models/Errors";
import {tempDetailOperations} from "redux/tempDetail";
import AhtungModal from "../UI/Modal/AhtungModal";
import Loader from "../UI/Loader";
import AddDetailsModal from "../UI/Modal/AddDetailsModal";
import CloneDetailsModal from "../UI/Modal/CloneDetailsModal";
import cloneDeep from "lodash.clonedeep";
import FilterSidebar from "../UI/FilterSidebar";
import InputModal from "../UI/Modal/InputModal";
import Requests from "../../api/API";
import InputExelModal from "../UI/Modal/InputExelModal";
import EditCount from "../UI/Modal/EditCount";
import {toastError, toastSuccess} from "../../helpers/toasts";
import TableHardware from "../UI/Tables/Hardware";
import AddHardwareModal from "../UI/Modal/AddHardwareModal";
import AddCatalogModal from "../UI/Modal/AddCatalogModal";

import CustomSelect from "../UI/Select"

import {components} from "react-select";
const { Option } = components;

const detailsDb = new _Details();
const _errors = new Errors([])
import axios from "axios";
import {userSelectors} from "redux/user";
import Project from "../../models/Project";
import Contour from "../UI/SvgSketch";
import Loader2 from "../UI/Loader2";
import CONSTANTS from "../../config/constants";
import isEqual from "lodash.isequal";
import JoinToSlidingSystemOrder from "../UI/Modal/JoinToSlidingSystemOrder";
import {generateDetailActions, parentStyles, chooseFormatByType, getModeClass, getModeItemClass} from "./helper";
import JoinToSlidingSystemOrderManager from "../UI/Modal/JoinToSlidingSystemOrderManager";
import {ImportDetailsTooltip} from "../UI/ImportDetails";

const DETAILS_TABS = {
	DETAILS: "details",
	HARDWARE: "hardware"
};

const MODALS_NAME = {
	SUBMIT: "submit",
	INPUT: "input",
	CLONE_DETAIL: "cloneDetail",
	GIB_LAB: "gibLab",
	EDIT_COUNT: "editCount",
	ADD_DETAILS: "addDetails",
	HARDWARE: "hardware",
	CATALOG: "catalog",
	IMPORT_DETAILS: "importDetails",
	JOIN_TO_SS_ORDER: "joinToSSOrder",
	JOIN_TO_SS_ORDER_MANAGER: "joinToSSOrderManager"
};

const INITIAL_STATE = Object.values(MODALS_NAME).reduce((result, key) => {
	result[key] = false;
	
	return result;
}, {})

const CustomOption = (props) => {
	return (
		<Option {...props}>
			<div style={{display: 'flex', gap: "5px"}}>
				{props.data.img && <span style={{display: "flex"}}><img  src={props.data.img} width="20" height="21"  alt={props.data.label} /></span>}
				{props.data.label}
			</div>
		</Option>
	);
};

const Details = () => {
	const dispatch = useDispatch();
	const construction = useSelector(projectSelectors.getConstruction);
	const {errors} = useSelector(state => state.project.project);
	const detailsList = construction._details;
	const materials = useSelector(projectSelectors.getMaterials);
	const detailsTableConfig = useSelector(projectSelectors.getDetailsTableConfig);
	const permissions = useSelector(userSelectors.permissions);
	
	const importProjectInputRef = useRef();
	const [selectedDetails, setSelectedDetails] = useState([]);
	const [details, setDetails] = useState(detailsList);
	const [isDetailsLoading, setIsDetailsLoading] = useState(true)
	const [initialDetails, setInitialDetails] = useState(() => detailsList);
	
	const [modals, setModals] = useState(INITIAL_STATE);
	const [selectedValue, setSelectedValue] = useState('');
	const [selectType, setSelectType] = useState('')
	const [submitModalText, setSubmitModalText] = useState('')
	const [detailForDeleting, setDetailForDeleting] = useState(null)
	const [load, setLoad] = useState(false)
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)
	const [isProjectImportTooltipOpen, setIsProjectImportTooltipOpen] = useState(false)
	const [filteredActive, setFilteredActive] = useState(({
		isMaterialChecked: false,
		isEdgesChecked: false,
		isThicknessChecked: false,
		isMultiplicityChecked: false,
		isProductsChecked: false,
		isTextureChecked: false,
	}));
	const [tabToggle, setTabToggle] = useState(DETAILS_TABS.DETAILS);
	
	const [currentDetailsToShow, setCurrentDetailsToShow] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(false); // новое состояния для контроля рендеринга
	
	const lastDetailWithImgPdf = detailsList[detailsList.length - 1]?.imgPdf
	const detailsPerInterval = 10;
	const intervalTime = 700;
	
	const isDetailsModeEqualsList = detailsTableConfig.mode === CONSTANTS.detailsTableModes.list;
	const isDetailsTabActive = tabToggle === DETAILS_TABS.DETAILS;
	
	useEffect(() => {
		let timer = null;
		
		if (empty(lastDetailWithImgPdf)) {
			timer = setTimeout(() => {
				if (currentIndex < detailsList.length) {
					setIsLoading(true); // начинаем загрузку
					const nextDetails = detailsList.slice(currentIndex, currentIndex + detailsPerInterval);
					setCurrentDetailsToShow(nextDetails);
					setCurrentIndex(prevIndex => prevIndex + detailsPerInterval);
				} else if (empty(lastDetailWithImgPdf)) {
					setIsLoading(true); // начинаем загрузку
					let objectsWithImgPdfNull = detailsList.filter(detail => detail.imgPdf === null || detail.imgPdf === '');
					setCurrentDetailsToShow(objectsWithImgPdfNull);
				} else {
					setIsLoading(false); // если все детали отображены, останавливаем загрузку
				}
			}, intervalTime);
		}
		
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [currentIndex, lastDetailWithImgPdf, detailsList]);
	
	const toggleModal = (name, value) => {
		setModals((prev) => ({...prev, [name]: value}));
	}
	
	const resetSelectedDetails = () => {
		setSelectedDetails([]);
	}
	
	const cleanSelectedValue = () => {
		setSelectedValue('');
	}
	
	const updateOrderAfterRemovalOnce = async (deletedItem) => {
		// slicing to create a new copy
		const newDetails = initialDetails.filter(detail => detail.id !== deletedItem.id);
		newDetails.forEach((item, index) => {
			item.order = index + 1;
		});
		setInitialDetails(newDetails);
		
		// updating database
		await Promise.all(newDetails.map((detail, index) => updateDetailOrderInDB(detail, index + 1)));
	}
	
	const updateOrderAfterRemoval = async () => {
		await Promise.all(initialDetails.map(async (detail, index) => {
			const newOrder = index + 1;
			if (newOrder !== detail.order) {
				detail.order = newOrder;
				await updateDetailOrderInDB(detail, newOrder);
			}
		}));
	};
	
	// TODO removeDetails and removeDetail have the same logic. Can I combine them?
	const removeDetails = (detail) => {
		detailsDb.deleteDetail(detail._id).then(() => {
			setInitialDetails([...construction._details]);
		}).catch(err => console.log(err))
	};
	
	const removeDetail = () => {
		setInitialDetails((prev) => prev.filter((el) => el !== detailForDeleting));
		construction._details = initialDetails.filter((el) => el !== detailForDeleting);
		detailsDb.deleteDetail(detailForDeleting.id).then(() => {
			setInitialDetails([...construction._details]);
		}).catch(err => console.log(err))
	};
	
	useEffect(() => {
		if (!errors.isShow) {

			const checkDetailsErrors = (detailsList) => {
				for (const detail of detailsList) {
					if (detail?.error?._errorsMessage.length > 0) {
						const hasError = detail.error._errorsMessage.some((error) => error.type !== "success");

						if (hasError) {
							const message = Languages.getTranslation('erroneous-parts-found-in-the-project', true);

							toast.error(`${message}`, {
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
								toastId: `${Languages.getTranslation('erroneous-parts-found-in-the-project', true)}`,
							});

							dispatch(projectOperations.errorProject(message, true));
							break;
						}
					}
				}
			};
			checkDetailsErrors(detailsList)
		}
	}, [errors]);
	
	useEffect(() => {
		if (detailForDeleting) {
			setSubmitModalText(Languages.getTranslation('delete-detail-accept', true) + detailForDeleting.name + "?")
			setSelectType('deleteDetail')
			toggleModal(MODALS_NAME.SUBMIT, true);
		}
	}, [detailForDeleting])
	
	useEffect(() => {
		setInitialDetails((prev) => {
			const detailsMap = new Map(prev.map(d => [d.id, d]));
			
			return detailsList.map((newDetail) => {
				const existingDetail = detailsMap.get(newDetail.id);
				
				if (existingDetail && !isEqual(existingDetail, newDetail)) {
					return newDetail;
				}
				
				// If it exists and hasn't changed, keep the original
				return existingDetail || newDetail; // Adds new detail if it wasn’t in prev
			});
		})
		setIsDetailsLoading(false);
	}, [detailsList]);
	
	useEffect(() => {
		if (selectedValue === 'selectRemoveDetails') {
			updateOrderAfterRemoval();
			selectedDetails.forEach((sd) => {
				removeDetails(sd)
			})
			construction._details = initialDetails.filter((detail) => !selectedDetails.includes(detail))
			setInitialDetails((prev) => prev.filter((detail) => !selectedDetails.includes(detail)));
			
			cleanSelectedValue();
			resetSelectedDetails();
		}
		if (selectedValue === "deleteDetail") {
			updateOrderAfterRemovalOnce(detailForDeleting)
			removeDetail()
			setSelectType('')
			cleanSelectedValue();
		}
		if (selectedValue === "import_project" && !empty(importProjectInputRef.current)) {
			importProjectInputRef.current.click()
			cleanSelectedValue();
		}
		if (selectedValue === "import_project_id") {
			toggleModal(MODALS_NAME.INPUT, true);
			cleanSelectedValue();
		}
		if (selectedValue === "transferToReverseSide") {
			updateHolesOnSelectedDetails()
			cleanSelectedValue();
			resetSelectedDetails();
		}
		if (selectedValue === "import_project_gitlab") {
			toggleModal(MODALS_NAME.GIB_LAB, true);
			cleanSelectedValue();
		}
		if (["import_project_pro100", "import_project_voody", "import_project_3dConstructor"].includes(selectedValue)) {
			importProjectInputRef.current.click();
		}
	}, [selectedValue])
	
	const updateHolesOnSelectedDetails = () => {
		let isSuccess = false
		selectedDetails.map(detail => Promise.all(detail.holes.map(hole => {
			if (hole) {
				isSuccess = true
				return hole.reverseSide()
			}
		})))
		if (isSuccess) toastSuccess(Languages.getTranslation('reverse-holes-to-side-success', true))
	}
	
	const toggleFilterSidebar = useCallback(() => {
		setIsSidebarOpen((prev) => !prev);
		document.body.classList.toggle("sidebar-open");
	}, [setIsSidebarOpen]);
	
	const changeAction = (event) => {
		const value = event
		
		if (empty(selectedDetails)) {
			_errors.setError(`${Languages.getTranslation('choose-one-detail', true)}`, 'error', true)
			return
		}
		
		if (value === 'selectMaterial') {
			dispatch(modalsOperations.switchStateForModal({
				show: true,
				type: 'REPLACE_MATERIAL_PROJECT',
				isEdit: selectedDetails,
				isReplacedMaterialForProject: false,
			}))
			cleanSelectedValue();
			resetSelectedDetails();
		}
		
		if (value === 'changeProductOnDetail') {
			dispatch(modalsOperations.switchStateForModal({
				show: true,
				type: 'CHANGE_PRODUCT_ON_DETAIL',
				isEdit: selectedDetails,
			}))
			cleanSelectedValue();
			resetSelectedDetails();
		}
		
		if (value === 'selectEdge') {
			if (empty(construction.edges)) {
				dispatch(errorMessageOperations.switchStateError({
					message: Languages.getTranslation('edge-to-detail-error', true),
					type: 'error',
					show: true
				}))

				cleanSelectedValue();
				resetSelectedDetails();
				return
			}
			const isWithoutEdgeOnDetail = checkDetailsWithoutEdges(selectedDetails)
			if (isWithoutEdgeOnDetail) {
				dispatch(errorMessageOperations.switchStateError({
					message: Languages.getTranslation('details-without-edges', true),
					type: 'error',
					show: true
				}))

				cleanSelectedValue();
				resetSelectedDetails();
				return
			}


			dispatch(modalsOperations.switchStateForModal({
				show: true,
				type: 'REPLACE_EDGE',
				isEdit: selectedDetails
			}))
			cleanSelectedValue();
			resetSelectedDetails();
		}
		
		if (value === 'groupChangeDiamHoles') {
			dispatch(modalsOperations.switchStateForModal({
				show: true,
				type: 'GROUP_CHANGE_DIAM_HOLES',
				isEdit: selectedDetails
			}))
			cleanSelectedValue();
			resetSelectedDetails();
		}
		
		if (value === 'removeDetails') {
			setSubmitModalText(Languages.getTranslation('submit-removeDetails', true))
			setSelectType('selectRemoveDetails')
			toggleModal(MODALS_NAME.SUBMIT, true);
			cleanSelectedValue();
		}
		
		if (value === 'selectTexture') {
			selectedDetails.forEach((sd) => {
				if (!['OSB'].includes(materials.find(mat => mat.index === sd.material).type)) {
					sd.updateDetail("isRotateTexture", true);
				}
			})
			detailsList.forEach((el) => {
				if (selectedDetails.includes(el) && !['OSB'].includes(materials.find(mat => mat.index === el.material).type)) {
					el.isRotateTexture = true
				}
			})
			cleanSelectedValue();
			resetSelectedDetails();
		}
		if (value === 'addSlidingSystem') {
			const whichModalToOpen = permissions.includes("KM_canSearchInJoiningDetailToSSOrder") ? MODALS_NAME.JOIN_TO_SS_ORDER_MANAGER : MODALS_NAME.JOIN_TO_SS_ORDER;
			toggleModal(whichModalToOpen, true);
			cleanSelectedValue();
		}
		if (value === 'removeSlidingSystem') {
			selectedDetails.forEach((sd) => {
				sd.updateDetail("isSlidingSystem", "");
			});
			detailsList.forEach((detail) => {
				if (selectedDetails.includes(detail)) {
					detail.isSlidingSystem = "";
				}
			});
			toastSuccess(Languages.getTranslation('connection-to-ss-order-removed', true));
			cleanSelectedValue();
			resetSelectedDetails();
		}
		
		if (value === 'removeTexture') {
			selectedDetails.forEach((sd) => {
				if (!['Постформінг', 'Постформинг', 'OSB'].includes(materials.find(mat => mat.index === sd.material).type)) {
					sd.updateDetail("isRotateTexture", false);
				}
			})
			detailsList.forEach((el) => {
				if (selectedDetails.includes(el)) {
					if (!['Постформінг', 'Постформинг', 'OSB'].includes(materials.find(mat => mat.index === el.material).type)) {
						el.isRotateTexture = false
					}
				}
			})
			cleanSelectedValue();
			resetSelectedDetails();
		}
		
		if (value === 'updateCountDetails') {
			toggleModal(MODALS_NAME.EDIT_COUNT, true);
			cleanSelectedValue();
		}
		
		if (value === 'transferToReverseSide') {
			setSelectedValue('transferToReverseSide')
		}
		
		if (value === 'cloneDetail') {
			const tempDetails = cloneDeep(selectedDetails)
			tempDetails.forEach((item) => {
				item.name += Languages.getTranslation('copy', true)
			})
			dispatch(tempDetailOperations.setTempDetails(tempDetails));
			toggleModal(MODALS_NAME.CLONE_DETAIL, true);
			cleanSelectedValue();
			resetSelectedDetails();
		}
	};
	
	const createDetail = async () => {
		if (!materials.length) {
			toastError(Languages.getTranslation('dont-have-materials-project', true))
			return;
		}
		if (empty(construction.products)) {
			await construction.createProduct({name: Languages.getTranslation('single-product', true)})
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "ADD_DETAIL",
			})
		);
	};
	
	const openHardware = () => {
		toggleModal(MODALS_NAME.HARDWARE, true);
	}
	
	const quickInputTableHandler = async () => {
		if (!materials.length) {
			toastError(Languages.getTranslation('dont-have-materials-project', true))
			return;
		}
		if (empty(construction.products)) {
			await construction.createProduct({name: Languages.getTranslation('single-product', true)})
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "QUICK_INPUT_TABLE",
			})
		);
	};
	
	const productsHandler = () => {
		if (!materials.length) {
			toastError(Languages.getTranslation('dont-have-materials-project', true))
			return;
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "PRODUCTS",
			})
		);
	};
	
	const uploadFileHandler = async (event) => {
		setLoad(true)
		
		const formData = new FormData()
		formData.append('file', event.target.files[0]);
		
		await axios.post(`${process.env.CVRT_KRONAS}/api/${selectedValue === 'import_project_pro100' || selectedValue === 'import_project_voody' || selectedValue === "import_project_3dConstructor" ? 'convert-file-pro100' : 'convert-file'}`, formData, {
			headers: {'Content-Type': 'multipart/form-data',}
		})
			.then(data => {
				dispatch(tempDetailOperations.setTempProject(data.data.data))
				setLoad(false)
				toggleModal(MODALS_NAME.ADD_DETAILS, true);
			})
			.catch(err => console.log(err))
	}
	
	const handleGetId = (id) => {
		Requests.Projects.getProjectById(id)
			.then(response => {
				// TODO JSON.parse(response.file) RangeError: Maximum call stack size exceeded
				// dispatch(tempDetailOperations.setTempProject(JSON.parse(response.file)))
				dispatch(tempDetailOperations.setTempProject(JSON.parse(response.file)))
				toggleModal(MODALS_NAME.INPUT, false);
				toggleModal(MODALS_NAME.ADD_DETAILS, true);
			})
			.catch(error => console.log(error))
	}
	
	const handleOpenCLone = () => {
		toggleModal(MODALS_NAME.GIB_LAB, false);
	}
	
	const openImportDetailsModal = (importType) => {
		setIsProjectImportTooltipOpen(false);
		toggleModal(MODALS_NAME.IMPORT_DETAILS, true);
		setSelectType(importType);
	}
	
	const searchCatalog = () => {
		toggleModal(MODALS_NAME.HARDWARE, !modals[MODALS_NAME.HARDWARE]);
		toggleModal(MODALS_NAME.CATALOG, !modals[MODALS_NAME.CATALOG]);
	}
	
	const changeDetailsMode = () => {
		if (!isDetailsModeEqualsList) {
			Project.validateProjectDetails().then(() => {
				dispatch(
					projectOperations.setDetailsTableMode(CONSTANTS.detailsTableModes.list)
				);
				dispatch(
					projectOperations.setDefaultTableLimits()
				);
			}).catch(error => console.log(error))
		} else {
			dispatch(
				projectOperations.setDetailsTableMode(CONSTANTS.detailsTableModes.edit)
			);
			dispatch(
				projectOperations.setDefaultTableLimits()
			);
		}
	}
	
	const createDetailButton = () => {
		if (!permissions.includes('KM_createDetail')) return null;
		
		const modeTogglerTitle = isDetailsModeEqualsList ?
			Languages.getTranslation("part-editing-mode", true) :
			Languages.getTranslation("save", true)
		
		return (
			<div
				className={`${isDetailsModeEqualsList ? 'details__options-add' : 'details__options-add_editMode'}
                ${!isDetailsTabActive ? 'bgNoSelect' : 'bgSelect'}`}
				onClick={() => {
					toast.dismiss();
					setTabToggle('details')
				}}>
				<div className="details__options-add_sub">
					<button
						onClick={createDetail}
						className="btn"
						style={{marginLeft: "23px"}}
						title={Languages.getTranslation('add-detail', true)}
					>
						<i className="btn__icon-add"></i>
					</button>
					<span className="details__options-title">{Languages.getTranslation("details", true)}</span>
				</div>
				{!isDetailsModeEqualsList &&
					<div className='details__options-add_editMode_wraper'>
						<div className='details__options-add_editMode_block'>
                            <span
															className='details__options-add_editMode_block_title'>{Languages.getTranslation("gabarits", true)}</span>
							<div className={getModeClass(detailsTableConfig.limits.onlyFirstGroup)} title={Languages.getTranslation('gabarits-only', true)}
									 onClick={() => {
										 if (detailsTableConfig.limits.onlyFirstGroup) {
											 dispatch(projectOperations.setDefaultTableLimits());
											 dispatch(projectOperations.setDefaultDetailsTableFocusedRow());
										 } else {
											 dispatch(projectOperations.setDetailsTableLimits({onlyFirstGroup: true, onlyEdge: false}));
											 dispatch(projectOperations.setDetailsTableFocusedRow({
												 detailIndex: detailsTableConfig.focusedRow.detailIndex,
												 fieldIndex: CONSTANTS.firstFieldDetailIndex
											 }));
										 }
									 }}>
								<div className={getModeItemClass(detailsTableConfig.limits.onlyFirstGroup)}></div>
							</div>
						</div>
						<div className='details__options-add_editMode_block'>
                            <span
															className='details__options-add_editMode_block_title'>{Languages.getTranslation("edge", true)}</span>
							<div className={getModeClass(detailsTableConfig.limits.onlyEdge)} title={Languages.getTranslation('edges-only', true)}
									 onClick={() => {
										 if (detailsTableConfig.limits.onlyEdge) {
											 dispatch(projectOperations.setDefaultTableLimits());
											 dispatch(projectOperations.setDefaultDetailsTableFocusedRow());
										 } else {
											 dispatch(projectOperations.setDetailsTableLimits({onlyFirstGroup: false, onlyEdge: true}));
											 dispatch(projectOperations.setDetailsTableFocusedRow({
												 detailIndex: detailsTableConfig.focusedRow.detailIndex,
												 fieldIndex: CONSTANTS.firstFieldDetailIndex
											 }));
										 }
									 }}>
								<div className={getModeItemClass(detailsTableConfig.limits.onlyEdge)}></div>
							</div>
						</div>
					</div>}
				{permissions.includes('KM_editDetails') &&
					<button type="button" title={modeTogglerTitle} onClick={changeDetailsMode}
									className="details__options-add_edit" disabled={!isDetailsTabActive}>
						{isDetailsModeEqualsList
							?
							<svg width="26" height="25" viewBox="0 0 26 25" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
								<rect x="0.75" y="0.778076" width="25" height="24" rx="4" fill="#F3E0EE"/>
								<path
									d="M15.3753 20.3927H11.1253C7.27908 20.3927 5.63574 18.7494 5.63574 14.9032V10.6532C5.63574 6.80691 7.27908 5.16357 11.1253 5.16357H12.542C12.8324 5.16357 13.0732 5.40441 13.0732 5.69482C13.0732 5.98524 12.8324 6.22607 12.542 6.22607H11.1253C7.85991 6.22607 6.69824 7.38774 6.69824 10.6532V14.9032C6.69824 18.1686 7.85991 19.3302 11.1253 19.3302H15.3753C18.6407 19.3302 19.8024 18.1686 19.8024 14.9032V13.4865C19.8024 13.1961 20.0432 12.9552 20.3337 12.9552C20.6241 12.9552 20.8649 13.1961 20.8649 13.4865V14.9032C20.8649 18.7494 19.2216 20.3927 15.3753 20.3927Z"
									fill="#A14389"/>
								<path
									d="M10.7722 16.8086C10.3401 16.8086 9.94341 16.6528 9.65299 16.3694C9.30591 16.0223 9.15716 15.5194 9.23508 14.9882L9.53966 12.8561C9.59633 12.4453 9.86549 11.914 10.1559 11.6236L15.7376 6.04193C17.1472 4.63235 18.578 4.63235 19.9876 6.04193C20.7597 6.81401 21.1067 7.60026 21.0359 8.38651C20.9722 9.02401 20.6322 9.64734 19.9876 10.2848L14.4059 15.8665C14.1155 16.1569 13.5842 16.4261 13.1734 16.4828L11.0413 16.7873C10.9492 16.8086 10.8572 16.8086 10.7722 16.8086ZM16.4884 6.79276L10.9067 12.3744C10.7722 12.509 10.6163 12.8207 10.588 13.0048L10.2834 15.1369C10.2551 15.3423 10.2976 15.5123 10.4038 15.6186C10.5101 15.7248 10.6801 15.7673 10.8855 15.739L13.0176 15.4344C13.2017 15.4061 13.5205 15.2503 13.648 15.1157L19.2297 9.53401C19.6901 9.0736 19.9309 8.66276 19.9663 8.28026C20.0088 7.81985 19.768 7.3311 19.2297 6.78568C18.0963 5.65235 17.3172 5.9711 16.4884 6.79276Z"
									fill="#A14389"/>
								<path
									d="M18.8108 11.241C18.7613 11.241 18.7117 11.2339 18.6692 11.2198C16.8063 10.6956 15.3258 9.21519 14.8017 7.35227C14.7238 7.06894 14.8867 6.77852 15.17 6.69352C15.4533 6.61561 15.7438 6.77852 15.8217 7.06186C16.2467 8.57061 17.4438 9.76769 18.9525 10.1927C19.2358 10.2706 19.3988 10.5681 19.3208 10.8514C19.2571 11.0923 19.0446 11.241 18.8108 11.241Z"
									fill="#A14389"/>
							</svg>
							:
							<svg width="26" height="25" viewBox="0 0 26 25" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
								<rect x="0.882812" y="0.506592" width="25" height="24" rx="4" fill="#A14389"/>
								<path fillRule="evenodd" clipRule="evenodd"
											d="M17.1908 5.34327C17.2341 5.3056 17.2852 5.28134 17.3444 5.27051H19.1311C19.2767 5.27051 19.3954 5.32177 19.4869 5.42412L20.5138 6.45106C20.6108 6.54808 20.6592 6.66126 20.6592 6.79062V19.2736C20.6592 19.403 20.6134 19.5134 20.5216 19.6051C20.4354 19.6966 20.3303 19.7426 20.2064 19.7426H6.56744C6.44326 19.7426 6.33557 19.6968 6.24405 19.6051C6.15221 19.5134 6.10645 19.4028 6.10645 19.2736V5.73943C6.10645 5.61007 6.15221 5.49963 6.24405 5.40795C6.33557 5.31643 6.44617 5.27051 6.57521 5.27051H9.12193C9.21362 5.27051 9.28654 5.29751 9.34039 5.35136C9.37806 5.3837 9.4049 5.4322 9.42123 5.49688H9.42932V10.4691C9.43466 10.5931 9.48042 10.6982 9.56676 10.7844C9.64761 10.8706 9.75013 10.9163 9.87399 10.9218H16.6491C16.7731 10.9218 16.881 10.8761 16.9725 10.7844C17.064 10.6982 17.1127 10.5929 17.118 10.4691V5.49688C17.1342 5.4377 17.1586 5.38644 17.1908 5.34327ZM15.9863 6.01448V10.2427H13.9568V6.01448H15.9863ZM18.7025 18.9743V13.2504C18.6971 13.1264 18.6514 13.024 18.565 12.9432C18.4789 12.857 18.3736 12.8085 18.2499 12.7978H8.5319L8.47515 12.814H8.44297C8.34061 12.8302 8.25427 12.8814 8.18425 12.9676C8.10874 13.0538 8.0709 13.1481 8.0709 13.2506H8.06298V18.9743H18.7025Z"
											fill="white"/>
							</svg>
						}
					</button>}
			</div>
		)
	}
	
	const closeModalAndResetSelectedDetails = (modalName) => {
		toggleModal(modalName, false);
		resetSelectedDetails();
	}
	
	const setSSOrderNumberToDetails = (orderNumber) => {
		selectedDetails.forEach((sd) => {
			sd.updateDetail("isSlidingSystem", orderNumber);
		});
		detailsList.forEach((detail) => {
			if (selectedDetails.includes(detail)) {
				detail.isSlidingSystem = orderNumber;
			}
		});
		const message = `${Languages.getTranslation('connection-to-ss-order-created1')}${orderNumber} ${Languages.getTranslation('connection-to-ss-order-created2',)}`
		toastSuccess(message);
	}
	
	useEffect(() => {
		return () => {
			dispatch(
				projectOperations.setDetailsTableMode(CONSTANTS.detailsTableModes.list)
			);
			dispatch(projectOperations.setDefaultTableLimits());
		}
	}, [])
	
	return (
		<>
			{load ? <div className='mT-10'><Loader/></div> :
				<>
					<div className="details__options">
						{createDetailButton()}
						
						{isDetailsModeEqualsList && permissions.includes('KM_furniture') &&
							<div
								className={`details__options-add ${isDetailsTabActive ? 'bgNoSelect' : 'bgSelect'}`}
								style={{justifyContent: "flex-start"}}
								onClick={() => {
									toast.dismiss();
									setTabToggle('hardware')
								}}>
								<button
									onClick={openHardware}
									className="btn"
									style={{marginLeft: "23px"}}
									title={Languages.getTranslation('add-hardware', true)}
								>
									<i className="btn__icon-add"></i>
								</button>
								<span style={{marginLeft: "15px"}}
											className="details__options-title">{Languages.getTranslation('furniture-title', true)}</span>
							</div>}
						{permissions.includes('KM_TSW') && (
							<button
								className="details__quick-input-table"
								onClick={quickInputTableHandler}
								title={Languages.getTranslation('open-qit', true)}
							>
								{Languages.getTranslation("quick-input-table", true)}
							</button>
						)}
						{permissions.includes('KM_importDetail') && (
							<><button
								className="details__quick-input-table"
								data-tooltip-id={`imports-tooltip`}
								title={Languages.getTranslation("import-parts", true)}
							>
								<i className="icon-link"></i>
							</button>
								<ImportDetailsTooltip
								isOpen={isProjectImportTooltipOpen}
								setIsOpen={(value) => setIsProjectImportTooltipOpen(value)}
								importHandler={openImportDetailsModal}
								/>
							</>
						)}
						{permissions.includes('KM_TSW') && (
							<button
								className="details__quick-input-table details__products"
								onClick={productsHandler}
								title={Languages.getTranslation('open-productsModal', true)}
							>
								<i className="details__products-icon"></i>
								<span>{Languages.getTranslation("products", true)}</span>
							</button>
						)}
						{permissions.includes("KM_detailsSelect") && (
							<div className="details__select">
								<CustomSelect
									value={selectedValue}
									customStyles={parentStyles}
									placeholder={Languages.getTranslation("select-action", true)}
									options={generateDetailActions(selectedDetails, permissions).filter(action => action.visible)}
									isDisabled={!isDetailsTabActive || selectedDetails.length === 0}
									components={{
										Option: CustomOption
									}}
									handleChange={(selectedOption) => {
										setSelectedValue(selectedOption);
										changeAction(selectedOption && selectedOption.value ? selectedOption.value : "");
									}}
								/>
							</div>
						)}
						<button type="button" className="details__filter" onClick={toggleFilterSidebar}
										disabled={!isDetailsTabActive}>
							<span className="details__filter-text">
									{Languages.getTranslation('filter', true)}
							</span>
							<div className="details__icon"/>
						</button>
					</div>
					<input type="file" id="project-calc__import"
								 className='import-project'
								 accept={chooseFormatByType(selectedValue)}
								 onChange={(e) => uploadFileHandler((e))}
								 ref={importProjectInputRef}
					/>
					{isDetailsTabActive && !isDetailsLoading &&
						<TableDetails
							isDetailsModeEqualsList={isDetailsModeEqualsList}
							details={details}
							setSelectedDetails={setSelectedDetails}
							selectedDetails={selectedDetails}
							setDetails={setInitialDetails}
							filteredActive={filteredActive}
							setDeleteDetail={setDetailForDeleting}
						/>
					}
					{isDetailsTabActive && isDetailsLoading &&
						<Loader2/>
					}
					{!isDetailsTabActive && <TableHardware/>}
					{modals[MODALS_NAME.HARDWARE] &&
						<AddHardwareModal
							searchCatalog={searchCatalog}
							close={() => toggleModal(MODALS_NAME.HARDWARE, false)}
						/>}
					{modals[MODALS_NAME.CATALOG] &&
						<AddCatalogModal
							close={() => toggleModal(MODALS_NAME.CATALOG, false)}
							backClick={() => searchCatalog()}
						/>}
					<SubmitModal
						isOpen={modals[MODALS_NAME.SUBMIT]}
						setShowSubmitModal={(value) => toggleModal(MODALS_NAME.SUBMIT, value)}
						setSelectedValue={setSelectedValue}
						selectType={selectType}
						submitModalText={submitModalText}
						setDeleteDetail={setDetailForDeleting}/>
					<AhtungModal isOpen={modals[MODALS_NAME.IMPORT_DETAILS]}
						setShowSubmitModal={(value) => toggleModal(MODALS_NAME.IMPORT_DETAILS, value)}
						submitModalText={Languages.getTranslation('import-project-accept', true)}
						setSelectedValue={setSelectedValue} selectType={selectType}/>
					{modals[MODALS_NAME.ADD_DETAILS] && <AddDetailsModal close={() => {
						dispatch(tempDetailOperations.setTempProject({}))
						toggleModal(MODALS_NAME.ADD_DETAILS, false);
					}}/>}
					<CloneDetailsModal
						isOpen={modals[MODALS_NAME.CLONE_DETAIL]}
						close={() => {
						dispatch(tempDetailOperations.setTempDetails([]))
						toggleModal(MODALS_NAME.CLONE_DETAIL, false);
					}}/>
					{modals[MODALS_NAME.INPUT] && <InputModal
						close={() => toggleModal(MODALS_NAME.INPUT, false)}
						getId={handleGetId}/>}
					<JoinToSlidingSystemOrder
						onContinue={setSSOrderNumberToDetails}
						isOpen={modals[MODALS_NAME.JOIN_TO_SS_ORDER]}
						close={() => closeModalAndResetSelectedDetails(MODALS_NAME.JOIN_TO_SS_ORDER)}
					/>
					<JoinToSlidingSystemOrderManager
						onContinue={setSSOrderNumberToDetails}
						isOpen={modals[MODALS_NAME.JOIN_TO_SS_ORDER_MANAGER]}
						close={() => closeModalAndResetSelectedDetails(MODALS_NAME.JOIN_TO_SS_ORDER_MANAGER)}
					/>
					<InputExelModal isOpen={modals[MODALS_NAME.GIB_LAB]}
						close={() => toggleModal(MODALS_NAME.GIB_LAB, false)}
						openCLone={handleOpenCLone}/>
				</>}
			<EditCount isOpen={modals[MODALS_NAME.EDIT_COUNT]} close={() => closeModalAndResetSelectedDetails(MODALS_NAME.EDIT_COUNT)} selectedDetails={selectedDetails}/>
			<FilterSidebar
				setDetails={setDetails}
				isOpen={isSidebarOpen}
				initialDetails={initialDetails}
				setFilteredActive={setFilteredActive}
				close={toggleFilterSidebar}
			/>
			<div>
				{!empty(currentDetailsToShow) && empty(lastDetailWithImgPdf) && isLoading && currentDetailsToShow.map(detail =>
					<Contour
						key={detail.id}
						detail={detail}
						rects={detail.getCutoutsData()}
						holes={detail.holes}
					/>
				)}
			</div>
		</>
	);
};

export default Details;
