import {useEffect, useRef, useState} from "react";

import debounce from "lodash.debounce";
import CustomSelect from "../Select";
import Languages from "../../../translation/Languages";

const createHighlight = (text, searchTerm) => {
	const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
	const reg = new RegExp(escapedSearchTerm, "i");
	
	return text.replace(reg, (match) => `<span class="highlight">${match}</span>`);
};

const MIN_SEARCH_QUERY_LENGTH = 4;
const DEBOUNCE_VALUE = 300;
export const DebouncedSearch = ({
																									 placeholder,
																									 isLoading,
																									 hasMore = false,
																									 defaultValue,
																									 id,
																									 handleSearch,
																									 options,
																									 onChange,
																									 resetOptions,
																									 isDisabled = false
																								 }) => {
	const [page, setPage] = useState(1);
	const [query, setQuery] = useState("");
	const [isSearched, setIsSearched] = useState(false);
	const handleSearchDebounced = useRef(
		debounce((searchText, page) => {
			setIsSearched(true);
			return handleSearch({ query: searchText, page });
		}, DEBOUNCE_VALUE)
	).current;
	
	const handleInputChange = (inputText, meta) => {
		if (!["input-blur", "menu-close"].includes(meta.action)) {
			setQuery(inputText);
			setPage(1);
			resetOptions();
		}
	};
	
	useEffect(() => {
		if (query.length >= MIN_SEARCH_QUERY_LENGTH) {
			handleSearchDebounced(query, page);
		}
	}, [query, page]);
	
	const loadMoreOptions = () => {
		if (hasMore) {
			setPage((prevPage) => prevPage + 1);
		}
	};
	
	const onChangeOption = (newValue) => {
		onChange(newValue);
		
		if (!newValue) {
			resetOptions();
		}
	};
	
	const noOptionsMessage = ({ inputValue }) => {
		if (!inputValue.trim() || !isSearched) {
			return null;
		}
		return Languages.getTranslation("not-found", true);
	};
	
	const formatOptionLabel = ({ label }, { inputValue }) => (
		<span dangerouslySetInnerHTML={{ __html: createHighlight(label, inputValue) }} />
	);
	
	return (
		<CustomSelect
			inputId={id}
			name="search"
			isClearable
			isSearchable
			defaultValue={defaultValue}
			options={options}
			isDisabled={isDisabled}
			isLoading={isLoading}
			onMenuScrollToBottom={loadMoreOptions}
			placeholder={placeholder}
			formatOptionLabel={formatOptionLabel}
			inputValue={query}
			filterOption={() => true}
			onInputChange={handleInputChange}
			onChange={onChangeOption}
			loadingMessage={() => Languages.getTranslation("search-going", true)}
			components={{
				DropdownIndicator: () => null,
				LoadingIndicator: () => null
			}}
			noOptionsMessage={noOptionsMessage}
		/>
	);
};
