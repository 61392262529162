const User = ({ user }) => {
  return user.user.user
    ? user.user.user.payload.user
    : null;
};

const permissions = ({ user }) => {
  return user.user.user ? user.user.user.payload.user.permissions : []
}

export default {
  User,
  permissions
};
