import Helpers from "./Helpers";
import {Group, Shape, Mesh, ExtrudeGeometry, BufferGeometry, ShapeGeometry} from "three";
import {empty, isset} from "../../helpers/helper";
export default class CutOut {
	cutOuts = [];
	_contour = [];
	_shapes = [];
	_h;
	_l;
	_w;
	side;
	bevels = [];
	_extrudeSettings = {
		steps: 1,
		depth: 0,
		bevelEnabled: false,
	}
	holesShapes = []

	constructor({h, l, w, contour, shapes, cutOuts, side, bevels}) {
		this._contour = contour;
		this.side = side;
		this.bevels = bevels;

		if(side === 'front') {
			this.cutOuts = cutOuts
				.map(el => {
					return el.mainElement
				})
				.filter(el =>{
					return el.side !== 'back' || el.fullDepth || el.depth + el.multiplicity === w
				});
			this._shapes = shapes.filter(el => el.side !== 'back' || el.fullDepth || el.depth + el.multiplicity === w);
		}	else {
			this.cutOuts = cutOuts
				.map(el => {
					return el.mainElement
				})
				.filter(el => {
					return el.side === 'back' || el.fullDepth || el.depth + el.multiplicity === w
				});
			this._shapes = shapes.filter(el => {
				return el.side === 'back' || el.fullDepth || el.depth + el.multiplicity === w
			});
		}

		this.h = h;
		this.l = l;
		this.w = w;
		if(!empty(this.bevels)) {
			this.addBevels();
		}
		this.addShapesCutOuts();
	}

	addShapesCutOuts() {
		this.cutOuts = this.cutOuts.map(cut => {
			return cut.cutOut
		})
		this._shapes.forEach(shape => {
			const cutout = shape.cutOut;
			if(cutout.type === 'cutout') {
				this.cutOuts.push(cutout);
			}
		})
	}

	addBevels() {
		this.bevels.forEach(bevel => {
			this.holesShapes.push(bevel.cutOut.topShape)
		})
	}

	get h() {
		return this._h;
	}
	set h(h) {
		this._h = h;
	}

	get l() {
		return this._l;
	}
	set l(l) {
		this._l = l;
	}

	get w() {
		return this._w;
	}
	set w(w) {
		this._w = w;
	}
	checkContourIntersection(cutOut, depth, side, ext = null, target = null) {
		let intersections = [];
		const intersectionsArray = [];
		let intersection;
		const _target = target || this._contour;
		_target.forEach((el, j) => {
			if (!el) {
				return
			}
			intersections = [];
			switch (el.type) {
				case 'line':
					if(['front', 'back'].includes(side)) {
						if (cutOut.some(el => el.cutOutType === "cutout")) {
							return [];
						}
						cutOut.forEach((cEl, i) => {
							switch (cEl.type) {
								case 'line':
									intersection = Helpers.findIntersection(
										{x: el.x1, y: el.y1},
										{x: el.x2, y: el.y2},
										{x: cEl.x1, y: cEl.y1},
										{x: cEl.x2, y: cEl.y2}
									)
									if(!empty(intersection)) {
										intersections.push({
											_el: i, ...intersection,
											crossed: j,
											parentEl: el.parentIndex,
											parentCutout: el.originalIndex
										})
									}
									break
								case 'arc':
								// intersection = Helpers.findIntersectionOfSegmentAndArc(
								// 	{x: el.x1, y: el.y1},
								// 	{x: el.x2, y: el.y2},
								// 	{x: cEl.xc, y: cEl.yc},
								// 	cEl.r,
								// 	cEl.startAngle,
								// 	cEl.endAngle
								// )

							}
							// if(!empty(intersection)) {
							// 	if (!isset(cEl.holes)) {
							// 		cEl.holes = [];
							// 	}
							// 	cEl.holes.push(intersection)
							// }
						})
					}
					break
				case 'arc':
					// cutOut.forEach((cEl, i) => {
					// 	switch (cEl.type) {
					// 		case 'line':
					// 			intersection = Helpers.findIntersectionOfSegmentAndArc(
					// 				{x: cEl.x1, y: cEl.y1},
					// 				{x: cEl.x2, y: cEl.y2},
					// 				{x: el.xc, y: el.yc},
					// 				el.r,
					// 				el.startAngle,
					// 				el.endAngle
					// 			)
					// 			if(!empty(intersection)) {
					// 				intersections.push({_el: i, ...intersection})
					// 			}
					// 			break
					// 		case 'arc':
					// 			intersection = Helpers.calculateArcIntersections(
					// 				{x: cEl.xc, y: cEl.yc},
					// 				cEl.r,
					// 				cEl.startAngle,
					// 				cEl.endAngle,
					// 				{x: el.xc, y: el.yc},
					// 				el.r,
					// 				el.startAngle,
					// 				el.endAngle
					// 			)
					// 			if(!empty(intersection)) {
					// 				intersections.push({_el: i, ...intersection})
					// 			}
					// 	}
					// })

			}
			if(!empty(intersections) && !ext) {
				// const cropedElementsIndexes = intersections.map(inter => inter._el);
				// const cropedElements = cutOut.filter((el, i) => cropedElementsIndexes.includes(i));
				// intersections.forEach(int => {
				// 	const cutOutElement = cutOut[int._el];
				// 	if(cutOutElement.x1 === cutOutElement.x2) {
				// 		if(int.y > cutOutElement.y1) {
				// 			cutOutElement.y2 = int.y
				// 		} else {
				// 			cutOutElement.y1 = int.y
				// 		}
				// 	} else {
				// 		if(cutOutElement.x1 > cutOutElement.x2) {
				// 			if(int.x <  cutOutElement.x1) {
				// 				cutOutElement.x1 = int.x
				// 			} else {
				// 				cutOutElement.x2 = int.x
				// 			}
				// 		} else {
				// 			if(int.x <  cutOutElement.x2) {
				// 				cutOutElement.x2 = int.x
				// 			} else {
				// 				cutOutElement.x1 = int.x
				// 			}
				// 		}
				// 	}
				// })
				if(empty(target)) {
					// if(!isset(el.holes)) {
					// 	el.holes = [];
					// }
					el.holes.push({
						depth : depth,
						side: side,
						ext: ext,
						intersections: intersections.map(int => { return {x: int.x, y: int.y} }),
					})
				} else {
					intersectionsArray.push(intersections)
				}
			}
		})
		return intersectionsArray;
	}

	checkCutOutsIntersections(cutOut, _i, side) {
		let isIntersect = false;
		if(!['front', 'back'].includes(side)) {
			return false
		}
		if(empty(this.holesShapes)) {
			this.holesShapes.push(cutOut)
		} else {
			let intersections = []
			this.holesShapes.forEach((holeShape, i) => {
				const intersection = this.checkContourIntersection(cutOut, null, side, null, holeShape);
				if(!empty(intersection)) {
					if(intersection.length % 2 === 0) {
						intersections.push({index: i, globalIndex: _i, intersection: [...intersection]})
					}
				}
			})
			if (!empty(intersections)) {
				isIntersect = true;
				let addedItems = 0;
				intersections.forEach((int, ind) => {

					const targetShape = [...this.holesShapes[int.index]];
					let current;

					if(int.intersection.length % 2 === 0) {
						try {
							int.intersection.forEach((inter, i) => {
								if(inter.length === 2) {
									const holes = {
										[inter[0]._el]: [],
										[inter[1]._el]: []
									}
									inter.forEach(el => {
										holes[el._el].push({x: el.x, y: el.y})
									})
									this.cutOuts[inter[0].parentEl].elements[inter[0].parentCutout].holes.push({
										depth : this.cutOuts[_i].depth > this.cutOuts[inter[0].parentEl].depth ? this.cutOuts[inter[0].parentEl].depth : this.cutOuts[_i].depth ,
										side: side,
										ext: null,
										intersections: inter.map(el => { return {x: el.x, y: el.y} })
									})
									for(let ind in holes) {
										const targetEl = this.cutOuts[_i].elements[ind];
										if(empty(targetEl.holes)) {
											this.cutOuts[_i].elements[ind].holes.push({
												depth : this.cutOuts[inter[0].parentEl].depth > this.cutOuts[_i].depth ? this.cutOuts[_i].depth : this.cutOuts[inter[0].parentEl].depth ,
												side: side,
												index: inter[0].parentEl,
												ext: null,
												intersections: holes[ind]
											})
										} else {
											this.cutOuts[_i].elements[ind].holes.forEach(h => {
												if(h.index === inter[0].parentEl) {
													h.intersections.push(...holes[ind])
												}
											})
										}
									}

									const addedElements = []
									if(inter.length === 2) {
										current = inter[0].crossed + addedItems;

										const cutOutCopy = JSON.parse(JSON.stringify(cutOut));
										// switch (hole)
										// const isStart = cutOutCopy[inter[0]._el].x1 === cutOutCopy[inter[0]._el].x2 &&
										let _cutOut;
										if(i === 0) {
											_cutOut = (cutOutCopy[inter[0]._el].x1 === cutOutCopy[inter[0]._el].x2)
												? cutOutCopy.slice(inter[0]._el, inter[1]._el + 1)
												: Helpers.getContourEnd(cutOutCopy, inter[1]._el, inter[0]._el + 1)
											addedElements.push({...targetShape[current], x2: inter[0].x, y2: inter[0].y});

											if(_cutOut[0].x1 === _cutOut[0].x2) {
												_cutOut[0].x1 =  inter[0].x;
												_cutOut[0].y1 =  inter[0].y;
												_cutOut[_cutOut.length - 1].x2 = inter[1].x;
												_cutOut[_cutOut.length - 1].y2 = inter[1].y;
											} else if(_cutOut[0].y1 === _cutOut[0].y2) {
												_cutOut[0].x1 =  inter[1].x;
												_cutOut[0].y1 =  inter[1].y;
												_cutOut[_cutOut.length - 1].x2 = inter[0].x;
												_cutOut[_cutOut.length - 1].y2 = inter[0].y;
											}
											addedElements.push(..._cutOut);
											addedElements.push({...targetShape[current], x1: inter[1].x, y1: inter[1].y})
										} else {
											_cutOut = (cutOutCopy[inter[0]._el].x1 === cutOutCopy[inter[0]._el].x2)
												? Helpers.getContourEnd(cutOutCopy, inter[1]._el, inter[0]._el + 1)
												: cutOutCopy.slice(inter[0]._el, inter[1]._el + 1);
											addedElements.push({...targetShape[current], x2: inter[1].x, y2: inter[1].y});
											if(_cutOut[0].x1 === _cutOut[0].x2) {
												_cutOut[0].x1 =  inter[1].x;
												_cutOut[0].y1 =  inter[1].y;
												_cutOut[_cutOut.length - 1].x2 = inter[0].x;
												_cutOut[_cutOut.length - 1].y2 = inter[0].y;

											} else if(_cutOut[0].y1 === _cutOut[0].y2) {
												_cutOut[0].x1 =  inter[0].x;
												_cutOut[0].y1 =  inter[0].y;
												_cutOut[_cutOut.length - 1].x2 = inter[1].x;
												_cutOut[_cutOut.length - 1].y2 = inter[1].y;
											}
											addedElements.push(..._cutOut);
											addedElements.push({...targetShape[current], x1: inter[0].x, y1: inter[0].y})
										}
									} else {

									}

									addedItems += addedElements.length - 1;
									targetShape.splice(current, 1, ...addedElements)
								}

							})
						} catch (e) {
							console.log(e)
						}
					} else {
						if(ind === 0) {

						} else {

						}
					}
					this.holesShapes.splice(int.index, 1, targetShape)
				})
			} else {
				this.holesShapes.push(cutOut)
			}
		}
		return isIntersect;
	}
	createCutOutShape(cutOut, i) {
		const addCutOut = () => {
			const shape = new Shape();
			shape.moveTo(cutOut.x, cutOut.y);

			const cutOutElements = Helpers.fixUShapePoints(cutOut, null, null, i);
			this.checkCutOutsIntersections(cutOutElements, i, cutOut.side);
			this.checkContourIntersection(cutOutElements, cutOut.depth, cutOut.side, cutOut.ext);

			cutOutElements.forEach(el => {
				switch (el.type) {
					case 'line':
						shape.moveTo(el.x1 - this.l / 2, el.y1 - this.h / 2)
						shape.lineTo(el.x2 - this.l / 2, el.y2 - this.h / 2);
						break
					case 'arc':
						shape.absarc(el.xc - this.l / 2, el.yc - this.h / 2, el.r, el.startAngleRad, el.endAngleRad, el.dir);
						break
				}
			})
			cutOut.shape = shape;

			return cutOut;
		}
		return addCutOut();
	}

	createBevelShape(bevel) {
		const {cutOut} = bevel
		const shape = new Shape();
		shape.moveTo(cutOut.x, cutOut.y);
		cutOut.elements.forEach(el => {
			switch (el.type) {
				case 'line':
					shape.moveTo(el.x1 - this.l / 2, el.y1 - this.h / 2)
					shape.lineTo(el.x2 - this.l / 2, el.y2 - this.h / 2);
					break
				case 'arc':
					shape.absarc(el.xc - this.l / 2, el.yc - this.h / 2, el.r, el.startAngleRad, el.endAngleRad, el.dir);
					break
			}
		})
		if(!empty(bevel.ownHoles)) {
			bevel.ownHoles.forEach(h => {
				const hShape = new Shape();
				switch (bevel.edgeSide){
					case 'left':
						hShape.moveTo(-this.l / 2, h.start - this.h/2)
						hShape.lineTo(bevel.sideC - this.l / 2 , h.start - this.h/2);
						hShape.lineTo(bevel.sideC - this.l / 2, h.end - this.h/2);
						hShape.lineTo(-this.l / 2, h.end -this.h/2);
						break;
					case 'top':

						hShape.moveTo(h.start - this.l / 2,  (this.h - bevel.sideC) - this.h / 2);
						hShape.lineTo(h.start - this.l / 2, this.h / 2);
						hShape.lineTo( h.end - this.l / 2, this.h / 2);
						hShape.lineTo(h.end - this.l / 2, (this.h - bevel.sideC) - this.h / 2);
						break;
					case 'right':
						hShape.moveTo((this.l -bevel.sideC)- this.l/2,  ( h.start) - this.h/2);
						hShape.lineTo(this.l/2 , ( h.start) - this.h/2 );
						hShape.lineTo(this.l / 2 ,  ( h.end) - this.h/2);
						hShape.lineTo( (this.l -bevel.sideC) - this.l/2, (h.end) - this.h/2);

						break;
					case 'bottom':
						hShape.moveTo(h.start - this.l / 2, -this.h/2);
						hShape.lineTo(h.start - this.l / 2, bevel.sideC - this.h/2);
						hShape.lineTo(h.end - this.l / 2, bevel.sideC - this.h/2);
						hShape.lineTo(h.end - this.l / 2, -this.h/2);
						break;
			}

				shape.holes.push(hShape)
			})
		}
		cutOut.shape = shape;

		return cutOut;
	}

	build() {
		const cutOuts = [...this.cutOuts];
		const _cutOuts = [];
		let i = 0;
		const addCutOut = () => {
			const cutOut = cutOuts.shift();
			if(!empty(cutOut)) {
				_cutOuts.push(this.createCutOutShape(cutOut, i));
				i++
				addCutOut();
			} else {
				return _cutOuts
			}
		}
		addCutOut();
		this.cutOuts.forEach(el => {
			this.createCutOutMesh(el)
		})
		this.bevels.forEach(el => {
			const cutOut = this.createBevelShape(el);
			this.createCutOutMesh(cutOut)
			_cutOuts.push(cutOut);
		})
		return _cutOuts
	}

	createCutOutMesh(cutOut) {
		if(!(this.side === 'back' && (cutOut.depth + cutOut.multiplicity === this.w || cutOut.fullDepth )) || cutOut.subType === 'bevel') {
			let cutOutElements = cutOut.subType === 'bevel' ? cutOut.elements : Helpers.fixUShapePoints(cutOut);
			let mesh, geometry;
			const shape = cutOut.shape;
			switch (cutOut.subType) {
				case 'groove':
					if(cutOutElements.length === 4) {
						if(cutOut.direction === 'hor') {
							cutOutElements = cutOutElements.filter(el => {
								if(el.x1 === 0 && el.x2 === 0 || el.x1 === this.l && el.x2 === this.l) {
									return false
								}
								return true;
							})
						} else {
							cutOutElements = cutOutElements.filter(el => {
								if(el.y1 === 0 && el.y2 === 0 || el.y1 === this.h && el.y2 === this.h) {
									return false
								}
								return true
							})
						}
					}
					geometry = new ShapeGeometry(shape, 4);
					mesh = new Mesh(geometry, Helpers.getMaterial3D(cutOut.color));
					cutOutElements.forEach((el, i) => {
						let sideMesh;
						switch (el.type) {
							case 'line':
								let side = 'left';
								if(el.y1 === el.y2) {
									side = (i < 2) ? 'top' : 'bottom';
								} else if(i > 0) {
									side = 'right';
								}
								sideMesh = Helpers.getLineMesh(
									{...el, holes: el.holes, w: cutOut.depth},
									cutOut.color,
									side,
									true
								)
								sideMesh.position.x = this.l - (this.l - el.x1);
								sideMesh.position.y = this.h - (this.h - el.y1);
								sideMesh.rotation.z = Math.atan2(el.y2-el.y1, el.x2-el.x1) - Math.PI / 2
								sideMesh.position.z = this.w - (this.w - cutOut.depth);
								break
							case 'arc':
								sideMesh = Helpers.getArcMesh(
									{...el, w: cutOut.depth},
									cutOut.color,
									null,
									true
								)
						}
						sideMesh.position.x -= this.l / 2
						sideMesh.position.y -= this.h / 2
						mesh.add(sideMesh)
					})
					mesh.updateMatrix()
					switch (cutOut.side) {
						case 'front':
							mesh.position.z = this.w / 2 - cutOut.depth;
							break;
						case 'back':
							mesh.position.z = this.w / 2 - cutOut.depth - cutOut.multiplicity;
							break
						case 'left':
							mesh.rotation.y = - Math.PI / 2;
							mesh.position.x =  -this.l / 2 + cutOut.depth;
							mesh.position.z =  this.l / 2 - this.w / 2 - (this.w - cutOut.z * 2 - cutOut.width);
							break
						case 'right':
							mesh.rotation.y = Math.PI / 2;
							mesh.position.y = this.h - cutOut.height - cutOut.y * 2;
							mesh.position.x =  this.l / 2 - cutOut.depth;
							mesh.position.z =  -this.l / 2 + this.w / 2;
							break
						case 'top':
							mesh.rotation.x =  -Math.PI / 2;
							mesh.rotation.z = Math.PI / 2
							mesh.position.y = this.h / 2 - cutOut.depth;
							mesh.position.z =  -this.l / 2 + this.w / 2;
							mesh.position.x =  -this.l / 2 + cutOut.y * 2 - (this.h / 2 - cutOut.width);
							break
						case 'bottom':
							mesh.rotation.x =  Math.PI / 2;
							mesh.rotation.z = Math.PI / 2;
							mesh.position.z =  this.l / 2 - this.w / 2 - (this.w - cutOut.z * 2 - cutOut.height);
							mesh.position.y = -this.h / 2 + cutOut.depth;
							mesh.position.x =   (this.l / 2 - this.h / 2);
							break
					}

					break
				case 'rabbet':
					geometry = new ShapeGeometry(shape, 4);
					mesh = new Mesh(geometry, Helpers.getMaterial3D(cutOut.color));
					let side = 'left';
					if(cutOutElements.length === 4) {
						switch (cutOut.edgeSide) {
							case 'left':
								side = 'right';
								cutOutElements = [cutOutElements[2]]
								break
							case 'right':
								side = 'left';
								cutOutElements = [cutOutElements[0]]
								break
							case 'top':
								side = 'bottom'
								cutOutElements = [cutOutElements[3]]
								break
							case 'bottom':
								side = 'top'
								cutOutElements = [cutOutElements[1]]
								break
						}
					}
					cutOutElements.forEach((el, idx) => {
						if (cutOut.edgeSide === 'left' && idx === 0 && cutOut.height !== this.l) {
							return null
						}
						let sideMesh;
						switch (el.type) {
							case 'line':
								sideMesh = Helpers.getLineMesh(
									{...el, holes: el.holes, w: cutOut.depth},
									cutOut.color,
									side,
									true
								)
								sideMesh.position.x = this.l - (this.l - el.x1);
								sideMesh.position.y = this.h - (this.h - el.y1);
								sideMesh.rotation.z = Math.atan2(el.y2-el.y1, el.x2-el.x1) - Math.PI / 2
								sideMesh.position.z = this.w - (this.w - cutOut.depth);
								break
							case 'arc':
								sideMesh = Helpers.getArcMesh(
									{...el, w: cutOut.depth},
									cutOut.color,
									null,
									true
								)
						}
						sideMesh.position.x -= this.l / 2
						sideMesh.position.y -= this.h / 2
						mesh.add(sideMesh)
					})
					mesh.position.z = this.w / 2 - cutOut.depth;
					break
				case 'bevel':
					geometry = new ShapeGeometry(shape, 4);
					mesh = new Mesh(geometry, Helpers.getMaterial3D(cutOut.color ?? Helpers.bevelColor));
					let angle = 0
					switch (cutOut.edgeSide) {
						case 'left':
							angle = cutOut.angle - Math.PI / 2;
							mesh.rotation.y = angle
							mesh.position.x =  -this.l / 2 + (this.l / 2) * Math.cos(angle);
							mesh.position.z =  -this.w / 2 + (this.l / 2) * Math.cos(cutOut.angle) + cutOut.z;
							break
						case 'right':
							angle = 3 * Math.PI / 2 - cutOut.angle;
							mesh.rotation.y = angle
							mesh.position.x =  this.l / 2 + (this.l / 2) * Math.sin(cutOut.angle) - cutOut.width;
							mesh.position.z =  this.w / 2 - (this.l / 2) * Math.cos(cutOut.angle);
							break
						case 'top':
							angle = cutOut.angle - Math.PI / 2;
							mesh.rotation.x = angle;
							mesh.position.y =  this.h / 2 - (this.h / 2) * Math.cos(angle);
							mesh.position.z = -this.w / 2 - (this.h / 2) * Math.sin(angle) + cutOut.z;
							break
						case 'bottom':
							const z = cutOut.side === 'front' ? cutOut.z : 0;
							angle = 3 * Math.PI / 2 - cutOut.angle;
							mesh.rotation.x = angle;
							mesh.position.y = -this.h / 2 + (this.h / 2) * Math.cos(angle) + cutOut.height
							mesh.position.z = this.w / 2 + (this.h / 2 ) * Math.sin(angle);
							break
					}
					break;
				default:
					geometry = new ShapeGeometry(shape, 4);
					mesh = (cutOut.depth + cutOut.multiplicity === this.w) ? new Mesh(geometry, Helpers.getMaterial3DTransparent()) : new Mesh(geometry, Helpers.getMaterial3D(cutOut.color));
					cutOutElements.forEach(el => {
						let sideMesh;
						switch (el.type) {
							case 'line':
								sideMesh = Helpers.getLineMesh(
									{...el, holes: el.holes, w: cutOut.depth},
									cutOut.color,
									null,
									true
								)
								sideMesh.position.x = this.l - (this.l - el.x1);
								sideMesh.position.y = this.h - (this.h - el.y1);
								sideMesh.rotation.z = Math.atan2(el.y2-el.y1, el.x2-el.x1) - Math.PI / 2
								sideMesh.position.z = this.w - (this.w - cutOut.depth);
								break
							case 'arc':
								sideMesh = Helpers.getArcMesh(
									{...el, w: cutOut.depth},
									cutOut.color,
									null,
									true
								)
						}
						sideMesh.position.x -= this.l / 2
						sideMesh.position.y -= this.h / 2
						mesh.add(sideMesh)
					})
					mesh.position.z = !empty(cutOut.z) ? cutOut.z - this.w / 2 : cutOut.depth === this.w ? - this.w / 2 : this.w / 2 - cutOut.depth
					break
			}
			mesh.userData = {
				pId: cutOut.id,
				meshType: cutOut.subType,
				type: cutOut.type,
				proc: cutOut.proc,
			}
			cutOut.mesh = mesh
		}
	}

	createFaceShape(rect, inside = true) {
		const shapes = [];
		this.holesShapes.forEach(shape => {
			let minX=this.l/2,
				minY=this.h/2,
				maxX=-this.l/2,
				maxY=-this.h/2;
			const _shape = new Shape();
			shape.forEach(el => {
				switch (el.type) {
					case 'line':
						const x1 = el.x1 - this.l / 2,
							x2 = el.x2 - this.l / 2,
							y1 = el.y1 - this.h / 2,
							y2 = el.y2 - this.h / 2;
						_shape.moveTo(x1, y1)
						_shape.lineTo(x2, y2);
						minX = Math.min(minX, x1, x2);
						minY = Math.min(minY, y1, y2);
						maxX = Math.max(maxX, x1, x2);
						maxY = Math.max(maxY, y1, y2);
						break
					case 'arc':
						const xc = el.xc - this.l / 2,
							yc = el.yc - this.h / 2;
						_shape.absarc(xc, yc, el.r, el.startAngleRad, el.endAngleRad, el.dir);
						minX = Math.min(minX, xc - el.r);
						minY = Math.min(minY, yc - el.r);
						maxX = Math.max(maxX, xc + el.r);
						maxY = Math.max(maxY, yc + el.r);
						break
				}
			})
			if(inside) {
				if(Helpers.rectanglesAreNested(
					{
						x1: minX, y1: maxY,
						x2: maxX, y2: maxY,
						x3: maxX, y3: minY,
						x4: minX, y4: minY
					},
					{
						x1: rect.x2, y1: rect.y2,
						x2: rect.x3, y2: rect.y3,
						x3: rect.x4, y3: rect.y4,
						x4: rect.x1, y4: rect.y1
					}
				)) {
					shapes.push(_shape);
				}
			} else {
				if(!Helpers.rectanglesAreNested(
					{
						x1: minX, y1: maxY,
						x2: maxX, y2: maxY,
						x3: maxX, y3: minY,
						x4: minX, y4: minY
					},
					{
						x1: rect.x2, y1: rect.y2,
						x2: rect.x3, y2: rect.y3,
						x3: rect.x4, y3: rect.y4,
						x4: rect.x1, y4: rect.y1
					}
				)) {
					shapes.push(_shape);
				}
			}
		})
		return shapes;
	}
}