import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import "./style.scss";
import Project from "models/Project";
import { projectSelectors } from "redux/project";
import { useSelector } from "react-redux";
import Languages from "../../../../translation/Languages";
import Loader from "../../Loader";
import {empty} from "../../../../helpers/helper";
import '../../../../components/CreateProject/Materials/style.scss'
import {tempDetailOperations} from "redux/tempDetail";
import {userSelectors} from "redux/user";
import {formatPrice} from '../../Price/Price'
import Requests from "../../../../api/API"
import moreDetailsCost from '../../../../media/moreDetailsCost.svg'
import CloseBtn from "../../Buttons/CloseBtn";
import {ModalFooter, ModalHeader} from "../index";

const calcProject = () => {

  const user = useSelector(userSelectors.User);

  const dispatch = useDispatch();
  const [load, setLoad] = useState(true)
  const [costProject, setCostProject] = useState(null);
  const project = useSelector(projectSelectors.getConstruction);
  const [materials, setMaterials] = useState(null);
  const [services, setServices] = useState(null);
  const [cost, setCost] = useState(null);
  const [furniture, setFurniture] = useState(null);
  const backgroundCost = !empty(furniture) ? "#F3F3F3" : "#fff";

  const getCostProject = async () => {
      let userData
    setLoad(true);
      try{

    if (project._projectData.owner && user.role.includes('manager')) {
    	const response = await Requests.Auth.searchUserById(project._projectData.owner)
    	userData = response.data;
    } else {
    	userData = user
    }
    const data = await Project.calcProjectModel(dispatch, userData);
    setLoad(false);
    if (!empty(data)) {
         setCostProject(data)
         const materialsAll = data.filter(({typeNomenclature}) => typeNomenclature === "material")
         const servicesAll = data.filter(({typeNomenclature}) => typeNomenclature === "service")
         const costAll = data.filter(({typeNomenclature}) => typeNomenclature === "ordersum")
        const furniture = data.filter(({typeNomenclature}) => typeNomenclature === "furniture")

         setMaterials(materialsAll)
         setServices(servicesAll)
         setCost(costAll)
         setFurniture(furniture)
         localStorage.setItem('data', JSON.stringify({ data: data, projectId: project.projectData?.id, user }));
    }
      }
    catch (err){
        setLoad(false)
    }
  };

  useEffect(() => {
    getCostProject();
  }, []);

  const close = () => {
    dispatch(tempDetailOperations.setTempProject({}))
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  const getCost = (items) => {
    if(!empty(materials)){
      return formatPrice(items?.reduce((acc, item) => acc + item.sum, 0))
    }
  }

  return (
    <>
      <div className="modal-calc-project modal__wrapper">
				<ModalHeader className="modal-calc-project__title">
					<h3>{Languages.getTranslation('project-calculation', true)}: {project.projectData?.id}</h3>
					<CloseBtn handler={close}/>
				</ModalHeader>
				<div className="modal-calc-project__body">
					{load ? <div style={{marginTop: '20px'}}><Loader /></div>  :
                  empty(materials) ? <h1  className='align-center' >
                          {Languages.getTranslation('error-project-not-calc', true)}
                      </h1> :
                      <>
                      <div className="modal-calc-project__body-table">
                        <div className="modal-calc-project__body-table">
                          <div className="modal-calc-project__body-table-title">
                            <span className="modal-calc-project__body-table-item-text">
                             {Languages.getTranslation('client', true)}: {user?.name},
                              </span>
                            <span className="modal-calc-project__body-table-item-text">+{user?.phone},</span>
                            <span className="modal-calc-project__body-table-item-text">{user?.email}</span>
                          </div>
                          <div className="modal-calc-project__body-table-item" style={{background: '#fff'}}>
                              <span className="modal-calc-project__body-table-item-text">
                                  {Languages.getTranslation('material-general', true)}
                              </span>
                            <div className="vertical-line"></div>

                            <span>
                                  {getCost(materials)} ₴
                            </span>
                          </div>

                          <div className="modal-calc-project__body-table-item">
                              <span className="modal-calc-project__body-table-item-text">
                                  {Languages.getTranslation('services-general', true)}
                              </span>
                            <div className="vertical-line"></div>

                            <span>
                                  {getCost(services)} ₴
                            </span>
                          </div>
                            {!empty(furniture) && (
                                <div className="modal-calc-project__body-table-item" style={{background: '#fff'}}>
                              <span className="modal-calc-project__body-table-item-text">
                                  {Languages.getTranslation('furniture-general', true)}
                              </span>
                                    <div className="vertical-line"></div>

                                    <span style={{color: '#A04382FF'}}>
                                        {getCost(furniture)} ₴
                            </span>
                                </div>
                            )}
                            {!empty(cost) && (
                                <div className="modal-calc-project__body-table-item" style={{background: backgroundCost}}>
                              <span className="modal-calc-project__body-table-item-text">
                                  {Languages.getTranslation('in-general', true)}
                              </span>
                                    <div className="vertical-line"></div>

                                    <span style={{color: '#A04382FF'}}>
                                  {formatPrice(cost[0]?.ordersum)} ₴
                            </span>
                                </div>
                            )}

                        </div>
                      </div>
                      </>
          }
        </div>
				<ModalFooter>
					<a target="_blank"
						 href={"/cost"} className="modal-calc-project__body-detail">
						<div
							className="modal-calc-project__body-detail-text"> {Languages.getTranslation('more-details', true)}</div>
						<img src={moreDetailsCost} alt='cost'/>
					</a>
				</ModalFooter>
			</div>
		</>
	);
};

export default calcProject;

