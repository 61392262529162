import './style.scss';
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import ahtung from '../../../../media/ahtuung.png'
import {Modal, ModalFooter} from "../index";

const AhtungModal = ({isOpen, setShowSubmitModal, setSelectedValue, selectType, submitModalText, setDeleteDetail}) => {

    const submit = () => {
        setSelectedValue(selectType)
        setShowSubmitModal(false)
    }

    const close = ()=>{

        if (setDeleteDetail){
            setDeleteDetail(null)
        }

        setShowSubmitModal(false)
    }

    return <Modal isOpen={isOpen}>
        <div className='ahtungModal modal__wrapper'>
					<div className="ahtungModal__body">
						<div className="ahtungModal__img">
							<img src={ahtung} width="88" height="70" alt="ahtung"/>
						</div>
						<h3 className="ahtungModal__body-decription-title">{Languages.getTranslation('warning', true)}</h3>
							<div className="ahtungModal__body-decription-body">
								<span>{Languages.getTranslation('ahtungModal_text', true)}</span>
								<div
									className="ahtungModal__body-decription-body_text">{Languages.getTranslation('ahtungModal_text_second', true)}</div>
								
								<ul className='ahtungModal__list'>
									<li className='ahtungModal__list-item'>{Languages.getTranslation('ahtung-text-one', true)}</li>
									<li className='ahtungModal__list-item'>{Languages.getTranslation('ahtung-text-two', true)}</li>
									<li className='ahtungModal__list-item'>{Languages.getTranslation('ahtung-text-three', true)}</li>
									<li className='ahtungModal__list-item'>{Languages.getTranslation('ahtung-text-four', true)}</li>
								</ul>
								{submitModalText}
								<br/>
								<div className='mt-5'>
									<b
										className='textPurpl'>{Languages.getTranslation('Substitution-of-material-in-the-project', true)}</b>
									{Languages.getTranslation('and', false)}
									<b className='textPurpl'>{Languages.getTranslation('replace-edge-project', true)}</b>
									<br/>
									{Languages.getTranslation('designer-Kronas-Master-3D', true)}
								</div>
						</div>
					</div>
					<ModalFooter className="ahtungModal__actions">
						<DefaultBtn
							title={Languages.getTranslation("cancel", true)}
							handler={close}
						/>
						<DefaultBtn
							title={Languages.getTranslation("submit", true)}
							handler={submit}
						/>
					</ModalFooter>
        </div>
		</Modal>

}

export default AhtungModal