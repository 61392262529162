import {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Auth from "components/Auth";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import "./style.scss";
import {userSelectors} from "redux/user";
import Languages from "../../translation/Languages";
import angle from "../../media/angle.png";
import phone from "../../media/phone.svg";
import {empty} from "../../helpers/helper";
import KronasLinks from "../KronasLInks";
import telegram from "../../media/header/telegram.svg"
import youtube from "../../media/header/youtube.svg"
import {Langs} from "../UI/Langs";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";
import {Tooltip} from "../UI/Tooltip";

const AppHeader = () => {
	const construction = useSelector(projectSelectors.getConstruction);
	const [isOpen, setIsOpen] = useState(false)
	const user = useSelector(userSelectors.User);
	const navigate = useNavigate()
	const permissions = useSelector(userSelectors.permissions);
	const detailR = useSelector(projectSelectors.getDetail);
	const dispatch = useDispatch()
	useUpdateTranslations();

  const registration =() => {
	  document.location=`${process.env.PERSONAL_ACCOUNT_URL}/#/auth/registration?previousUrl=${window.location.href}`;
  }
	const login = () => {
		document.location=`${process.env.PERSONAL_ACCOUNT_URL}?previousUrl=${window.location.href}`
	}

	const linkResp = "link";
	
	const closeInfoTooltip = () => {
		setIsOpen(false);
	}

	const handleToDetail = () => {
		if (detailR !== null) {
			const detail = null
			dispatch(projectOperations.setDetail({detail}))
			dispatch(projectOperations.setIdDetailFromScene(detailR.id))
		}
	}
	
	return (
		<header className='header'>
			<KronasLinks />
			<div className="header__container">
				{!empty(user) ? (

				<div className="header__links">
					<NavLink
						className={({isActive}) =>
							(isActive || window.location.href.indexOf("processes") > -1
								? `active ${linkResp}`
								: `${linkResp}`) +
							(construction &&
							!construction.details.length &&
							!construction.materials.length &&
							!construction.edges.length
								? " disabled"
								: "")
						}
						to="/details"
						onClick={handleToDetail}
					>
						{Languages.getTranslation("details", true)}
					</NavLink>
					{permissions.includes('KM_3dConstructor') ? <NavLink className={`${linkResp}`} to="/constructor">Constructor</NavLink> : null}
					{permissions.includes('KM_showProjectList') ? <NavLink
						className={({isActive}) =>
							isActive ? `active ${linkResp}` : `${linkResp}`
						}
						to="/projects"
					>
						{Languages.getTranslation("projects", true)}
					</NavLink> : <></>}
					{permissions.includes('KM_importProject') ?
						<NavLink
							className={({isActive}) =>
								isActive ? `active ${linkResp}` : `${linkResp}`
							}
							to="/import-project"
						>
							{Languages.getTranslation("Import", true)}
						</NavLink>: <></>}
					<div data-tooltip-id="contact-tooltip" className={`btn_contact link ${isOpen && 'active'}`}>
						{Languages.getTranslation("tex-support", true)}
						<img className={isOpen ? 'rotate' : 'rotateBack'} style={{marginLeft: 14}} src={angle} alt="angle"/>
					</div>
					<Tooltip openOnClick clickable isOpen={isOpen} setIsOpen={(value) => setIsOpen(value)} id="contact-tooltip" place="bottom-center" className="contact_tooltip">
						<div className="contact_tooltip__container">
							<span>{Languages.getTranslation('kronas-master-3d', true)}</span>
							<div className="contact_tooltip__container_questions">
								({Languages.getTranslation("questions-km3d", true)})
							</div>
							<a href="tel:0800300538" className='contact_tooltip_call' onClick={closeInfoTooltip}>
								<img src={phone} alt="phone"/> 0 800 300 538
							</a>
							<a href="tel:0673094095" className='contact_tooltip_call' onClick={closeInfoTooltip}>
								<img src={phone} alt="phone"/> (067) 309 40 95
							</a>
							<a href="tel:0443211440" className='contact_tooltip_call' onClick={closeInfoTooltip}>
								<img src={phone} alt="phone"/> (044) 321 14 40
							</a>
							<div className="contact_tooltip__time">
								<span className="contact_tooltip__time-item">
									{Languages.getTranslation("time-support-weekdays", true)}
								</span>
								<span className="contact_tooltip__time-item">
										{Languages.getTranslation("time-support-weekend", true)}
								</span>
							</div>
							<div className="contact_tooltip__container-grey-line"/>
							
							<div className="contact_tooltip__container_support"
									 onClick={() => {
										 navigate('/support');
										 closeInfoTooltip();
									 }}>
								{Languages.getTranslation("write-us", true)}</div>
							<a href="https://kronas.com.ua/ua/kronas-master-training"
								 className='contact_tooltip_call' onClick={closeInfoTooltip} target="_blank">
								<span>{Languages.getTranslation("study-enrollment", true)}</span>
							</a>
						</div>
						<div className="contact_tooltip__container">
							<div className='contact_tooltip_title'>Call-центр</div>
							<div className="contact_tooltip__container_questions">
								({Languages.getTranslation("general-questions", true)})
							</div>
							<div className="contact_tooltip__time">
								<span className="contact_tooltip__time-item">
									{Languages.getTranslation("time-support-weekdays-call-center", true)}
								</span>
								<span className="contact_tooltip__time-item">
										{Languages.getTranslation("time-support-weekend-callcenter", true)}
								</span>
							</div>
							<a href="tel:0800305335" className='contact_tooltip_call' onClick={closeInfoTooltip}>
								<img src={phone} alt="phone"/> 0 800 305 335
							</a>
						</div>
					</Tooltip>
				</div>
					) :
					(
						<div className="header__registration">
							<div onClick={registration}
								 className="header__registration-text">{Languages.getTranslation('registration', true)}</div>
							|
							<div onClick={login}
								 className="header__registration-text">{Languages.getTranslation('login', true)}</div>
						</div>
					)}
				{permissions.includes('KM_showLangSwitch') && <Langs/>}
				<div className="header__links">
					{!empty(user) && (
					<>
				      <a className="header__links-link" target="_blank" href="https://www.youtube.com/watch?v=o8V28bwO4n0&list=PL4on7YuYKzf_PQwJ3zBqPwAROAm9Ufi1H">
					   <img src={youtube} alt="youtube" width="30px" height="30px"/>
				      </a>
				      <a className="header__links-link" target="_blank" href="https://t.me/kronasmaster3d">
					    <img src={telegram} alt="telegram" width="30px" height="30px"/>
				      </a>
					</>
					)}
				   <Auth/>
				</div>
			</div>
		</header>
	);
};

export default AppHeader;
