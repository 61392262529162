import Languages from "../../translation/Languages";

import "./styles.scss";

const SaleInfo = ({title, conditions, note}) => {
	return (
		<section className="sale-info">
			{title && <h2 className="sale-info__title">{title}</h2>}
			<div className="sale-info__list">
				{conditions.map((condition, index) => (
					<div key={index} className="sale-info__condition">
						<div className="sale-info__condition-icon">
							<img src={condition.img.src} width={condition.img.width} height={condition.img.height} alt={condition.descKey}/>
						</div>
						<div className="sale-info__condition-info">
							{condition.titleKey && <h3 className="sale-info__condition-title">
								{Languages.getTranslation(condition.titleKey)}
							</h3>}
							<p className="sale-info__condition-desc">
								{Languages.getTranslation(condition.descKey)}
							</p>
						</div>
					</div>
				))}
			</div>
			{note &&
				<div className="sale-info__note">{note}</div>}
		</section>
	)
}

export default SaleInfo;