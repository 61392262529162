import  {useEffect, useRef, useState} from "react";
import Requests from "api/API";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {empty} from "../../../../helpers/helper";

import CloseBtn from "components/UI/Buttons/CloseBtn";
import Languages from "translation/Languages";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";

import "./style.scss";
import Loader from "../../Loader";
import Errors from "../../../../models/Errors";
import {ModalFooter, ModalHeader} from "../index";

const _errors = new Errors([]);

const CatalogIcon = () => {
	return (<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="1.2414" y="0.459659" width="8.44608" height="8.44608" fill="#A14389" stroke="#A14389"
					strokeWidth="0.756233"/>
		<rect x="11.4523" y="0.459659" width="8.44608" height="8.44608" fill="#A14389" stroke="#A14389"
					strokeWidth="0.756233"/>
		<rect x="1.2414" y="10.6704" width="8.44608" height="8.44608" fill="#A14389" stroke="#A14389"
					strokeWidth="0.756233"/>
		<path
			d="M11.4523 10.6704H13.8083H16.0973H18.3863H19.8984V10.801V13.4266V16.0186V19.1164H17.3428H15.0537H12.7647H11.4523V18.8125V16.0186V13.5949V10.6704Z"
			fill="#A14389" stroke="#A14389" strokeWidth="0.756233"/>
	</svg>)
}

const AddCatalogModal = ({close, backClick}) => {
	const construction = useSelector(projectSelectors.getConstruction);
	
	const [hardware, setHardware] = useState([])
	const [selectedHardware, setSelectedHardware] = useState([])
	const [code, setCode] = useState("");
	const [isLoaded, setIsLoaded] = useState(false);
	const [debouncedValue, setDebouncedValue] = useState('');
	
	const [catalogList, setCatalogList] = useState([])
	const [step, setStep] = useState([{stepArticle: 270, stepN: 1, data: null}])
	
	const input = useRef(null);
	
	const searchCatalog = async (code) => {
		setIsLoaded(true)
		const request = await Requests.Materials.getFurnitureCatalog(code).catch(error =>
			_errors.setError(error.message, 'error', true))
		
		if (!empty(request)) {
			setIsLoaded(false)
			return request.data
		} else {
			setIsLoaded(false)
		}
    }

    useEffect(() => {
        searchCatalog(step[0].stepArticle).then(data => {
            setCatalogList(data)
            setStep([{stepArticle: 270, stepN: 1, data}])
        })
    }, [])

    const search = async (req, article) => {
        setHardware([]);
        setIsLoaded(true);
        const { data } = await Requests.Materials.getHardware(req, article).catch(error => {
            _errors.setError(error.message, 'error', true);
        });
        if (!empty(data)) {
            setIsLoaded(false);
            return data.map(item => {
                return {...item, count: 1};
            })
        } else {
            setIsLoaded(false);
            return [];
        }
    };

    const getFurnitureByCategory = async (id) => {
        //getFurnitureCatalogList
        setHardware([]);
        setIsLoaded(true);
        const { data } = await Requests.Materials.getFurnitureCatalogList(id).catch(error => {
            _errors.setError(error.message, 'error', true);
        });
        if (!empty(data)) {
            setIsLoaded(false);
            return data.map(item => {
                return {...item, count: 1};
            })
        } else {
            setIsLoaded(false);
            return [];
        }
    }

    useEffect(() => {
        const delay = 500;
        const debounceTimer = setTimeout(() => {
            setDebouncedValue(code);
        }, delay);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [code]);

    useEffect(() => {
        if (debouncedValue !== '') {
            search( code, step[step.length - 1].stepArticle).then(data => setHardware(data)).catch(error => console.log(error));
        }
    }, [debouncedValue]);

    const submit = () => {
        if (empty(selectedHardware)) {
            _errors.setError(`${Languages.getTranslation("hardware-not-chos", true)}`, 'error', true)
            return;
        }
        console.log(selectedHardware)
        construction
            .setProjectFurnitures(selectedHardware.map(el => ({...el, article: el.vendor_code})))
            .then(() => {
                setSelectedHardware([])
                close();
            });
    };

    const activeCheckBox = (type, hardware) => {
        if (type === "checkbox") {
            return selectedHardware.includes(hardware)
                ? "detailsTab__checkbox detailsTab__checkbox_active"
                : "detailsTab__checkbox";
        } else {
            return selectedHardware.includes(hardware)
                ? "checkbox__icon checkbox__icon_active"
                : "checkbox__icon";
        }
    };

    const selectHardwareHandler = (item) => {
        if(item.count <= 0){
            item.count = 1
        }
        if (selectedHardware.includes(item)) {
            const filteredHardware = selectedHardware.filter((el) => el.id !== item.id);
            setSelectedHardware([...filteredHardware]);
        } else {
            setSelectedHardware([...selectedHardware, item]);
        }
    };

    const handleChangeCont = (event, el) => {
        event.target.value = event.target.value.replace(/[^0-9]+/g, "");
        const { value }  = event.target;
        const temp = [...hardware]
        temp.forEach(item => {
            if (item.id === el.id) {
                item.count = Number(value)
            }
        })
        setHardware(temp)
    }

    const backStep = () => {
        setHardware([])
        if (step.length === 1) {
            backClick();
            return;
        }
        const prevStep = step.slice(0, -1);
        setCatalogList(prevStep[prevStep.length - 1].data)
        setStep(prevStep)
    }

    const openItem = (item) => {
        // console.log(item)
        setCatalogList([])
        if (item.count_children === 0) {
            getFurnitureByCategory(item.id).then(data => setHardware(data)).catch(error => console.log(error));
            setStep(prev => {
                return [...prev, {stepArticle: item.id, stepN: prev[prev.length - 1].stepN + 1, data: []}]
            })
        } else {
            searchCatalog(item.id).then(data => {
                setStep(prev => {
                    return [...prev, {stepArticle: item.id, stepN: prev[prev.length - 1].stepN + 1, data}]
                })
                setCatalogList(data)
            })
        }
    }

    const validateEmpty = (event, item) => {
        const { value }  = event.target;
        if(value === '' && item.count === 0){
            const temp = [...selectedHardware]
            temp.forEach(el => {
                if(el.id === item.id){ item.count = 1 }
            })
            setSelectedHardware([...temp]);
        }
    }

    return (<div className='modal'>
        <div className="addCatalog modal__wrapper">
					<ModalHeader className="addCatalog__header">
						<div className="addCatalog__header-title">
							<CatalogIcon/>
							<h3 className="addCatalog__header-title-text">
								{Languages.getTranslation("catalog-furniture", true)}
							</h3>
						</div>
						<div className="addCatalog__header-searchField">
							<i className="addCatalog__input-icon"></i>
							<input
								className="addCatalog__input"
								onChange={(e) => setCode(e.target.value)}
								ref={input}
								type="text"
								placeholder={Languages.getTranslation("search", true)}
								autoComplete='off'
							/>
						</div>
						<CloseBtn handler={close}/>
					</ModalHeader>
					{isLoaded ?
						<div className="addCatalog__main_loader"><Loader/></div> :
						!empty(catalogList) && catalogList[0] !== 'last' && step.length === 1 && code === '' &&
						<div className="addCatalog__main">
							{catalogList.map((item, index) => {
								return (
									<div key={index} onClick={() => openItem(item)} className="addCatalog__main_item">
										<img src={item.medias.small} className="addCatalog__main_item_img" alt={item.alias}/>
										<div className="addCatalog__main_item_text">
											{Languages.getAvaliableTranslationBaseOnUk(item.translations, "short_name")}
                                </div>
                            </div>
                        )
                    })} </div>}
            {isLoaded ?
                <div className="addCatalog__main_loader"><Loader/></div> :
                !empty(catalogList) && catalogList[0] !== 'last' && step.length > 1 && code === '' &&
                <div className="addCatalog__items">
                    {catalogList.map((item, index) => {
                        return (
                            <div key={index} onClick={() => openItem(item)}
                                 className="addCatalog__items_item">
                                <div className="addCatalog__items_item_name">
                                    {Languages.getAvaliableTranslationBaseOnUk(item.translations, "short_name")}
                                </div>
                                <svg width="9" height="16" viewBox="0 0 9 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999246 14.7441L7.5293 8.21409L1.05935 1.74414" stroke="#A14389"
                                          strokeWidth="1.6611" strokeLinecap="round"/>
                                </svg>
                            </div>
                        )
                    })} </div>}

            {!empty(hardware) ? (<div className="addCatalog__body">
                <div className='addCatalog__body_headerList'>
                    <div className="addCatalog__body_headerList-item">№</div>
                    <div className="addCatalog__body_headerList-item">
                        {Languages.getTranslation("name", true)}</div>
                    <div className="addCatalog__body_headerList-item">
                        {Languages.getTranslation("article", true)}</div>
                    <div className="addCatalog__body_headerList-item">
                        {Languages.getTranslation("amount", true)}</div>
                    <div className="addCatalog__body_headerList-item">
                        {Languages.getTranslation("price", true)}</div>
                    <div className="addCatalog__body_headerList-item">
                        {Languages.getTranslation("total", true)}</div>
                </div>
                {isLoaded ? <div className="addCatalog__main_loader"><Loader/></div> :
                    <div className="addCatalog__body-block">
                        <div className="addCatalog__body-list">
                            {hardware.map((item, index) => {
                                    if (item.active === "act" && (item.info.price > 0 || item.info.price_old > 0)) {
                                        return (
                                            <div className="addCatalog__body-list-item" key={index}>
                                                <div
                                                    className={activeCheckBox("checkbox", item)}
                                                    onClick={() => selectHardwareHandler(item)}
                                                >
                                                    <i className={activeCheckBox("icon", item)}></i>
                                                </div>
                                                <span className="">
                                                {`${index + 1}`}
                                            </span>
                                                <span className="addCatalog__body-list-item-text">
                                                <img
                                                    className="addCatalog__body-list-item-img"
                                                    src={item.media.images[0].small}
                                                    alt={Languages.getAvaliableTranslationBaseOnUk(item.translations)}/>

                                                    <div className="addCatalog__body-list-item-text_subText" title={Languages.getAvaliableTranslationBaseOnUk(item.translations)}>
                                                        {Languages.getAvaliableTranslationBaseOnUk(item.translations)}
                                                    </div>
                                                </span>
                                                    <span className="addCatalog__body-list-item-text">
                                                {`${item.vendor_code || item.article}`}
                                                </span>
                                                <div className='addCatalog__body-list-item__default-input'>
                                                    <input
                                                        type="text"
                                                        className='addCatalog__body-list-item__input'
                                                        name="count"
                                                        value={item.count || ''}
                                                        onChange={(event) => handleChangeCont(event, item)}
                                                        onBlur={(event) => validateEmpty(event, item)}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                                <span className="addCatalog__body-list-item-text" title={item.info.price}>
                                             {item.info.price}
                                            </span>
                                                    <span className="addCatalog__body-list-item-text" title={Number((item.info.price * item.count).toFixed(2))}>
                                                 {Number((item.info.price * item.count).toFixed(2))}
                                            </span>
                                            </div>)
                                    }
                            })}
                        </div>
                    </div>}
                </div>) : (!empty(code) && <div className="addCatalog__empty">
                        {Languages.getTranslation("not-found-furnitures", true)}
                            </div> )}

						<ModalFooter className="addCatalog__btns">
							<DefaultBtn
								title={Languages.getTranslation("return", true)}
								handler={backStep}
							/>
							{!empty(hardware) && <DefaultBtn
								title={Languages.getTranslation("save", true)}
								handler={submit}
								disabled={empty(selectedHardware)}
							/>}
						</ModalFooter>
        </div>
    </div>);
};

export default AddCatalogModal;
