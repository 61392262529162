import "./style.scss";
import Languages from "translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import {projectSelectors} from "redux/project";
import Project from '../../../../models/Project'
import {errorMessageOperations} from "redux/errors";
import {ModalFooter, ModalHeader} from "../index";
import {useState} from "react";
import Loader from "../../Loader";

const NewProjectOrLoadOrLoad = ({setIsEmpty}) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const construction = useSelector(projectSelectors.getConstruction);
	
	const close = () => {
		setIsLoading(true);
		Project.loadProject(null, dispatch)
			.then(() => {
				return Project.validateProjectDetails();
			})
			.then(() => {
				window.location.href = window.location.href.replace('?action=create', '')
			})
			.catch(err => console.log(err))
			.finally(() => setIsLoading(false))
	};
	
	const create = () => {
		construction.startNewProject()
			.then(() => {
				dispatch(errorMessageOperations.removeAllErrors({message: '', drop: true}))
				setIsEmpty([])
			})
			.catch(error => console.log(error))
	}
	
	let content;
	
	if (isLoading) {
		content = <Loader/>
	} else {
		content = <>
			<ModalHeader className="newProjectOrLoad__header">
				<h3 className="newProjectOrLoad__header-title">
					{Languages.getTranslation("new-project", true)}
				</h3>
			</ModalHeader>
			<div className="newProjectOrLoad__body">
				{Languages.getTranslation("new-project-or-load-text", true)}
			</div>
			<ModalFooter className="newProjectOrLoad__actions">
				<DefaultBtn
					title={Languages.getTranslation("load-project", true)}
					handler={close}
				/>
				<DefaultBtn
					title={Languages.getTranslation("create-project", true)}
					handler={create}
				/>
			</ModalFooter>
		</>
	}
	
	return (
		<div className='modal'>
			<div className="newProjectOrLoad modal__wrapper">
				{content}
			</div>
		</div>
	);
};

export default NewProjectOrLoadOrLoad;
