import './style.scss'
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import {Modal} from "../index";

const ModalSuccess = ({isOpen, text, buttonText, close }) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="success-modal__content modal__wrapper">
        <span className="success-modal__content-img" />
        <span className="success-modal__content-text">{text}</span>
        <span className="success-modal__content-btn">
          <DefaultBtn title={buttonText} handler={close} minWidth="100px" />
        </span>
      </div>
    </Modal>

  )
}

export default ModalSuccess;
