import "./style.scss";

const CloseBtn = ({ handler }) => {
  return (
      <button
				className="btnCross"
				type="button"
				onClick={handler}
				onTouchEnd={handler}
      >
				<i className="icon-close"></i>
      </button>
  );
};

export default CloseBtn;
