import API from "config/api";
import Languages from "translation/Languages";
import {empty} from "../helpers/helper";

export default class Constructor {
  constructor(axios, token, headers) {
    this.axios = axios;
    this.token = token;
    this.headers = headers;

  }

  setPreview(id, data) {
    this.headers = {
      ...this.headers,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    return this.axios.post(`${API.ROUTE.sendConstructorPreview()}/${id}/preview`, {"preview": JSON.stringify(data)}, this.headers).then(response => {
      if(response && response.data){
        return Promise.resolve(response.data)
      }else{
        return Promise.reject()
      }
    })
  }

}
