import {useGetClients} from "../../hooks/useGetClients";
import {DebouncedSearch} from "../UI/DebounceSearch";

export const SearchClient = ({ onSelect, id, isDisabled = false }) => {
	const { isClientFetching, meta, clients, handleSearch, resetOptions } = useGetClients();
	
	const selectOption = async (selectedClient) => {
		try {
			onSelect(selectedClient);
		} catch (error) {
			console.error(error);
		}
	};
	
	return (
		<DebouncedSearch
			id={id}
			options={clients}
			resetOptions={resetOptions}
			isDisabled={isDisabled}
			placeholder=""
			onChange={selectOption}
			hasMore={meta?.last_page !== meta?.current_page}
			handleSearch={handleSearch}
			isLoading={isClientFetching}
		/>
	);
};