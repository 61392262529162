import CloseBtn from "../../Buttons/CloseBtn";
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {useEffect, useRef} from "react";
import {ModalFooter, ModalHeader} from "../../Modal";

const FeaturesModal = ({closeModalHandler, modalType, modalTitle, modalState, modalSelectHandler, applyModalState, modal}) => {
	const modalElem = useRef();

	useEffect(() => {
		modalElem.current.scrollIntoView();
	}, [modal]);


	return <div className="featuresModal__background " ref={modalElem}>
		<div className="chosProcesses__comment-container chosProcesses__modal modal__wrapper">
			<ModalHeader className="chosProcesses__comment-header">
				<h3 className="chosProcesses__comment-header-title">{modalTitle}</h3>
				<CloseBtn handler={closeModalHandler}/>
			</ModalHeader>
			<div className='chosProcesses__modal-main'>
				<div className="chosProcesses__modal-container">
					{['mirror', 'mirrorWithMovement'].includes(modalType) && <div className='chosProcesses__modal-item'>
						<label className="param__label">
							{Languages.getTranslation('shift-direction', true)}
						</label>
						<select name="direction" className='param__default-select' value={modalState.direction}
										style={{cursor: 'pointer'}} onChange={(e) => modalSelectHandler(e)}>
							<option value=''>{Languages.getTranslation('choose', true)}</option>
							<option value='mirror_hor'>{Languages.getTranslation('clone-hor', true)}</option>
							<option value='mirror_ver'>{Languages.getTranslation('clone-ver', true)}</option>
						</select>
					</div>}
					{['moving-copy_group', 'moving_group'].includes(modalType) && <>
						<div className='chosProcesses__modal-item'>
							<label className="param__label">
								{Languages.getTranslation('shift-direction', true)}
							</label>
							<select name="direction" className='param__default-select' value={modalState.direction}
											style={{cursor: 'pointer'}} onChange={(e) => modalSelectHandler(e)}>
								{[
									{key: "horizontal", value: Languages.getTranslation('horizontal', true)},
									{key: "vertical", value: Languages.getTranslation('vertical', true)},
									// {key: "diagonal", value: Languages.getTranslation('diagonal', true)},
								].map(el => <option key={el.key} value={el.key}>{el.value}</option>)}
							</select>
						</div>
						<div className='chosProcesses__modal-item'>
							<label className="param__label">
								{Languages.getTranslation("slide", true)}
							</label>
							<input type="text" className='param__input chosProcesses__modal-input' name='value'
										 style={{height: 41}} value={modalState.value} onChange={(e) => modalSelectHandler(e)}/>
						</div>
					</>}
				
				</div>
			</div>
			<ModalFooter className='chosProcesses__modal-footer'>
				<DefaultBtn handler={applyModalState} title={Languages.getTranslation('save', true)}/>
			</ModalFooter>
		</div>
	</div>
}

export default FeaturesModal