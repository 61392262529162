import CloseBtn from "../../Buttons/CloseBtn";
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {modalsOperations, modalsSelectors} from "redux/modals";
import CustomSelect from "../../Select";
import _Details from "../../../../db/_Details";
import {toastError} from "../../../../helpers/toasts";

const detailsDb = new _Details();


const parentStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#ffffff',
    border: 'var(--border-width-thin) solid black',
    marginTop: 25
  })
};

const ChangeProductOnDetail = () => {
  const construction = useSelector(projectSelectors.getConstruction);
  const [products, setProducts] = useState(construction.products)
  const [value, setValue] = useState(1)
  const detailsToChange = useSelector(modalsSelectors.getModalIsEdit);
  const dispatch = useDispatch()

  useEffect(() => {
    setProducts([...construction.products.map(product => ({
      value: product.id,
      label: product.name,
    }))])
  }, []);

  const close = () => {
    dispatch(modalsOperations.switchStateForModal({
      show: false, type: null,
    }));
  };

  // const submitHandler = () => {
  //   detailsToChange.forEach(detail => {
  //     detailsDb.updateDetail(detail.id, 'productId', value.value)
  //       .then(() => detail.productId = value.value)
  //       .catch(err => console.log(err))
  //   })
  //   close()
  // }

  const submitHandler = () => {
    Promise.all(detailsToChange.map(detail => detailsDb.updateDetail(detail.id, 'productId', value.value)
      .then(() => detail.productId = value.value)))
      .then(() => close())
      .catch(err =>  toastError(Languages.getTranslation('defaultError', true)));
  }

  return (<div className="chosProcesses__comment-container chosProcesses__modal modal__wrapper">
				<div className="chosProcesses__comment-header">
					<span
						className="chosProcesses__comment-header-title"> {Languages.getTranslation('change-product-on-detail', true)}</span>
					<CloseBtn handler={close}/>
				</div>
				<div className='chosProcesses__modal-main'>
					<div className="chosProcesses__modal-container">
						{<div className='chosProcesses__modal-item'>
				
							<CustomSelect
								value={value}
								customStyles={parentStyles}
								placeholder={Languages.getTranslation('choose-product-for-replace', true)}
								options={products}
								isDisabled={construction.products.length === 0}
								handleChange={(selectedOption) => setValue(selectedOption)}
							/>
				
							{/*<select name="direction" className='param__default-select'*/}
							{/*        style={{cursor: 'pointer'}} placeholder={Languages.getTranslation("select-action", true)}>*/}
				
							{/*  {construction.products.map((product) => <option value={product.id} key={product.id}>{product.name}</option>)}*/}
							{/*</select>*/}
						</div>}
				
					</div>
					<div className='chosProcesses__modal-footer'>
						<DefaultBtn title={Languages.getTranslation('save', true)} handler={submitHandler}/>
					</div>
				</div>
				</div>
	)
}

export default ChangeProductOnDetail;