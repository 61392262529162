import "./style.scss";
import TableItem from "./TableItem";
import {useEffect, useRef, useState} from "react";
import {modalsOperations} from "redux/modals";
import {useDispatch, useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import MaterialItem from "./MaterialItem";
import CloseBtn from "components/UI/Buttons/CloseBtn";
import {empty} from "../../../../helpers/helper";
import EdgeItem from "./EdgeItem";
import ItemHelper from "./ItemHelper";
import EdgeHelper from "./EdgeHelper";
import Languages from '../../../../translation/Languages'
import Errors from "../../../../models/Errors";
import {toastError} from "../../../../helpers/toasts";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Project from "../../../../models/Project";
import {ModalHeader} from "../index";

const _errors = new Errors([])

const QuickInputTable = () => {
	const detailsRef = useRef(null)
	const dispatch = useDispatch();
	const [isMaterialsOpen, setIsMaterialsOpen] = useState(false);
	const [isEdgesOpen, setIsEdgesOpen] = useState(false);
	const [tableDetails, setTableDetails] = useState([]);
	const [edgesMode, setEdgesMode] = useState(false);
	const [edgesModeOnly, setEdgesModeOnly] = useState(false);
	const construction = useSelector(projectSelectors.getConstruction);
	const currentMaterials = useSelector(projectSelectors.getMaterials);
	const currentEdges = useSelector(projectSelectors.getEdges)
	const details = useSelector(projectSelectors.getDetails);
	const [selectedMaterial, setSelectedMaterial] = useState(currentMaterials[0]);
	const [isEdgesHelperOn, setIsEdgesHelperOn] = useState(false)
	const itemHelper = useRef()
	const [currentDetail, setCurrentDetail] = useState(null)
	const [yPoint, setYPoint] = useState(90)
	const [leftPoint, setLeftPoint] = useState(507)
	const [leftPoint2, setLeftPoint2] = useState(507)
	const edgeHelperRef = useRef()

    const edgesModeClass = edgesMode
        ? "quickInputTable__edges-mode quickInputTable__edges-mode_active"
        : "quickInputTable__edges-mode";

    const edgesModeItemClass = edgesMode
        ? "quickInputTable__edges-mode-item quickInputTable__edges-mode-item_active"
        : "quickInputTable__edges-mode-item";

		const edgesModeOnlyClass = edgesModeOnly
        ? "quickInputTable__edges-mode quickInputTable__edges-mode_active"
        : "quickInputTable__edges-mode";

    const edgesModeItemOnlyClass = edgesModeOnly
        ? "quickInputTable__edges-mode-item quickInputTable__edges-mode-item_active"
        : "quickInputTable__edges-mode-item";

    const edgeModeHandler = () => {
		if(['OSB'].includes(selectedMaterial?.type)) { return; }
			if (edgesModeOnly){
				setEdgesModeOnly(false)
			}
        setEdgesMode((prevState) => !prevState);
    };

		const edgeModeOnlyHandler = () => {
			if(['OSB'].includes(selectedMaterial?.type)) { return; }
			if (edgesMode){
				setEdgesMode(false)
			}
			setEdgesModeOnly((prevState) => !prevState);
    };

    const materialsClass = isMaterialsOpen
        ? "quickInputTable__info-body"
        : "quickInputTable__info-body_active";

    const edgesClass = isEdgesOpen
        ? "quickInputTable__info-body"
        : "quickInputTable__info-body_active";

    const materialsIconClass = isMaterialsOpen
        ? "polygon-down polygon-down_active"
        : "polygon-down";

    const edgesIconClass = isEdgesOpen
        ? "polygon-down polygon-down_active"
        : "polygon-down";

    const tableBodyClass =
        isEdgesOpen || isMaterialsOpen
            ? "quickInputTable__table-body_active"
            : "quickInputTable__table-body";

		const changeRowOnDetails = () => {
			if (!empty(detailsRef.current.lastElementChild)) {
				detailsRef.current.lastElementChild.childNodes[1]?.firstElementChild.focus()
			}
		}

	useEffect(() => {
		// переход на первый инпут следующей строк
		changeRowOnDetails()
	}, [tableDetails.length]);

    const handleAddDetail = () => {
			if (tableDetails.length) {
				if (tableDetails.some((el) => !el.l || !el.h || !el.count)) {
					_errors.setError(`${Languages.getTranslation("part-are-not-correct", true)}`, 'error', true)
					return
				}
			}
        const detail = construction.getEmptyDetailData();
        detail.name = `${Languages.getTranslation("detail", true)} ${details.length + tableDetails.length + 1}`;
        detail.material = selectedMaterial;
        detail.count = 0;
        detail.isRotateTexture = !['OSB'].includes(selectedMaterial.type)
				detail.productId = 1
        setTableDetails([...tableDetails, detail]);
    };

    const deleteDetail = (detail, row) => {
        if (!empty(detail.id)) {
            construction.deleteDetail(detail.id)
                .then(() => {
                    // saveLastInputRef(row, null, true);
                    setTableDetails([...tableDetails.filter(el => el.id !== detail.id)])
                })
        } else {
            const details = [...tableDetails];
            details.splice(row, 1);
            setTableDetails(details)
        }
    };

		const save = () => {
			construction.details.forEach(detail => detail.createContour())
			close()
		}

		const valideteDetails = () => {
			Project.validateProjectDetails().then(() => {}).catch(error => console.log(error))
		}

    const close = () => {
		valideteDetails();
        dispatch(
            modalsOperations.switchStateForModal({
                show: false,
                type: null,
            })
        );
    };

    const quickValidate =  (data) => {
        const width = Number(data.l);
        const height = Number(data.h);
        if (width < 30 || height < 30) {
							toastError(Languages.getTranslation("part-are-not-correct", true))
                return false
        } else if (width === 30 && height < 70) {
						toastError(Languages.getTranslation("part-are-not-correct", true))
						return false
				} else if (height === 30 && width < 70) {
						toastError(Languages.getTranslation("part-are-not-correct", true))
						return false
				}
				else {
            return true;
        }
    }

    const saveDetail = async (data, index) => {
        const isValidData = quickValidate(data, index);
        if (!isValidData) {
            return false;
        }
        const _details = [...tableDetails];
        const detail = _details[index];
				const material = currentMaterials.find(el=> el.index === detail.material.index || el.index === detail.material)
				detail.w = material.thickness
				detail.isPostForming = ['Постформінг', 'Постформинг'].includes(material.type)
        if (empty(detail.id)) {
          for (const key in data) {
            if(key === 'edges') {
	            const edges = {...data[key]}
	            for(let edge in edges) {
		            if(!empty(edges[edge])) {
			            edges[edge] = currentEdges[edges[edge] - 1].index
		            }
	            }
	            detail.edges = edges;
            } else {
	            detail[key] = data[key]
            }
          }
        await construction.addProjectDetails([detail])
              .then(() => {
                  _details[index].id = construction._details[construction._details.length - 1].id;
                  setTableDetails([..._details]);
              })
        } else {
            const updated = [];
            for (const key in data) {
                if (detail[key] !== data[key] && key !== 'edges') {
                    updated.push({[key]: data[key]})
                }
	            if(key === 'edges') {
								const edges = {...data[key]}
		            for(let edge in edges) {
			            if(!empty(edges[edge])) {
				            edges[edge] = currentEdges[edges[edge] - 1].index
			            }
		            }
		            updated.push({edges: edges})
	            } else {
		            detail[key] = data[key]
	            }

            }
            if (!empty(updated)) {
                Promise.all(updated.map(el => {
                    const name = Object.keys(el)[0];

                    return construction.updateDetail(detail.id, name, el[name])
                }))
                    .then(() => {
                        setTableDetails([..._details])
                    })
            }
        }
    }

	const changeMaterial = (material) => {
		setSelectedMaterial(material)
		const currentDetail = tableDetails.find(el => el.id === null);
		if (currentDetail) {
			currentDetail.material = material;
			currentDetail.isRotateTexture = !['OSB'].includes(material.type);
		}
	}

	const handlerGetTop = (number)=>{
		return number
	}

	return (
		<div className="quickInputTable modal__wrapper">
			<ModalHeader className="quickInputTable__header">
				<h3 className="quickInputTable__header-title">
					{Languages.getTranslation('TSHV-title', true)}
				</h3>
				<CloseBtn handler={close}/>
			</ModalHeader>
			<div className="quickInputTable__materials-edges">
			<div className="quickInputTable__info">
					<div className="quickInputTable__info-header">
						<div className="quickInputTable__info-header-title">{Languages.getTranslation('material', true)}</div>
						<div
							className="quickInputTable__info-header-btn"
							onClick={() => setIsMaterialsOpen(!isMaterialsOpen)}
						>
							<i className={materialsIconClass}></i>
						</div>
					</div>
					<div className={materialsClass}>
						{currentMaterials.map((material, index) => (
							<MaterialItem
								key={index}
								material={material}
								index={index}
								setSelectedMaterial={changeMaterial}
								selectedMaterial={selectedMaterial}
							/>
						))}
					</div>
				</div>
				<div className="quickInputTable__info">
					<div className="quickInputTable__info-header">
						<div className="quickInputTable__info-header-title">{Languages.getTranslation('edge', true)}</div>
						<div
							className="quickInputTable__info-header-btn"
							onClick={() => setIsEdgesOpen(!isEdgesOpen)}
						>
							<i className={edgesIconClass}></i>
						</div>
					</div>
					<div className={edgesClass}>
						{currentEdges.map((edge, index) => (
							<EdgeItem
								edge={edge}
								index={index}
								key={index}/>
						))}
					</div>
				</div>
			</div>
			<div className="quickInputTable__addDetail">
				<h3 className="quickInputTable__addDetail-title">{`${Languages.getTranslation('add-detail', true)} ${Languages.getTranslation("overall-size", true)}`}</h3>
				<button
					className="quickInputTable__addDetail-btn"
					style={{marginLeft: "0"}}
					onClick={handleAddDetail}
				>
					<i className="btn__icon-add"></i>
				</button>
				<span className="quickInputTable__edges-mode-text">{Languages.getTranslation('edge', true)}</span>
				<div className={edgesModeClass} style={['OSB'].includes(selectedMaterial?.type) ? {
					cursor: 'not-allowed',
					opacity: '.5'
				} : {cursor: "pointer"}} onClick={edgeModeHandler}>
					<div className={edgesModeItemClass}></div>
				</div>

				<span className="quickInputTable__edges-mode-text">{`${Languages.getTranslation('only', true)} ${Languages.getTranslation('edge', true)}`}</span>
				<div className={edgesModeOnlyClass} style={['OSB'].includes(selectedMaterial?.type) ? {
					cursor: 'not-allowed',
					opacity: '.5'
				} : {cursor: "pointer"}} onClick={edgeModeOnlyHandler}>
					<div className={edgesModeItemOnlyClass}></div>
				</div>
			</div>
			<div className="quickInputTable__table-header">
				<span className="quickInputTable__table-header-number ">№</span>
				<div className="quickInputTable__table-header-main-container">
					<span className="quickInputTable__table-header-item ">{Languages.getTranslation('height', true)}</span>
					<span className="quickInputTable__table-header-item ">{Languages.getTranslation('width', true)}</span>
					<span className="quickInputTable__table-header-item ">
              Кількість
            </span>

					<div className="label-icon">
						<i className="icon__triangle-up"></i>
					</div>
					<div className="label-icon">
						<i className=" icon__triangle-down"></i>
					</div>
					<div className="label-icon">
						<i className=" icon__triangle-left"></i>
					</div>
					<div className="label-icon">
						<i className=" icon__triangle-right"></i>
					</div>
				</div>
				<div className="quickInputTable__content-container">
					<div className="quickInputTable__table-header-texture">
						Текстура
					</div>
					<div className="quickInputTable__table-header-name ">{Languages.getTranslation('name', true)}</div>
					<div className="quickInputTable__table-header-name ">{Languages.getTranslation('material', true)}</div>
					<div className="quickInputTable__table-header-name ">{Languages.getTranslation('delete', true)}</div>
				</div>
			</div>
			{currentDetail ? <ItemHelper edges={currentEdges} detail={currentDetail} selectedMaterial={selectedMaterial}
				itemHelper={itemHelper} materials={currentMaterials} handlerGetTop={handlerGetTop} yPoint={yPoint} leftPoint={leftPoint}/> : <div ref={itemHelper}></div>}
			<EdgeHelper edgeHelperRef={edgeHelperRef} edges={currentEdges} leftPoint2={leftPoint2}/>
			<div className={tableBodyClass} ref={detailsRef}>
				{!empty(tableDetails) ? tableDetails.map((detail, indx) => (
					<TableItem
						saveDetail={saveDetail}
						detail={detail}
						deleteDetail={deleteDetail}
						key={detail.id}
						row={indx}
						focus={indx === tableDetails.length - 1 ? 'count' : false}
						selectedMaterial={selectedMaterial}
						currentMaterials={currentMaterials}
						edges={currentEdges}
						isEdgesHelperOn={isEdgesHelperOn}
						setIsEdgesHelperOn={setIsEdgesHelperOn}
						setCurrentDetail={setCurrentDetail}
						itemHelper={itemHelper}
						handlerGetTop={handlerGetTop}
						setYPoint={setYPoint}
						setLeftPoint={setLeftPoint}
						edgeHelperRef={edgeHelperRef}
						setLeftPoint2={setLeftPoint2}
						handleAddDetail={handleAddDetail}
						edgesMode={edgesMode}
						edgesModeOnly={edgesModeOnly}
						_errors={_errors}
						setSelectedMaterial={setSelectedMaterial}
					/>
				)) : <div className='quickInputTable__addDetails'>Додайте деталі</div> }
			</div>
			<div className='quickInputTable__btn-save'>
				<DefaultBtn handler={save} title={Languages.getTranslation('save', true)}/>
			</div>
		</div>
	);
};
//fix
export default QuickInputTable;
