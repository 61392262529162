import {useEffect, useRef} from "react";
import {CSSTransition} from "react-transition-group";
import {useSelector} from "react-redux";
import {modalsSelectors} from "redux/modals";
import {inputsSelectors} from "redux/inputs";

import ImportProject from "components/UI/Modal/Types/importProject";
import SaveProject from "./SaveProject/index";
import IncreaseDetail from "./increaseDetail/index";
import AddFolder from "./AddFolder/index";
import QuickInputTable from "./QuickInputTable";
import AddDetail from "./AddDetail";
import CuttingParams from "./CuttingParams";
import AddEdjes from "./AddEdges";
import AddMaterialAndEdges from "./AddMaterialAndEdges";
import TransportCut from "./TransportCut";
import NewProject from "./NewProject";
import KronasMaterials from "./KronasMaterials";
import ReplaceMaterial from "./ReplaceMaterial";
import CustomerWarehouse from "./CustomerWarehouse";
import ReplaceMaterialProject from "./ReplaceMaterialProject";
import ImportErrorModal from "./ImportErrorModal";
import ReplaceEdgesProject from "./ReplaceEdgesProject";
import MapProject from "./MapProject";
import CalcProject from "./CalcProject";
import MultiplicityModel from "./MultiplicityModel";
import ReplaceEdge from "./ReplaceEdge";
import SaveToOwnerProfile from "./SaveToOwnerProfile";
import ChooseOwner from "./ChooseOwner";
import RemoveEdges from "./RemoveEdges";
import MaterialsResidues from "./MaterialsResidues/MaterialsResidues";
import Products from "./Products";
import DeleteProduct from "./DeleteProduct";
import ChangeProductOnDetail from "./ChangeProductOnDetail";
import GroupChangeDiamHoles from "./GroupChangeDiamHoles";
import {useUpdateTranslations} from "../../../hooks/useUpdateTranslations";
import {Divider} from "../Divider";
import {createPortal} from "react-dom";

export const ModalHeader = ({children, className = ""}) => {
	return <>
		<div className={`modal__header ${className}`.trim()}>
			{children}
		</div>
		<Divider/>
	</>
}

export const ModalFooter = ({children, className = ""}) => {
	return <>
		<Divider/>
		<div className={`modal__actions ${className}`.trim()}>
			{children}
		</div>
	</>
}

export const Modal = ({isOpen, children}) => {
	const modalRef = useRef(null);
	
	return (createPortal(<CSSTransition
		in={isOpen}
		nodeRef={modalRef}
		timeout={300}
		classNames="modal modal-state"
		unmountOnExit
	>
		<div ref={modalRef}>
			{children}
		</div>
	</CSSTransition>, document.body))
}

const ReduxModal = () => {
	const show = useSelector(modalsSelectors.getModalShowState);
	const type = useSelector(modalsSelectors.getModalTypeState);
	const file = useSelector(inputsSelectors.getFileProjectState);
	useUpdateTranslations();
	
	useEffect(() => {
	}, [type, show, file]);
	
	return (<Modal isOpen={show && type != "PROCESSING"}>
		{(() => {
			switch (type) {
				case "IMPORT_PROJECT_1":
					return <ImportProject file={file}/>;
				case "ADD_MATERIAL":
					return <AddMaterialAndEdges/>;
				case "TRANSPORT_CUT":
					return <TransportCut/>;
				case "LINK_MATERIAL":
					return <AddMaterialAndEdges/>;
				case "NEW_PROJECT":
					return <NewProject/>;
				case "REPLACE_MATERIAL_PROJECT":
					return <ReplaceMaterialProject/>;
				case "REPLACE_EDGES_PROJECT":
					return <ReplaceEdgesProject/>;
				case "REPLACE_MATERIAL":
					return <ReplaceMaterial/>;
				case "REPLACE_EDGE":
					return <ReplaceEdge/>;
				case "REMOVE_EDGES":
					return <RemoveEdges/>;
				case "CUTTING_PARAMS":
					return <CuttingParams/>;
				case "CUSTOMER_WAREHOUSE":
					return <CustomerWarehouse/>;
				case "KRONAS_MATERIALS":
					return <KronasMaterials/>;
				case "CHOOSE_OWNER":
					return <ChooseOwner/>;
				case "SAVE_PROJECT":
					return <SaveProject/>;
				case "INCREASE_DETAIL":
					return <IncreaseDetail/>;
				case "SAVE_TO_OWNER_PROFILE":
					return <SaveToOwnerProfile/>;
				case "MAP_PROJECT":
					return <MapProject/>;
				case "CALC_PROJECT":
					return <CalcProject/>;
				case "MULTIPLICITY":
					return <MultiplicityModel/>;
				case "IMPORT_ERROR_MODAL":
					return <ImportErrorModal/>;
				case "ADD_FOLDER":
					return <AddFolder/>;
				case "GROUP_CHANGE_DIAM_HOLES":
					return <GroupChangeDiamHoles/>;
				case "MATERIALS_RESIDUES":
					return <MaterialsResidues/>;
				case "QUICK_INPUT_TABLE":
					return <QuickInputTable/>;
				case "ADD_DETAIL":
					return <AddDetail/>;
				case "ADD_EDJES":
					return <AddEdjes/>;
				case "DELETE_PRODUCT":
					return <DeleteProduct/>;
				case "PRODUCTS":
					return <Products/>;
				case "CHANGE_PRODUCT_ON_DETAIL":
					return <ChangeProductOnDetail/>;
				default:
					return null;
			}
		})()}
	</Modal>)
};

export default ReduxModal;
