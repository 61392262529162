import {useState} from "react";
import API from 'api/API';
import Languages from "../../translation/Languages";
import './style.scss'
import plug from 'media/unsplash_WiCAU5dwom8.svg'
import Errors from "../../models/Errors";

const _errors = new Errors([])

const Authorize = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submit = (event) => {
    event.preventDefault()
    const user = API.Auth.getUser({email: email, password: password})
        .then(data => {
          //TODO: add user data to redux
          document.location.href = '/'
        }).catch(() => {
          _errors.setError(`${Languages.getTranslation('e-mail-or-password-incorrectly', true)}`, 'error', true)
        })
  }

  return (
    <>
      <div className="authorize">
        <div className="plug">
          <img src={plug} />
        </div>
        <div className="form-plug">
          <form onSubmit={submit} className="form">
            <h2>{Languages.getTranslation('authorization', true)}</h2>
            <div>
              <div className="form-control">
                <label htmlFor="email">E-mail</label>
                <input required id="email" onChange={e => setEmail(e.target.value)} name="email" value={email} type='text' maxLength='255' />
              </div>
              <div className="form-control">
                <label htmlFor="password">{Languages.getTranslation('password', true)}</label>
                <input autoComplete="on" required id="password" onChange={e => setPassword(e.target.value)} name="password" value={password} type='password' maxLength='255' />
              </div>
              <div className="action">
                <button className="active">{Languages.getTranslation('login', true)}</button>
              </div>
              <div className="reg-actions">
                <div>
                  <a target="_blank" href="https://my.kronas.com.ua/#/auth/recover-password">{Languages.getTranslation('forgot_pass_q', true)}</a>
                </div>
                <div className="register">
                  <div>{Languages.getTranslation('have_not_acc_q', true)}</div>
                  <a target="_blank" href="https://my.kronas.com.ua/#/auth/registration">{Languages.getTranslation('to_register', true)}</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Authorize
