import Languages from "../../../translation/Languages";
import React from "react";
import './style.scss'
const DetailsForDelete = ({ details }) => {
  return (
    <>

      <div className="deleteMaterial__body">
        <div className="deleteMaterial__details-header">
          <div className="deleteMaterial__number">№</div>
          <div className="deleteMaterial__name">
            {Languages.getTranslation("name", true)}
          </div>
          <div className="deleteMaterial__container">
            <div className="deleteMaterial__item">
              {Languages.getTranslation("height", true)}
            </div>
            <div className="deleteMaterial__item">
              {Languages.getTranslation("width", true)}
            </div>
            <div className="deleteMaterial__item">
              {Languages.getTranslation("amount", true)}
            </div>
            <div className="deleteMaterial__item">
              {Languages.getTranslation("multiplicity", true)}
            </div>
          </div>
        </div>
        <div className="deleteMaterial__details-list">
          {details.map((detail, key) => (
              <div key={key} className="deleteMaterial__detail">
                <div className="deleteMaterial__number">{key + 1}</div>
                <div className="deleteMaterial__name">{detail.name}</div>
                <div className="deleteMaterial__container">
                  <div className="deleteMaterial__item">{detail.l}</div>
                  <div className="deleteMaterial__item">{detail.h}</div>
                  <div className="deleteMaterial__item">{detail.count}</div>
                  <div className="deleteMaterial__item">
                    {detail.multiplicity?.type ?? '-'}
                  </div>
                </div>
              </div>))}
        </div>
      </div>
    </>
  );
};

export default DetailsForDelete;
