import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {constructor3dOperations, constructor3dSelectors} from "./../../../../redux/constructor";
import Base from "../../../model/components/Base";
import ResizeProduct from "../../../model/components/ResizeProduct";
import {userSelectors} from "../../../../redux/user"
import Panel from "../../layuot/panel";
import {empty} from "../../../../helpers/helper";
import Helper from "../../../model/3d/Helper";
import UsedDetail from "../UsedDetail";
import Connections from "../Connections";
import Modal from "../../layuot/Modal";
import {v4 as uuidv4} from "uuid";
import './style.scss'
import Tooltip from "../UI/Tooltip";


const UsedDetails = ({productId, title}) => {
	const permissions = useSelector(userSelectors.permissions);
	const usedDetails = useSelector(constructor3dSelectors.getCDetails);
	const details = usedDetails.filter(el => el.productId === productId);
	const selectedObjects = useSelector(constructor3dSelectors.getSelectedObjects)
	const BaseClass = new Base();
	const ResizeProductClass = new ResizeProduct();
	const dispatch = useDispatch();
	// const selectedDetails = useSelector(constructor3dSelectors.getSelectedDetails)
	const [optionsVisible, setOptionsVisible] = useState(false)
	// const [selectedDetails, setSelectedDetails] = useState([])
	const [detailsGroups, setDetailsGroup] = useState([])
	const [sizeModal, setSizeModal] = useState(false);
	const [minMaxSizeModal, setMinMaxSizeModal] = useState(false);
	const [minMaxSize, setMinMaxSize] = useState({
		min: {},
		max: {}
	});
	const [errors, setErrors] = useState({})
	const [size, setSize] = useState(null);

	const getDetailsGroups = () => {
		const tmp_obj = {};
		details.forEach(detail => {
			if(!empty(detail.group?.group_id)) {
				if(empty(tmp_obj[detail.group.group_id])) {
					tmp_obj[detail.group.group_id] = {
						name: detail.group.name,
						group_id: detail.group.group_id,
						details: []
					};
				}
				tmp_obj[detail.group.group_id].details.push(detail);
			} else {
				if(empty(tmp_obj?.noGroup)) {
					tmp_obj.noGroup = []
				}
				tmp_obj.noGroup.push(detail)
			}
		})
		return Object.values(tmp_obj)
	}

	useEffect(() => {
		// setSelectedDetails(usedDetails.filter(detail => detail.selected))
		setDetailsGroup(getDetailsGroups())
	}, [usedDetails])

	useEffect(() => {

	}, [detailsGroups]);

	useEffect(() => {
		const minMax = BaseClass.getProductDetails(productId)?.minMax
		if(!empty(minMax)) {
			setMinMaxSize(minMax)
		}
	}, [])

	if(empty(details)) return null;

	const lockProduct = () => {
		BaseClass.lockProduct(productId);
	}

	const toggleOptions = () => {
		setOptionsVisible(!optionsVisible)
	}

	const removeFromGroup = detail => {
		detail.removeFromGroup()
	}

	const mergeDetails = (selectedDetails) => {
		const name = prompt('Назва Об’єкту');
		if(!empty(name)) {
			const group = {
				group_id: uuidv4(),
				name
			}
			selectedDetails.forEach(detail => detail.addToGroup(group))
		}
	}

	const changeProductSize = () => {
		const { size} = BaseClass.getProductSize(productId);
		const minMax = BaseClass.getProductDetails(productId)?.minMax;
		// console.log(minMax)
		setSize({
			x: Number(size.x.toFixed(2)),
			y: Number(size.y.toFixed(2)),
			z: Number(size.z.toFixed(2))});
		setSizeModal(true)
	}

	const saveProductSize = () => {
		ResizeProductClass.resizeProduct(productId, size);
	}

	const setProductMinMax = () => { //TODO: Should be implemented by product rotation
		const value = BaseClass.getProductDetails(productId)?.minMax;
		if(value) {
			setMinMaxSize({
				min: {
					x: value.min.width,
					y: value.min.height,
					z: value.min.depth
				},
				max: {
					x: value.max.width,
					y: value.max.height,
					z: value.max.depth
				}
			})
		} else {
			const { size} = BaseClass.getProductSize(productId);
			setMinMaxSize({
				min: {
					x: Number(size.x.toFixed(2)),
					y: Number(size.y.toFixed(2)),
					z: Number(size.z.toFixed(2))
				},
				max: {
					x: Number(size.x.toFixed(2)),
					y: Number(size.y.toFixed(2)),
					z: Number(size.z.toFixed(2))
				}
			})
		}
		setMinMaxSizeModal(true)
	}

	const saveProductMinMax = () => {
		const data = { //TODO: Should be implemented by product rotation
			min: {
				height: minMaxSize.min?.y,
				width: minMaxSize.min?.x,
				depth: minMaxSize.min?.z
			},
			max: {
				height: minMaxSize.max?.y,
				width: minMaxSize.max?.x,
				depth: minMaxSize.max?.z
			}

		}
		BaseClass.updateProjectProduct(productId, data)
			.then(() => setMinMaxSizeModal(false))
	}

	const getChangeSizeControls = () => {
		return (
			<>
				<button onClick={saveProductSize}>save</button>
			</>
		)
	}

	const getMinMaxControls = () => {
		return (
			<>
				<button onClick={saveProductMinMax}>save</button>
			</>
		)
	}

	const handlerChangeSize = (e, axis) => {
		const {value} = e.target;
		const val = Number(value);
		if(val >= minMaxSize.min[axis] && value <= minMaxSize.max[axis]) {
			setSize({...size, [axis]: val})
		} else {
			setErrors({...errors, 'minMax': {[axis]: 'incorrectSize'}})
		}
	}

	const getError = () => {

	}

	const getEditSizeModal = () => {
		if(empty(size) || !sizeModal) return null;
		return (
      <Modal
	      title={'set-size'}
	      show={true}
	      hide={() => setSizeModal(false)}
	      controls={getChangeSizeControls()}
      >
            <div className="modal__input-group">
              <label htmlFor="size-x">X:</label>
              <input
                type="number"
                id="size-x"
                value={size.x}
                onChange={(e) => setSize({ ...size, x: Number(e.target.value) })}
              />
            </div>
            <div className="modal__input-group">
              <label htmlFor="size-y">Y:</label>
              <input
                type="number"
                id="size-y"
                value={size.y}
                onChange={(e) => setSize({ ...size, y: Number(e.target.value) })}
              />
            </div>
            <div className="modal__input-group">
              <label htmlFor="size-z">Z:</label>
              <input
                type="number"
                id="size-z"
                value={size.z}
                onChange={(e) => setSize({ ...size, z: Number(e.target.value) })}
              />
            </div>
      </Modal>
    )
	}

	const minMaxHandler = e => {
		const {name, value} = e.target;
		const _tmp = name.split('_')
		setMinMaxSize(state => {
			return {...state, [_tmp[0]]: {...state[_tmp[0]], [_tmp[1]]: Number(value.toFixed(2))}}
		})
	}

	const getSetSizeModal = () => {
		if(!minMaxSizeModal) return null;
		return (<Modal
			title={'change-size'}
			show={true}
			hide={() => setMinMaxSizeModal(false)}
			controls={getMinMaxControls()}
		>
			<h2>Мінімальні значення</h2>
			<div className="modal__input-group">
				<label htmlFor="size-x">X:</label>
				<input
					type="number"
					id="size-x"
					name={'min_x'}
					value={minMaxSize.min?.x}
					onChange={minMaxHandler}
				/>
			</div>
			<div className="modal__input-group">
				<label htmlFor="size-y">Y:</label>
				<input
					type="number"
					id="size-y"
					name={'min_y'}
					value={minMaxSize.min?.y}
					onChange={minMaxHandler}
				/>
			</div>
			<div className="modal__input-group">
				<label htmlFor="size-z">Z:</label>
				<input
					type="number"
					id="size-z"
					name={'min_z'}
					value={minMaxSize.min?.z}
					onChange={minMaxHandler}
				/>
			</div>
			<h2>Максимальні значення</h2>
			<div className="modal__input-group">
				<label htmlFor="size-x">X:</label>
				<input
					type="number"
					id="size-x"
					name={'max_x'}
					value={minMaxSize.max?.x}
					onChange={minMaxHandler}
				/>
			</div>
			<div className="modal__input-group">
				<label htmlFor="size-y">Y:</label>
				<input
					type="number"
					id="size-y"
					name={'max_y'}
					value={minMaxSize.max?.y}
					onChange={minMaxHandler}
				/>
			</div>
			<div className="modal__input-group">
				<label htmlFor="size-z">Z:</label>
				<input
					type="number"
					id="size-z"
					name={'max_z'}
					value={minMaxSize.max?.z}
					onChange={minMaxHandler}
				/>
			</div>

		</Modal>)
	}

	const renderOptions = () => {
		// if (!optionsVisible) return null;
		const optionsArray = [];
		const selectedDetails = details.filter(detail => detail.selected);
		optionsArray.push({
			type: 'action',
			handler: () => changeProductSize(),
			text: 'changeSize'
		})

		// if(permissions.includes('KK_addSizeLimit')) {
			optionsArray.push({
				type: 'action',
				handler: () => setProductMinMax(),
				text: 'setSize'
			})
		// }

		// if (selectedDetails.length >= 2) {
		// 	optionsArray.push({
		// 		type: 'action',
		// 		handler: () => mergeDetails(selectedDetails),
		// 		text: 'group'
		// 	})
		// }
		return (
			<>
				{optionsArray.map(el => <a onClick={el.handler}>{el.text}</a>)}
			</>
		)
	}

	const getProductOptions = () => {
		return (
			<>
				<Tooltip className={'icon-button-burger'} showType={'click'}>
					{renderOptions()}
				</Tooltip>
				{/*<button className={'icon-button-burger'} onClick={toggleOptions}>...</button>*/}
				{/*{renderOptions()}*/}
			</>
		)
	}


	return (
		<Panel className='used-details p__content' icon={'box'} lock={false} options={getProductOptions}  title={title}>
			{detailsGroups.map((el, i) => {
				if(!empty(el.details)) {
					return (
						<div className={'used-details__group'} key={i}>
							<p className={'used-details__group-title'}>{el.name}</p>
							{el.details.map(detail => <UsedDetail key={detail.detailCId} removeFromGroup={removeFromGroup} detailCId={detail.detailCId}  />)}
						</div>
					)
				} else {
					return el.map(detail => <UsedDetail key={detail.detailCId} detailCId={detail.detailCId}  />)
				}
			})}
			{permissions.includes('KK_editConnections') ? <Connections productId={productId} /> : null}
			{getEditSizeModal()}
			{getSetSizeModal()}
		</Panel>
	)
}

export default UsedDetails