import {List} from "react-virtualized";
import {Detail} from "./Detail";
import {forwardRef, useCallback, useState, useEffect} from "react";
import {useProjectTableContext} from "../../../../../hooks/useContexts";
import {useDispatch, useSelector} from "react-redux";
import {swapElements, updateDetailOrderInDB} from "../../../../../helpers/helper";
import Languages from "../../../../../translation/Languages";
import {projectOperations, projectSelectors} from "redux/project";
import CONSTANTS from "../../../../../config/constants";

const Table = forwardRef(({ selectDetail, selectedDetails, remove, details, setDetails, height, hasErrors, width}, ref) => {
    const {tableRef, rerenderTable} = useProjectTableContext();
    const dispatch = useDispatch();
		const construction = useSelector(projectSelectors.getConstruction);
		const detailIdFromScene = useSelector(projectSelectors.getIdDetailFromScene);
		const [scrolledDetail, setScrolledDetail] = useState(typeof detailIdFromScene === 'number' ? details.findIndex((d) => d._id === detailIdFromScene) : false);
    const handleDragStart = useCallback((e, detail) => {
        e.dataTransfer.setData('order', detail?.order);
        e.dataTransfer.setData('dragId', detail.id);
    }, []);

    const handleDrop = useCallback(async (e, targetOrderId) => {
        e.preventDefault();
        const draggingOrderId = e.dataTransfer.getData('order');


        const draggingItem = details.find(detail => detail.order === Number(draggingOrderId));
        const targetItem = details.find(detail => detail.order === targetOrderId);

        const draggingIndex = details.indexOf(draggingItem);
        const targetIndex = details.indexOf(targetItem);


        if (draggingIndex !== -1 && targetIndex !== -1 && draggingIndex !== targetIndex) {
            let updatedDetails = details;

            swapElements(updatedDetails, draggingIndex, targetIndex);

            const promisesForUpdateDetails = [];

            const maxIndex = Math.max(draggingIndex, targetIndex);

            for (let i = 0; i <= maxIndex; i++) {
                const newOrder = i + 1;

                updatedDetails[i].order = newOrder;

                promisesForUpdateDetails.push(updateDetailOrderInDB(updatedDetails[i], newOrder));
            }

            await Promise.all(promisesForUpdateDetails);

            setDetails(updatedDetails);
            rerenderTable();
        }
    }, [dispatch, details, rerenderTable]);

    const dragOverHandler = useCallback((e) => {
        e.preventDefault()
    }, []);

    const rowRenderer = ({index, style}) => {
			if (details.length === 0) {
				return (
					<div key="empty-project" style={style} className="details__empty-project">{Languages.getTranslation("empty-project", true)}</div>
				);
			}
			
			const detail = details[index];
			const isHighlighted = typeof scrolledDetail === "number" ? scrolledDetail === index : false;

        return (
            <Detail
                key={detail.id}
                style={{...style, backgroundColor: isHighlighted ? "#f1e5ef" : ""}}
                selectDetailHandler={selectDetail}
                detail={detail}
                index={index}
                hasErrors={hasErrors}
                remove={remove}
                isSelected={selectedDetails.includes(detail)}
                dragEndHandler={dragOverHandler}
                dragOverHandler={dragOverHandler}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
								construction={construction}
            />
        )
    };
	
	useEffect(() => {
		setTimeout(() => {
			setScrolledDetail(false);
      dispatch(
        projectOperations.setIdDetailFromScene(null)
      );
		}, 5000);
	}, []);

    return (
        <div ref={ref} className="table-wrapper">
            <List
								{...(typeof scrolledDetail === "number" && {scrollToIndex: scrolledDetail})}
                ref={tableRef}
                className="default-details-table"
                height={height}
                overscanRowCount={CONSTANTS.overscanRowCount}
								scrollToAlignment="center"
                rowCount={details.length === 0 ? 1 : details.length}
                rowHeight={70}
                rowRenderer={rowRenderer}
                width={width}
            />
        </div>
    )
});

export default Table;