import {combineReducers} from 'redux';
import {createReducer} from '@reduxjs/toolkit';
import ACTIONS from './actions';

const initialModalState = {
  show: false,
  type: null,
  showDetailInfo: false,
  isReplacedMaterialForProject: true,
};

const modals = createReducer(initialModalState, {
  [ACTIONS.ModalStateSwitch.type]: (state, val) => {
    return {
      ...state,
      show: val.payload.show,
      type: val.payload.type,
      isEdit: val.payload.isEdit || false,
      isReplacedMaterialForProject: val.payload.isReplacedMaterialForProject
    }
  },
  [ACTIONS.ModalDetailSwitch.type]: (state, val) => {
    return {
      ...state,
      showDetailInfo: val.payload.showDetailInfo,
    }
  },
});

export default combineReducers({
  modals
});
