import {useEffect, useState} from "react";
import icon from "../../media/user.svg";
import API from "api/API";
import {useDispatch, useSelector} from 'react-redux'
import {userSelectors} from "redux/user";
import "./style.scss";
import Errors from "../../models/Errors";
import Languages from "../../translation/Languages";
import DefaultBtn from "../UI/Buttons/DefaultBtn";
import {errorMessageOperations} from "redux/errors";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";
import {Tooltip} from "../UI/Tooltip";
import CustomSelect from "../UI/Select";
import Loader2 from "../UI/Loader2";

const _errors = new Errors([]);

const Auth = () => {
    const user = useSelector(userSelectors.User);
    const [allFilial, setAllFilial] = useState([]);
		const [isFilialsLoading, setIsFilialsLoading] = useState(true);
		const [isTooltipOpened, setIsTooltipOpened] = useState();
    const [currentFilial, setCurrentFilial] = useState();
    useUpdateTranslations();

    const dispatch = useDispatch()

    useEffect(() => {
			if (isTooltipOpened && !allFilial.length) {
				API.Auth.getFilialAgency()
					.then(response => {
						const allFilials = response?.data.map((filial) => ({...filial, label: filial.name, value: filial.id}));
						setAllFilial(allFilials);
						setCurrentFilial(user?.filial_agency === null
							? allFilials.find((f) => f.id === 5) // id "Київ - Куренівський"
							: allFilials.find((f) => f.id === user.filial_agency))
					}).finally(() => {
						setIsFilialsLoading(false);
				})
			}
    }, [isTooltipOpened]);

    const logout = async () => {
        await API.Auth.logout()
            .catch(error => {
                _errors.setError(`${error.message}`, 'error', true)
            });
    };

    const handleChangeFilial = async (item) => {
        await API.Auth.setFilialAgency(item.id)
            .then(() => {
                dispatch(errorMessageOperations.switchStateError(
                    {message: Languages.getTranslation("filial-changed", true), type: 'success', show: true}))
								// зачем редиректить? Для того, чтобы заново провалидировать проект
								document.location.href = '/details'
                setCurrentFilial(item)
            })
    }
		
    return (
        <>
            <div className="auth">
                <div className="user">
                    {user && <>
											<img data-tooltip-id='user-tooltip' src={icon} alt="logo"/>
											<Tooltip
												className="header__user-tooltip"
												id="user-tooltip"
												place="bottom-end"
												openOnClick
												isOpen={isTooltipOpened}
												setIsOpen={(value) => setIsTooltipOpened(value)}
												clickable
											>
											{isFilialsLoading ? <Loader2/> : (
												<>
												<div className='user_userName'>
														<img src={icon} alt="logo" className="auth-img"/>
														<div className='user_userName_colum'>
															<div title={`${user.firstName} ${user.lastName}`} className='user_userName_colum_first'>{`${user.firstName} ${user.lastName}`}</div>
															<div>{`+${user.phone}`}</div>
														</div>
													</div>
												<p className='user_affiliate'>{Languages.getTranslation("Affiliate:", true)}</p>
												<CustomSelect value={currentFilial} options={allFilial} handleChange={handleChangeFilial} />
												<DefaultBtn title={Languages.getTranslation('exit', true)} handler={logout}/>
												</>
											)}
											</Tooltip>
										</>}
                </div>
            </div>
        </>
    );
};

export default Auth;
