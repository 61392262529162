import {useCallback, useMemo, useState} from "react";
import API from "../api/API";

const selectLabel = (obj, props) => {
	for (let i = 0; i < props.length; i++) {
		if (obj[props[i]]) return props[i];
	}
	
	return props[props.length - 1];
};

const convertToSelectOption = (arr, valueKey, labelKeys) =>
	arr.map(
		(obj) =>
			({
				...obj,
				label: obj[selectLabel(obj, labelKeys)],
				value: valueKey ? obj[valueKey] : obj
			})
	);

export const useGetClients = () => {
	const [isFetching, setIsFetching] = useState(false);
	const [clients, setClients] = useState([]);
	const [meta, setMeta] = useState(null);
	const resetOptions = useCallback(() => {
		setClients([]);
	}, []);
	
	const handleSearch = useCallback(
		async ({query, page}) => {
			try {
				if (query.trim()) {
					setIsFetching(true);
					const response = await API.Auth.searchUserByPhoneAndName({query, page});
					const {meta, data} = response.data;
					const newClients = data ? convertToSelectOption(data, "id", ["name"]) : [];
					setClients((prev) => {
						return [...prev, ...newClients].reduce((acc, client) => {
							if (!acc.some((c) => c.id === client.id)) {
								acc.push(client);
							}
							return acc;
						}, []);
					});
					setMeta(meta);
				} else {
					resetOptions();
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsFetching(false);
			}
		},
		[resetOptions, setMeta, setClients, setIsFetching]
	);
	
	return useMemo(
		() => ({
			clients,
			meta,
			handleSearch,
			resetOptions,
			isClientFetching: isFetching
		}),
		[clients, handleSearch, resetOptions, isFetching]
	);
};
