import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";

const initialUserState = {
  user: null,
};

const user = createReducer(initialUserState, {
  [ACTIONS.UserInfo.type]: (state, user) => {
    return {
      ...state,
      user: user,
    };
  },
});

export default combineReducers({
  user,
});
