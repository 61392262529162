import Languages from "../../../../translation/Languages";
import CloseBtn from "../../Buttons/CloseBtn";

const Header = ({close}) => {
	return <div className="filter-sidebar_header">
		<div className="filter-sidebar_header-title">
			<h3>{Languages.getTranslation('filter', true)}</h3>
			<div className="filter-sidebar_header-icon"/>
		</div>
		<div className="filter-sidebar_header-btn">
			<CloseBtn handler={close}/>
		</div>
	</div>
}

export default Header;