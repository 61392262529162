import ACTIONS from './actions';

const switchStateForModal = ({show, type, isEdit,isReplacedMaterialForProject}) => {
  return async dispatch => {
    try {
      dispatch(ACTIONS.ModalStateSwitch({show: show, type: type, isEdit: isEdit, isReplacedMaterialForProject:isReplacedMaterialForProject}))
    } catch (e) {
      console.log(e)
    }
  };
}

const showStateDetailInfo = ({show}) => {
  return async dispatch => {
    try {
      dispatch(ACTIONS.ModalDetailSwitch({showDetailInfo: show}))
    } catch (e) {
      console.log(e)
    }
  };
}

export default {
  switchStateForModal,
  showStateDetailInfo
};
