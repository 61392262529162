import React, {useState} from "react";
import './style.scss';

export default function Tooltip(props) {
	const {className, children, showType = 'hover'} = props;
	const [visible, setVisible] = useState(false)
	const [position, setPosition] = useState({left: 0, top: 0, right: 0, bottom: 0})

	const toggleVisible = (e, v = false) => {
		if(v) {
			calcPosition(e)
		}

		setVisible(v);
	}

	const calcPosition = (e) => {
		const {left, top, right, bottom, width, height, y} = e.target.getBoundingClientRect()
		const wWidth = window.innerWidth, wHeight = window.innerHeight;

		if(left + width > wWidth - 100) {
			setPosition({
				left: left + width > wWidth - 100 ? 'auto' : left - width + 25,
				right: left + width > wWidth - 100 ? wWidth - right + 100 : 'auto',
				top: bottom > 100 ? 'auto' : bottom - height - 10,
				bottom: bottom < 100 ? 'auto' : wHeight - bottom + height + 25
			})
		} else {
			console.log(e.target.getBoundingClientRect())
			setPosition({
				left: 'auto',
				right: wWidth - right + width * 2 - width / 2,
				top: bottom > 100 ? 'auto' : bottom - height - 10,
				bottom: bottom < 100 ? 'auto' : wHeight - bottom + height + 25
			})
		}
	}

	const getToolTip = () => {
		switch (showType) {
			case 'hover':
				return (
					<a className={className} onMouseOver={e => toggleVisible(e,true)} onMouseLeave={e => toggleVisible(e,false)}></a>
				);
			case 'click':
				return (
					<a className={className} onClick={e => toggleVisible(e, !visible)}></a>
				);
		}

	}

	return (
		<>
			{getToolTip()}
			{visible ? <div style={{left: position.left, top: position.top, right: position.right, bottom: position.bottom}} className={`tooltip ${Number.isInteger(position.left) && position.left < 300 ? 'tooltip-left' : ''} `}>{children}</div> : null}
		</>
	)
}