import {useState} from "react";
import axios from "axios";
import Languages from "../../../../translation/Languages";
import API from "../../../../api/API";
import Loader from "../../Loader";
import './style.scss';
import {tempDetailOperations} from "redux/tempDetail";
import {useDispatch} from "react-redux";
import CloseBtn from "../../Buttons/CloseBtn";
import {DownloadIcon} from "../../../Icons/DownloadIcon";
import AddDetailsModal from "../AddDetailsModal";
import {Modal, ModalFooter, ModalHeader} from "../index";

const InputExelModal = ({isOpen, close, openCLone}) => {
	const FORMATS_GITLAB = '.xlsx, .xls'
	const dispatch = useDispatch()
	const [load, setLoad] = useState(false)
	const [showAddDetails, setShowAddDetails] = useState(false)
	
	const changeInput = async (event) => {
		setLoad(true)
		const formData = new FormData()
		formData.append('file', event.target.files[0]);
		
		await axios.post(`${process.env.PROJECT_API}/excel/import`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `Bearer ${API.getToken()}`
			},
		}).then(response => {
			dispatch(tempDetailOperations.setTempProject(response.data.data))
			
			setShowAddDetails(true)
			
			// openCLone()
			setLoad(false)
		})
	}
	
	return <Modal isOpen={isOpen}>
		<div className='inputExelModal modal__wrapper'>
			<ModalHeader className='inputExelModal__header'>
				<h3>{Languages.getTranslation('import-file', true)}</h3>
				<CloseBtn handler={close}/>
			</ModalHeader>
			{load ? <Loader/> :
				<div className="inputExelModal__body">
					<div className="inputExelModal__main">
						<div className="inputExelModal__body__header">
							<div className="inputExelModal_id">
								<label
									htmlFor="file-exel-upload">
									<i className='icon-linkXlsx'></i>
								</label>
								<input
									id='file-exel-upload'
									onChange={(event) => changeInput(event)}
									className="inputExelModal__body__header_input"
									type="file"
									accept={FORMATS_GITLAB}
									name='file'
									placeholder={Languages.getTranslation("upload-exel-file", true)}/>
								<div>{Languages.getTranslation('import-file-order', true)}</div>
							</div>
						</div>
					</div>
				</div>}
			<ModalFooter className='inputExelModal__body-wrapper'>
				<div className="inputExelModal__body-wrapper-icon">
					<DownloadIcon/>
				</div>
				<div className='inputExelModal__container'>
					<div
						className='inputExelModal__body-wrapper-text'>{Languages.getTranslation('download-template-xlsx', true)}</div>
					<a className='inputExelModal__body-wrapper_link' href={Languages.getTranslation('link-excel-xlsx', true)}
						 download>xlsx,</a>
					<a className='inputExelModal__body-wrapper_link' href={Languages.getTranslation('link-excel-xls', true)}
						 download>xls</a>
				</div>
			</ModalFooter>
		</div>
		{showAddDetails && <AddDetailsModal close={() => {
			dispatch(tempDetailOperations.setTempProject({}))
			setShowAddDetails(false)
			navigate('/')
		}
		}/>}
	</Modal>
	
}

export default InputExelModal