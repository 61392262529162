import Languages from "../../../translation/Languages";
import "./styles.scss";
import {useDispatch, useSelector} from "react-redux";
import {langOperations, langSelectors} from "redux/lang";
import {Tooltip} from "../Tooltip";
import {useState} from "react";

export const Langs = () => {
	const curLang = useSelector(langSelectors.getLangState);
	const dispatch = useDispatch();
	const [isLangTooltipOpened, setIsLangTooltipOpened] = useState(false);
	const changeLangHandler = (lang) => {
		dispatch(
			langOperations.changeLang({lang})
		);
		Languages._locale = lang;
		setIsLangTooltipOpened(false);
	}
	
	return (
		<div className={`lang ${isLangTooltipOpened ? "lang--opened" : ""}`.trim()}>
			<div className="lang--current" data-tooltip-id="lang-tooltip">
				{Languages.checkUkLang(curLang)}
			</div>
			<Tooltip className="lang__tooltip" id="lang-tooltip" place="bottom" openOnClick clickable
							 isOpen={isLangTooltipOpened} setIsOpen={(value) => setIsLangTooltipOpened(value)} noArrow>
				{Languages.langs.map((lang) => (
					<button
						key={lang}
						type="button"
						className={`lang-btn ${curLang === lang ? "active" : ""}`.trim()}
						onClick={() => changeLangHandler(lang)}
					>
						{Languages.checkUkLang(lang)}
					</button>
				))}
			</Tooltip>
		</div>)
}